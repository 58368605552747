import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/domain/Constant";
import { BreedListModel } from "@/domain/breed/breedList/model/BreedListModel";
import { BreedListResponseDto } from "@/domain/breed/breedList/dto/BreedListResponseDto";
import { BreedListRequestDto } from "@/domain/breed/breedList/dto/BreedListRequestDto";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";

const paginationMapper = new PaginationMapper();

export class BreedListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: BreedListResponseDto): Result<BreedListModel> {
    try {
      const breedList: BreedListModel[] = [];

      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const breedListModel: BreedListModel = {
            breed: {
              id: Number(item.id),
              name: item.attributes.name,
              shortCode: item.attributes.shortCode,
              description: item.attributes.description,
              isDefault: item.attributes.isDefault,
              animalType: item.attributes.animalType.data.attributes.name,
            },
            page: 0,
            pageSize: 0,
            pagination: dto.meta.pagination,
          };

          if (item.attributes.tenantEstateAnimalUnitPrice.data) {
            breedListModel.breed.price =
              item.attributes.tenantEstateAnimalUnitPrice.data.attributes.unitPrice;
          }

          breedList.push(breedListModel);
        }
        return Result.ok(breedList);
      }

      return Result.ok(breedList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.BREED_LIST))
      );
    }
  }

  mapToDTO(domain: BreedListModel): Result<BreedListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const breedRequest = new BreedListRequestDto(pagiRequest, domain.tenantEstateAnimalUnitPrice);

      return Result.ok(breedRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.BREED_LIST))
      );
    }
  }
}
