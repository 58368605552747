import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { BreedAddRequestDto } from "@/domain/breed/breedAdd/dto/BreedAddRequestDto";
import { BreedAddResponseDto } from "@/domain/breed/breedAdd/dto/BreedAddResponseDto";
import { BreedAddModel } from "@/domain/breed/breedAdd/model/BreedAddModel";

export class BreedAddMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: BreedAddResponseDto): Result<BreedAddModel> {
    try {
      const breedAdd: BreedAddModel = {
        id: dto.data.id,
        name: dto.data.attributes.name,
        description: dto.data.attributes.description,
        shortCode: dto.data.attributes.shortCode,
        animalType: dto.data.attributes.animalType,
      };

      return Result.ok(breedAdd);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.BREED_ADD))
      );
    }
  }

  mapToDTO(domain: BreedAddModel): Result<BreedAddRequestDto> {
    try {
      const breedRequest = new BreedAddRequestDto(domain.name, domain.description, Number(domain.animalType));

      return Result.ok(breedRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.BREED_ADD))
      );
    }
  }
}
