import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { IDeleteAnimalRepository } from "@/domain/animal/animalDelete/repository/IDeleteAnimalRepository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import DeleteAnimalService from "../service/DeleteAnimalService";
import { DeleteAnimalRequestDto } from "@/domain/animal/animalDelete/dto/DeleteAnimalRequestDto";

const deleteAnimalService = new DeleteAnimalService();

export class DeleteAnimalRepository implements IDeleteAnimalRepository {
  async deleteAnimal(request: DeleteAnimalRequestDto): Promise<Result<any>> {
    try {
      return await deleteAnimalService
        .delete(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_DELETE)));
    }
  }
}
