import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalListRequestDto } from "@/domain/animal/animalList/dto/AnimalListRequestDto";
import { AnimalListResponseDto } from "@/domain/animal/animalList/dto/AnimalListResponseDto";
import { AnimalListModel } from "@/domain/animal/animalList/model/AnimalListModel";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";

const paginationMapper = new PaginationMapper();

export class AnimalListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AnimalListResponseDto): Result<AnimalListModel[]> {
    try {
      const animalList: AnimalListModel[] = [];

      for (const item of dto.data) {
        const animalListModel: AnimalListModel = {
          animal: {
            id: item.id,
            name: item.attributes.name,
            motherEarTag: item.attributes.motherEarTag,
            fatherEarTag: item.attributes.fatherEarTag,
            earTag: item.attributes.earTag,
            gender: item.attributes.gender,
            birthDate: item.attributes.birthDate,
            liveWeight: item.attributes.liveWeight,
            carcassWeight: item.attributes.carcassWeight,
            acquisitionDate: item.attributes.acquisitionDate,
            acquisitionReason: item.attributes.acquisitionReason,
            acquisitionPrice: item.attributes.acquisitionPrice,
            color: item.attributes.color,
            status: item.attributes.status,
            leaveDate: item.attributes.leaveDate,
            leaveReason: item.attributes.leaveReason,
            leavePrice: item.attributes.leavePrice,
            barnyard: item.attributes.barnyard,
            animalBreed: item.attributes.animalBreed,
          },
          page: 0,
          pageSize: 0,
          pagination: dto.meta.pagination
        };

        if (item.attributes.profilePhoto.data) {
          animalListModel.animal.profilePhotoUrl =
            item.attributes.profilePhoto.data.attributes.url;
        } else {
          if (animalListModel.animal.gender == "DISI") {
            animalListModel.animal.profilePhotoUrl = "/media/animal/female.svg";
          } else if (animalListModel.animal.gender == "ERKEK") {
            animalListModel.animal.profilePhotoUrl = "/media/animal/male.svg";
          }
          else{
            animalListModel.animal.profilePhotoUrl = "/media/animal/animal.png";
          }
        }

        animalList.push(animalListModel);
      }

      return Result.ok(animalList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_LIST))
      );
    }
  }

  mapToDTO(domain: AnimalListModel): Result<AnimalListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const animalRequest = new AnimalListRequestDto(
        pagiRequest,
        domain.barnyardId,
        domain.query
      );

      return Result.ok(animalRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_LIST))
      );
    }
  }
}
