import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalTagUpdateRequestDto } from "@/domain/animalTag/animalTagUpdate/dto/AnimalTagUpdateRequestDto";
import { AnimalTagUpdateResponseDto } from "@/domain/animalTag/animalTagUpdate/dto/AnimalTagUpdateResponseDto";
import { AnimalTagUpdateModel } from "@/domain/animalTag/animalTagUpdate/model/AnimalTagUpdateModel";

export class AnimalTagUpdateMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AnimalTagUpdateResponseDto): Result<AnimalTagUpdateModel> {
    try {
      const animalTagAdd: AnimalTagUpdateModel = {
        id: dto.data.id,
        name: dto.data.attributes.name,
        description: dto.data.attributes.description,
        shortCode: dto.data.attributes.shortCode,
        isDefault: dto.data.attributes.isDefault,
      };

      return Result.ok(animalTagAdd);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_TAG_UPDATE))
      );
    }
  }

  mapToDTO(domain: AnimalTagUpdateModel): Result<AnimalTagUpdateRequestDto> {
    try {
      const animalTagRequest = new AnimalTagUpdateRequestDto(domain.name);

      return Result.ok(animalTagRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_TAG_UPDATE))
      );
    }
  }
}
