import { IVaccinationListUseCase } from "@/domain/vaccination/vaccinationList/usecase/IVaccinationListService";
import { VaccinationListRepository } from "../repository/VaccinationListRepository";
import { VaccinationListModel } from "@/domain/vaccination/vaccinationList/model/VaccinationListModel";
import { Result } from "@/core/utility/ResultType";
import { VaccinationListMapper } from "../mapper/VaccinationListMapper";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { SWAL_MESSAGES, USECASE } from "@/domain/Constant";
import { UnknownCause } from "@/core/cause/UnknownCause";

export class VaccinationListUseCase implements IVaccinationListUseCase {
  vaccinationListRepository: VaccinationListRepository;
  vaccinationListMapper = new VaccinationListMapper();

  constructor(_vaccinationListRepository: VaccinationListRepository) {
    this.vaccinationListRepository = _vaccinationListRepository;
  }

  async execute(
    model: VaccinationListModel
  ): Promise<Result<VaccinationListModel>> {
    try {
      const dto = this.vaccinationListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<VaccinationListModel>(dto.getError());
      } else {
        const response = await this.vaccinationListRepository.vaccinationList(
          dto
        );

        if (response.isSuccess) {
          const domain = this.vaccinationListMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<VaccinationListModel>(domain.getValue());
        } else {
          return Result.fail<VaccinationListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<VaccinationListModel>(
        new UseCaseException(new UnknownCause(USECASE.VACCINATION_LIST))
      );
    }
  }
}
