import { BaseDTO } from "@/core/base/BaseDTO";

export class BreedUpdateRequestDto extends BaseDTO {
  data: {
    name?: string;
    description?: string;
    animalType?: number;
  };

  constructor(name?: string, description?: string, animalType?: number, ) {
    super();
    this.data = {
      name,
      description,
      animalType,
    };
  }
}
