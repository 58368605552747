import { BaseDTO } from "@/core/base/BaseDTO";

export class PriceAddRequestDto extends BaseDTO {
  data: {
    unitPrice?: number;
    animalBreed?: number;
  };

  constructor(unitPrice?: number, animalBreed?: number) {
    super();
    this.data = {
      unitPrice,
      animalBreed,
    };
  }
}
