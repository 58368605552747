import { ForgotPasswordRepository } from "../repository/ForgotPasswordRepository";
import { ForgotPasswordMapper } from "../mapper/ForgotPasswordMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IForgotPasswordUseCase } from "@/domain/authentication/forgotPassword/usecase/IForgotPasswordUsecase";
import { ForgotPasswordModel } from "@/domain/authentication/forgotPassword/model/ForgotPasswordModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";

export class ForgotPasswordUsecase implements IForgotPasswordUseCase {
  forgotPasswordRepository: ForgotPasswordRepository;
  forgotPasswordMapper = new ForgotPasswordMapper();

  constructor(_forgotPasswordRepository: ForgotPasswordRepository) {
    this.forgotPasswordRepository = _forgotPasswordRepository;
  }

  async execute(model: ForgotPasswordModel): Promise<Result<ForgotPasswordModel>> {
    try {
      const dto = this.forgotPasswordMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<ForgotPasswordModel>(dto.getError());
      } else {
        const response = await this.forgotPasswordRepository.forgotPassword(dto);
        
        if (environment.env == "prod") {
        const analytics = getAnalytics();
        logEvent(analytics, 'forgot_password');
        }
        if (response.isSuccess) {
          const domain = this.forgotPasswordMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<ForgotPasswordModel>(domain.getValue());
        } else {
          return Result.fail<ForgotPasswordModel>(response.getError())
        }
      }
    } catch (error) {
      return Result.fail<ForgotPasswordModel>(
        new UseCaseException(new UnknownCause(USECASE.FORGOT_PASSWORD))
      );
    }
  }
}
