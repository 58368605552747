import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { ResetPasswordRequestDto } from "@/domain/authentication/resetPassword/dto/ResetPasswordRequestDto";
import { ResetPasswordResponseDto } from "@/domain/authentication/resetPassword/dto/ResetPasswordResponseDto";
import { ResetPasswordModel } from "@/domain/authentication/resetPassword/model/ResetPasswordModel";

export class ResetPasswordMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: ResetPasswordResponseDto): Result<ResetPasswordModel> {
    try {
      const resetPassword: ResetPasswordModel = {
        id: dto.data.attributes.id,
        username: dto.data.attributes.username,
        email: dto.data.attributes.email,
        provider: dto.data.attributes.provider,
        confirmed: dto.data.attributes.confirmed,
        blocked: dto.data.attributes.blocked,
        createdAt: dto.data.attributes.createdAt,
        updatedAt: dto.data.attributes.updatedAt,
        phoneNumber: dto.data.attributes.phoneNumber,
        name: dto.data.attributes.name,
        surname: dto.data.attributes.surname,
      };

      return Result.ok(resetPassword);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.RESET_PASSWORD))
      );
    }
  }

  mapToDTO(domain: ResetPasswordModel): Result<ResetPasswordRequestDto> {
    try {
      const resetPasswordRequest = new ResetPasswordRequestDto(
        domain.code,
        domain.password,
        domain.passwordConfirmation,
      );

      return Result.ok(resetPasswordRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.RESET_PASSWORD))
      );
    }
  }
}
