import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalTagUpdateService from "../service/AnimalTagUpdateService";
import { IAnimalTagUpdateRepository } from "@/domain/animalTag/animalTagUpdate/repository/IAnimalTagUpdateRepository";
import { AnimalTagUpdateRequestDto } from "@/domain/animalTag/animalTagUpdate/dto/AnimalTagUpdateRequestDto";
import { AnimalTagUpdateResponseDto } from "@/domain/animalTag/animalTagUpdate/dto/AnimalTagUpdateResponseDto";

const updateAnimalTagService = new AnimalTagUpdateService();

export class AnimalTagUpdateRepository implements IAnimalTagUpdateRepository {
  async updateAnimalTag(
    request: AnimalTagUpdateRequestDto, id?: number
  ): Promise<Result<AnimalTagUpdateResponseDto>> {
    try {
      return await updateAnimalTagService
        .update(request, id)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<AnimalTagUpdateResponseDto>(response.getValue());
          } else {
            return Result.fail<AnimalTagUpdateResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<AnimalTagUpdateResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<AnimalTagUpdateResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_TAG_UPDATE))
      );
    }
  }
}
