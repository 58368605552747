import { Result } from "@/core/utility/ResultType";
import { BadRequestException } from "@/core/exception/BadRequestException";
import CreateAnimalService from "../service/CreateAnimalService";
import { ICreateAnimalRepository } from "@/domain/animal/animalAdd/repository/ICreateAnimalRepository";
import { CreateAnimalRequestDto } from "@/domain/animal/animalAdd/dto/CreateAnimalRequestDto";
import { CreateAnimalResponseDto } from "@/domain/animal/animalAdd/dto/CreateAnimalResponseDto";
import { GetAnimalDetailResponseDto } from "@/domain/animal/animalDetail/dto/GetAnimalDetailResponseDto";
import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { REPOSITORY } from "@/domain/Constant";

const createAnimalService = new CreateAnimalService();

export class CreateAnimalRepository implements ICreateAnimalRepository {
  async createAnimal(
    request: CreateAnimalRequestDto
  ): Promise<Result<CreateAnimalResponseDto>> {
    try {
      return await createAnimalService
        .post(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<GetAnimalDetailResponseDto>(response.getValue());
          } else {
            return Result.fail<GetAnimalDetailResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<GetAnimalDetailResponseDto>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<GetAnimalDetailResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_ADD))
      );
    }
  }
}
