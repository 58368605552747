import { FeedBuyRepository } from "../repository/FeedBuyRepository";
import { FeedBuyModel } from "@/domain/feed/feedBuy/model/FeedBuyModel";
import { Result } from "@/core/utility/ResultType";
import { FeedBuyMapper } from "../mapper/FeedBuyMapper";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/domain/Constant";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IFeedBuyUseCase } from "@/domain/feed/feedBuy/usecase/IFeedBuyUsecase";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";

export class FeedBuyUseCase implements IFeedBuyUseCase {
  feedBuyRepository: FeedBuyRepository;
  feedBuyMapper = new FeedBuyMapper();

  constructor(_feedBuyRepository: FeedBuyRepository) {
    this.feedBuyRepository = _feedBuyRepository;
  }

  async execute(model: FeedBuyModel): Promise<Result<FeedBuyModel>> {
    try {
      const dto = this.feedBuyMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<FeedBuyModel>(dto.getError());
      } else {
        const response = await this.feedBuyRepository.buyFeed(dto);

        if (response.isSuccess) {
          const domain = this.feedBuyMapper.mapToDomain(response.getValue());

          if (environment.env == "prod") {
          const analytics = getAnalytics();
          logEvent(analytics, 'buy_feed', {
            feed_name: model.name
          });
        }

          return Result.ok<FeedBuyModel>(domain.getValue());
        } else {
          return Result.fail<FeedBuyModel>(response.getError())
        }
      }
    } catch (error) {
      return Result.fail<FeedBuyModel>(
        new UseCaseException(new UnknownCause(USECASE.FEED_BUY))
      );
    }
  }
}
