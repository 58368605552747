import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { ForgotPasswordRequestDto } from "@/domain/authentication/forgotPassword/dto/ForgotPasswordRequestDto";
import { ForgotPasswordModel } from "@/domain/authentication/forgotPassword/model/ForgotPasswordModel";

export class ForgotPasswordMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<ForgotPasswordModel> {
    try {
      const forgotPassword: ForgotPasswordModel = {
        ok: dto.ok
      };

      return Result.ok(forgotPassword);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.FORGOT_PASSWORD))
      );
    }
  }

  mapToDTO(domain: ForgotPasswordModel): Result<ForgotPasswordRequestDto> {
    try {
      const forgotPasswordRequest = new ForgotPasswordRequestDto(
        domain.email
      );

      return Result.ok(forgotPasswordRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.FORGOT_PASSWORD))
      );
    }
  }
}
