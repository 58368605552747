import AboutUserRepository from "../repository/AboutUserRepository";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { IAboutUserUsecase } from "@/domain/authentication/aboutUser/usecase/IAboutUserUsecase";
import { AboutUserModel } from "@/domain/authentication/aboutUser/model/AboutUserModel";
import { AboutUserResponseDto } from "@/domain/authentication/aboutUser/dto/AboutUserResponseDto";
import { AboutUserMapper } from "../mapper/AboutUserMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";

export class AboutUserUseCase implements IAboutUserUsecase {
  aboutUserRepository: AboutUserRepository;
  aboutUserMapper = new AboutUserMapper();

  constructor(private _aboutUserRepository: AboutUserRepository) {
    this.aboutUserRepository = this._aboutUserRepository;
  }
  async execute(): Promise<Result<AboutUserModel>> {
    try {
      const response = await this._aboutUserRepository.getAboutUser();

      if (response.isSuccess) {
        const value: AboutUserResponseDto = response.getValue();

        const aboutUserModel = this.aboutUserMapper.mapToDomain(value);
        return Result.ok<AboutUserModel>(aboutUserModel.getValue());
      } else {
        return Result.fail<AboutUserModel>(
          response.getError()
        );
      }
    } catch (error) {
      return Result.fail<AboutUserModel>(
        new UseCaseException(new UnknownCause(USECASE.ABOUT_USER))
      );
    }
  }
}
