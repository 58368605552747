import { IValueValidator } from "@/core/base/IValueValidator";
import { MinLengthCause } from "@/core/cause/MinLengthCause";
import { NullValueCause } from "@/core/cause/NullValueCause";
import { ValidationException } from "@/core/exception/ValidationException";
import { Result } from "@/core/utility/ResultType";
import { VALIDATOR_ERROR } from "@/domain/Constant";
import { ErrorLangMessage } from "@/presentation/helper/ErrorMessage";

export default class MinLengthValidator implements IValueValidator<string> {
  private min: number;
  private type: string;

  constructor(min: number, type: string) {
    this.min = min;
    this.type = type;
  }

  validate(value: string | null | undefined): Result<string> {
    if (value == null) {
      return Result.fail<string>(
        new ValidationException(new NullValueCause(VALIDATOR_ERROR.required))
      );
    } else if (this.min > value.length) {
      let errorMessage = ErrorLangMessage.getErrorMessage(
        VALIDATOR_ERROR.minlength
      );

      return Result.fail<string>(
        new ValidationException(new MinLengthCause(this.type + this.min + errorMessage))
      );
    } else {
      return Result.ok<string>(value);
    }
  }
}
