import { PriceAddRepository } from "../repository/PriceAddRepository";
import { PriceAddMapper } from "../mapper/PriceAddMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IPriceAddUseCase } from "@/domain/breed/priceAdd/usecase/IPriceAddUsecase";
import { PriceAddModel } from "@/domain/breed/priceAdd/model/PriceAddModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";

export class PriceAddUsecase implements IPriceAddUseCase {
  priceAddRepository: PriceAddRepository;
  priceAddMapper = new PriceAddMapper();

  constructor(_priceAddRepository: PriceAddRepository) {
    this.priceAddRepository = _priceAddRepository;
  }

  async execute(model: PriceAddModel): Promise<Result<PriceAddModel>> {
    try {
      const dto = this.priceAddMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<PriceAddModel>(dto.getError());
      } else {
        const response = await this.priceAddRepository.addPrice(dto);

        if (response.isSuccess) {
          const domain = this.priceAddMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
          const analytics = getAnalytics();
          logEvent(analytics, 'add_price', {
            unit_price: model.unitPrice,
            animal_breed: model.animalBreed
          });
        }

          return Result.ok<PriceAddModel>(domain.getValue());
        } else {
          return Result.fail<PriceAddModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<PriceAddModel>(
        new UseCaseException(new UnknownCause(USECASE.PRICE_ADD))
      );
    }
  }
}
