import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { FeedBuyUseCase } from "@/application/feed/feedBuy/usecase/FeedBuyUseCase";
import { FeedBuyRepository } from "@/application/feed/feedBuy/repository/FeedBuyRepository";
import { FeedBuyModel } from "@/domain/feed/feedBuy/model/FeedBuyModel";

export function createProductController() {
  const feedBuyRepository = new FeedBuyRepository();
  const feedBuyUseCase = new FeedBuyUseCase(feedBuyRepository);
  return new ProductController(feedBuyUseCase);
}

export class ProductController implements IController {
  constructor(private feedBuyUseCase: FeedBuyUseCase) {}

  async buyFeed(feedBuyModel: FeedBuyModel): Promise<Result<FeedBuyModel>> {
    const response = await this.feedBuyUseCase.execute(feedBuyModel);

    if(response.isSuccess){
      return response;
    }
    else{
      return Result.fail<FeedBuyModel>(response.getError());
    }
  }
}
