import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { ITenantStatisticService } from "@/domain/dashboard/tenantStatistic/service/ITenantStatisticService";
import { TenantStatisticResponseDto } from "@/domain/dashboard/tenantStatistic/dto/TenantStatisticResponseDto";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class TenantStatisticService implements ITenantStatisticService {
  async get(): Promise<Result<TenantStatisticResponseDto>> {
    try {
      return await instance
        .get<TenantStatisticResponseDto>(API_ROUTE.TENANT_ESTATE_STATISTIC)
        .then((response) => {
          return Result.ok<TenantStatisticResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<TenantStatisticResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_MOST_VALUABLE_ANIMAL)))
          );
        });
    } catch (error) {
      return Result.fail<TenantStatisticResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_MOST_VALUABLE))
      );
    }
  }
}
