import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IBreedAddService } from "@/domain/breed/breedAdd/service/IBreedAddService";
import { BreedAddRequestDto } from "@/domain/breed/breedAdd/dto/BreedAddRequestDto";
import { BreedAddResponseDto } from "@/domain/breed/breedAdd/dto/BreedAddResponseDto";

export default class BreedAddService implements IBreedAddService {
  async add(
    request: BreedAddRequestDto
  ): Promise<Result<BreedAddResponseDto>> {
    try {
      return await instance
        .post(API_ROUTE.BREED, request)
        .then((response) => {
          return Result.ok<BreedAddResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<BreedAddResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_ADD_BREED)))
          );
        });
    } catch (error) {
      return Result.fail<BreedAddResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.BREED_ADD))
      );
    }
  }
}
