import { LoginRequestDto } from "@/domain/authentication/loginUser/dto/LoginRequestDto";
import { BaseDomain } from "@/core/base/BaseDomain";
import { BaseDTO } from "@/core/base/BaseDTO";
import { Password } from "@/core/value/object/Password";
import { Result } from "@/core/utility/ResultType";
import { IMapper } from "@/core/base/IMapper";
import { LoginResponseDto } from "@/domain/authentication/loginUser/dto/LoginResponseDto";
import { TenantUserMapper } from "@/application/tenantUser/mapper/TenantUserMapper";
import { TenantUserModel } from "@/domain/tenantUser/model/TenantUserModel";
import { MAPPER, VALUE } from "@/domain/Constant";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { EmailAddress } from "@/core/value/object/EmailAddress";
import { MapperException } from "@/core/exception/base/MapperException";
import { LoginModel } from "@/domain/authentication/loginUser/model/LoginModel";

export class LoginMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: LoginResponseDto): Result<LoginModel> {
    const tenantUserMapper: TenantUserMapper = new TenantUserMapper();
    const activeTenantUser: TenantUserModel = tenantUserMapper.mapToDomain(
      dto.data.attributes.activeTenantUser
    ) as TenantUserModel;
    const tenantUsers: TenantUserModel[] = tenantUserMapper.mapToDomain(
      dto.data.attributes.tenantUsers
    ) as TenantUserModel[];
    try {
      const loginModel: LoginModel = {
        password: new Password({ value: VALUE.NULL_STRING }),
        id: dto.data.id !== undefined ? dto.data.id : 0,
        jwt: dto.jwt,
        email: new EmailAddress({ value: dto.data.attributes.email }),
        username: dto.data.attributes.username,
        activeTenantUser: activeTenantUser,
        tenantUsers: tenantUsers,
      };
      return Result.ok(loginModel);
    } catch {
      return Result.fail(new MapperException(new UnknownCause(MAPPER.LOGIN)));
    }
  }

  mapToDTO(domain: LoginModel): Result<LoginRequestDto> {
    const email = new EmailAddress({
      value: domain.email.props.value,
    }).create();
    const password = new Password({
      value: domain.password.props.value,
    }).create();

    if (email.isFailure) {
      return Result.fail(email.getError());
    } else if (password.isFailure) {
      return Result.fail(password.getError());
    }
    try {
      const loginRequestModel: LoginRequestDto = {
        identifier: email.getValue(),
        password: password.getValue(),
      };
      return Result.ok(loginRequestModel);
    } catch {
      return Result.fail(new MapperException(new UnknownCause(MAPPER.LOGIN)));
    }
  }
}
