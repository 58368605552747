import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalDiseaseUpdateRequestDto } from "@/domain/animal/animalDisease/animalDiseaseUpdate/dto/AnimalDiseaseUpdateRequestDto";
import { AnimalDiseaseUpdateResponseDto } from "@/domain/animal/animalDisease/animalDiseaseUpdate/dto/AnimalDiseaseUpdateResponseDto";
import { AnimalDiseaseUpdateModel } from "@/domain/animal/animalDisease/animalDiseaseUpdate/model/AnimalDiseaseUpdateModel";

export class AnimalDiseaseUpdateMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AnimalDiseaseUpdateResponseDto): Result<AnimalDiseaseUpdateModel> {
    try {
      const animalDiseaseAdd: AnimalDiseaseUpdateModel = {
        id: dto.data.id,
        startDate: dto.data.attributes.startDate,
        endDate: dto.data.attributes.endDate,
        animal: dto.data.attributes.animal,
        disease: dto.data.attributes.disease,
      };

      return Result.ok(animalDiseaseAdd);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_DISEASE_UPDATE))
      );
    }
  }

  mapToDTO(domain: AnimalDiseaseUpdateModel): Result<AnimalDiseaseUpdateRequestDto> {
    try {
      const animalDiseaseRequest = new AnimalDiseaseUpdateRequestDto(domain.endDate);

      return Result.ok(animalDiseaseRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_DISEASE_UPDATE))
      );
    }
  }
}
