import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { IAnimalTagListService } from "@/domain/animalTag/animalTagList/service/IAnimalTagListService";
import { AnimalTagListResponseDto } from "@/domain/animalTag/animalTagList/dto/AnimalTagListResponseDto";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class AnimalTagListService implements IAnimalTagListService {
  async getList(): Promise<Result<AnimalTagListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.ANIMAL_TAG)
        .then((response) => {
          return Result.ok<AnimalTagListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<AnimalTagListResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_ANIMAL_TAG_LIST)))
          );
        });
    } catch (error) {
      return Result.fail<AnimalTagListResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_TAG_LIST))
      );
    }
  }
}
