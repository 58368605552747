export default {
  project: "Maldavar",
  year: "2024",
  version: "1.32",
  dashboard: "Startseite",
  settings: "Einstellungen",
  authentication: "Authentifizierung",
  signIn: "Anmelden",
  signUp: "Registrieren",
  passwordReset: "Passwort zurücksetzen",
  error404: "Fehler 404",
  error500: "Fehler 500",
  createAccount: "Konto erstellen",
  signin: "Anmelden",
  adminPassword: "Willkommen, bitte geben Sie Ihre Anmeldeinformationen ein.",
  email: "E-Mail",
  password: "Passwort",
  forgotPassword: "Passwort vergessen",
  login: "Anmelden",
  pleaseWait: "Bitte warten...",
  animal: "Tier",
  barnyards: "Gehege",
  allAnimals: "Alle Tiere",
  createAnimal: "Tier hinzufügen",
  weight: "Lebendgewicht",
  animalTags: "Tiermarken",
  vaccine: "Impfung",
  vaccineList: "Impfliste",
  food: "Futter",
  treatment: "Behandlung",
  overheads: "Gemeinkosten",
  current: "Strom",
  sales: "Verkäufe",
  order: "Bestellung",
  shipment: "Sendung",
  bill: "Rechnung",
  waybill: "Lieferschein",
  buy: "Kaufen",
  buyAnimal: "Tier kaufen",
  buyFeed: "Futter kaufen",
  supplies: "Versorgungsmaterial",
  machine: "Maschine/Gerät",
  basicKnowledge: "Grundkenntnisse",
  vehicles: "Fahrzeuge",
  breeds: "Rassen",
  animalTypes: "Tierarten",
  vaccines: "Impfstoffe",
  diseases: "Krankheiten",
  foods: "Futtermittel",
  treatments: "Behandlungen",
  tags: "Tags",
  staff: "Personal",
  staffList: "Personalliste",
  authorization: "Autorisierung",
  common: {
    layout: {
      about: "Über uns",
      contact: "Kontakt",
      contactUs: "Kontaktieren Sie uns",
    },
    button: {
      info: "Info",
      edit: "Bearbeiten",
      detail: "Detail",
      save: "Speichern",
      cancel: "Abbrechen",
      return: "Zurück",
      add: "Hinzufügen",
      update: "Aktualisieren",
      send: "Senden",
      loader: "Bitte warten...",
      yes: "Ja",
      no: "Nein",
    },
    menu: {
      myProfile: "Mein Profil",
      changeTenant: "Farm wechseln",
      language: "Sprache",
      accountSettings: "Kontoeinstellungen",
      signOut: "Abmelden",
    },
    table: {
      process: "Bearbeiten",
      notProcess: "Kann nicht bearbeitet werden, da es Standard ist",
      pagination: {
        desc: " von ",
        desc1: "Ergebnisse werden angezeigt.",
        desc2: "Ergebnisse pro Seite",
      },
    },
  },
  swalMessages: {
    CONFIRM_BUTTON_TEXT: "Ok",
    API_ERROR_MESSAGE: "Technischer Fehler.",
    ERROR_CODE: " Fehlercode: ",
    UNKNOWN_ERROR_MESSAGE: "Ein unbekannter Fehler ist aufgetreten.",
    ARE_YOU_SURE: "Sind Sie sicher?",
    SURE_DELETE_ANIMAL:
      "Sind Sie sicher, dass Sie dieses Tier löschen möchten?",
    SURE_TREAT_ANIMAL:
      "Sind Sie sicher, dass Sie es als geheilt aktualisieren möchten?",

    SUCCESS: {
      SUCCESS: "Aktion erfolgreich!",
      LOGIN_SUCCESS_MESSAGE: "Anmeldung erfolgreich.",
      SUCCESS_FORGOT_PASSWORD:
        "Passwort zurücksetzen E-Mail wurde erfolgreich gesendet.",
      SUCCESS_RESET_PASSWORD: "Passwort erfolgreich geändert.",

      SUCCESS_CREATE_ANIMAL:
        "Herzlichen Glückwunsch, Tier wurde erfolgreich hinzugefügt.",
      SUCCESS_UPDATE_ANIMAL:
        "Herzlichen Glückwunsch, Tier wurde erfolgreich aktualisiert.",
      SUCCESS_DELETE_ANIMAL:
        "Herzlichen Glückwunsch, Tier wurde erfolgreich gelöscht.",
      SUCCESS_TRANSFER_ANIMAL:
        "Herzlichen Glückwunsch, Tier wurde erfolgreich übertragen.",

      SUCCESS_UPDATE_ANIMAL_WEIGHT:
        "Herzlichen Glückwunsch, Tiergewicht wurde erfolgreich aktualisiert.",
      SUCCESS_ADD_ANIMAL_VACCINE:
        "Herzlichen Glückwunsch, Impfung wurde erfolgreich durchgeführt.",
      SUCCESS_ADD_ANIMAL_TAG:
        "Herzlichen Glückwunsch, Tiermarke wurde erfolgreich erstellt.",
      SUCCESS_UPDATE_ANIMAL_TAG:
        "Herzlichen Glückwunsch, Tiermarke wurde erfolgreich aktualisiert.",
      SUCCESS_REMOVE_ANIMAL_TAG:
        "Herzlichen Glückwunsch, Tiermarke wurde erfolgreich entfernt.",
      SUCCESS_BUY_FOOD:
        "Herzlichen Glückwunsch, Futterkauf wurde erfolgreich hinzugefügt!",
      SUCCESS_ADD_BREED:
        "Herzlichen Glückwunsch, Tierart wurde erfolgreich hinzugefügt!",
      SUCCESS_UPDATE_BREED:
        "Herzlichen Glückwunsch, Tierart wurde erfolgreich aktualisiert!",
      SUCCESS_REMOVE_BREED:
        "Herzlichen Glückwunsch, Tierart wurde erfolgreich entfernt!",
      SUCCESS_UPDATE_PRICE:
        "Herzlichen Glückwunsch, Tierartpreis wurde erfolgreich aktualisiert!",
      SUCCESS_ADD_ANIMAL_FEED:
        "Herzlichen Glückwunsch, Tierfutter wurde erfolgreich hinzugefügt!",
      SUCCESS_ADD_ANIMAL_DISEASE:
        "Herzlichen Glückwunsch, Tierkrankheit wurde erfolgreich hinzugefügt!",
      SUCCESS_UPDATE_ANIMAL_DISEASE:
        "Herzlichen Glückwunsch, Tierkrankheit wurde erfolgreich aktualisiert!",
    },

    ERROR: {
      ERROR: "Aktion fehlgeschlagen!",
      SESSION_TIME_OUT:
        "Sitzung abgelaufen. Bitte melden Sie sich erneut mit Ihren Benutzerinformationen an.",
      LOGIN_ERROR_MESSAGE:
        "Anmeldeinformationen sind falsch. Bitte versuchen Sie es erneut.",
      ERROR_FORGOT_PASSWORD:
        "Technischer Fehler. Passwort zurücksetzen E-Mail konnte nicht gesendet werden.",
      ERROR_RESET_PASSWORD:
        "Technischer Fehler. Passwortänderung fehlgeschlagen. Bitte versuchen Sie es erneut.",
      ERROR_CHANGE_TENANT_USER:
        "Technischer Fehler! Änderung des Farmbenutzers fehlgeschlagen.",
      ERROR_ABOUT_USER:
        "Technischer Fehler! Persönliche Informationsübertragung fehlgeschlagen.",

      ERROR_CREATE_ANIMAL:
        "Technischer Fehler! Tier hinzufügen fehlgeschlagen.",
      ERROR_UPDATE_ANIMAL:
        "Technischer Fehler! Tieraktualisierung fehlgeschlagen.",
      ERROR_DELETE_ANIMAL:
        "Technischer Fehler! Löschung des Tieres fehlgeschlagen.",
      ERROR_TRANSFER_ANIMAL:
        "Technischer Fehler! Tierübertragung fehlgeschlagen.",

      ERROR_UPDATE_ANIMAL_WEIGHT:
        "Technischer Fehler! Tiergewichtsaktualisierung fehlgeschlagen.",
      ERROR_ADD_ANIMAL_VACCINE:
        "Technischer Fehler! Tierimpfung fehlgeschlagen.",
      ERROR_ADD_ANIMAL_TAG:
        "Technischer Fehler! Tiermarke hinzufügen fehlgeschlagen.",
      ERROR_UPDATE_ANIMAL_TAG:
        "Technischer Fehler! Tiermarkenaktualisierung fehlgeschlagen.",
      ERROR_REMOVE_ANIMAL_TAG:
        "Technischer Fehler! Tiermarkenentfernung fehlgeschlagen.",
      ERROR_BUY_FOOD: "Technischer Fehler! Futterkauf fehlgeschlagen.",
      ERROR_ADD_BREED: "Technischer Fehler! Tierart hinzufügen fehlgeschlagen.",
      ERROR_UPDATE_BREED:
        "Technischer Fehler! Tierartaktualisierung fehlgeschlagen.",
      ERROR_REMOVE_BREED:
        "Technischer Fehler! Entfernung der Tierart fehlgeschlagen.",
      ERROR_UPDATE_PRICE:
        "Technischer Fehler! Tierartpreisaktualisierung fehlgeschlagen.",
      ERROR_ADD_ANIMAL_FEED:
        "Technischer Fehler! Tierfutter hinzufügen fehlgeschlagen.",
      ERROR_ADD_ANIMAL_DISEASE:
        "Technischer Fehler! Tierkrankheit hinzufügen fehlgeschlagen.",
      ERROR_UPDATE_ANIMAL_DISEASE:
        "Technischer Fehler! Tierkrankheitsaktualisierung fehlgeschlagen.",
    },
  },
  customErrorMessages: {
    useCase: {
      error: "Usecase-Fehler.",
    },
    mapper: {
      error: "Mapper-Fehler.",
    },
    controller: {
      error: "Controller-Fehler.",
    },
    repository: {
      error: "Repository-Fehler.",
    },
    aggregateRoot: {
      error: "Aggregate-Root-Fehler.",
    },
    domainEvent: {
      error: "Domain-Ereignisfehler.",
    },
    email: {
      required: "Bitte geben Sie Ihre E-Mail-Adresse ein.",
      invalid: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
    },
    password: {
      required: "Bitte geben Sie Ihr Passwort ein.",
      min: "Das Passwort muss mindestens 6 Zeichen lang sein.",
      match: "Die Passwörter müssen übereinstimmen.",
    },
    identity: {
      required: "Bitte geben Sie Ihre Personalausweisnummer ein.",
      length: "Die Personalausweisnummer muss aus 11 Ziffern bestehen.",
    },
    credential: {
      required: "Bitte geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein.",
      credential:
        "Sie haben eine ungültige E-Mail-Adresse oder ein ungültiges Passwort eingegeben.",
    },
    required: "Bitte füllen Sie dieses Feld aus.",
  },
  validators_error: {
    required: "Bitte ausfüllen.",
    typeError: "Bitte geben Sie einen geeigneten Datentyp ein.",
    email: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
    minlength: " kann nicht weniger als Zeichen haben.",
    maxlength: " kann nicht mehr als Zeichen haben.",
    zeroValue: "Wert darf nicht kleiner als 0 sein.",
  },
  menuPages: {
    name: "Seiten",
    dashboard: {
      pageName: "Dashboard",
      statistics: {
        header: "Schnellzugriff",
        barnyardList: "Hofliste",
        animalList: "Tierliste",
        addAnimal: "Neues Tier hinzufügen",
        accounting: "Buchhaltung",
        weight: "Gewicht",
        animalTag: "Tiermarken",
        breeds: "Tierarten",
      },
      tenantInfo: {
        header: "Betriebsinformationen",
        desc: "Detaillierte Informationen zum Betrieb...",
        tenant: "Betrieb",
        barnyardCount: "Anzahl der Höfe",
        animalCount: "Anzahl der Tiere",
        occupancy: "Auslastung",
        totalAnimalValue: "Gesamtwert der Tiere",
        totalAmount: "Gesamtbetrag",
        unit: "Stück",
        kilo: "Kilogramm",
      },
      animalWeight: {
        header: "Tiergewichtszunahme",
        desc: "Statistiken zur Gewichtszunahme der Tiere...",
      },
      mostValuableAnimal: {
        header: "Wertvollstes Tier",
        desc: "Detaillierte Informationen zum wertvollsten Tier auf dem Betrieb...",
        animal: {
          name: "Tiername",
          eartag: "Ohrmarke",
          gender: "Geschlecht",
          weight: "Gewicht",
          color: "Farbe",
          value: "Wert",
        },
      },
      accounting: {
        header: "Buchhaltung",
        desc: "Informationen zur Buchhaltung...",
      },
    },
    error404: {
      nothing: "Hier scheint nichts zu sein.",
      return: "Zurück zur Startseite",
    },
    error500: {
      error: "Systemfehler",
      description:
        "Etwas ist schief gelaufen! Bitte versuchen Sie es später erneut!",
      return: "Zurück zur Startseite",
    },
    animalList: {
      pageName: "Tiere",
      animalList: "Tierliste",
      animal: "Tier",
      earTag: "Ohrmarke",
      gender: "Geschlecht",
      color: "Farbe",
      barnyard: "Hof",
      lastWeight: "Letztes Gewicht",
      status: "Status",
      pregnant: "Trächtig",
      search: "Tier suchen",
      tag: "Etikett",
      zeroCount: "Keine Tiere gefunden.",
      seeAll: "Alle anzeigen",
    },
    createAnimal: {
      pageName: "Tier hinzufügen",
      photo: "Foto",
      photoDesc: "Erlaubte Dateitypen: png, jpg, jpeg..",
      race: "Rasse",
      selectRace: "Rasse auswählen...",
      gender: "Geschlecht",
      selectGender: "Geschlecht auswählen...",
      earringNumber: "Ohrmarke",
      nickname: "Spitzname",
      lastWeight: "Letztes Gewicht",
      motherEarNumber: "Mutter-Ohrmarke",
      fatherEarNumber: "Vater-Ohrmarke",
      price: "Kaufpreis",
      companyInfo: "Firmeninformationen",
      companyFirstDate: "Erstes Datum des Eintritts ins Unternehmen",
      companyEnterDesc: "Grund für den Eintritt ins Unternehmen",
      healthInfo: "Gesundheitsinformationen",
      barnyard: "Hof",
      selectBarnyard: "Hof auswählen...",
      status: "Status",
      selectStatus: "Status auswählen...",
      tags: "Tags",
      tagsValid: "Maximal 3 Tags können ausgewählt werden.",
      selectTags: "Auswählen...",
      color: "Farbe",
      birthDate: "Geburtsdatum",
    },
    weight: {
      pageName: "Gewichte",
      animals: "Tiere",
      myWeight: "Mein Gewicht",
      animal: "Tier",
      barnyard: "Hof",
      lastWeight: "Letztes Gewicht",
      liveWeight: "Lebendgewicht",
      status: "Status",
      description: "Beschreibung",
      addWeight: "Gewicht hinzufügen",
    },
    addWeight: {
      pageName: "Gewicht hinzufügen",
      pageDescription:
        "Bitte geben Sie die erforderlichen Informationen ein, um das Gewicht des Tieres einzugeben.",
      liveWeight: "Lebendgewicht",
      enterWeight: "Gewicht eingeben...",
      date: "Datum",
      enterDate: "Datum wählen",
      description: "Beschreibung",
      enterDescription: "Beschreibung eingeben...",
    },
    myWeight: {
      pageName: "Mein Gewicht",
      animals: "Tiere",
      myWeight: "Mein Gewicht",
      animal: "Tier",
      barnyard: "Hof",
      date: "Datum",
      lastWeight: "Letztes Gewicht",
      liveWeight: "Lebendgewicht",
      weightResult: "Ergebnis des Gewichts",
      zeroCount: "Keine Gewichtsinformationen gefunden.",
    },
    animalWeight: {
      pageName: "Tiergewichte",
      graphic: "Grafik",
      list: "Liste",
    },
    vaccineSelect: {
      pageName: "Impfstoff",
      animalSelection: "Tierauswahl",
      vaccination: "Impfung",
      animal: "Tier",
      barnyard: "Hof",
    },
    vaccination: {
      pageName: "Impfung",
      vaccinationList: "Impfungsliste",
      vaccinationInfos: "Impfungsinfos",
      animalSelection: "Tierauswahl",
      vaccination: "Impfung",
      animal: "Tier",
      id: "Nr.",
      barnyard: "Hof",
      vaccine: "Impfstoff",
      mililiter: "Ml",
      price: "Preis",
      date: "Datum",
      description: "Beschreibung",
      active: "Aktiv",
      status: "Status",
      createVaccination: "Impfung erstellen",
      addVaccination: "Impfung hinzufügen",
      addAllVaccination: "Allen impfen",
      zeroCount: "Keine Impfinformationen gefunden.",
      past: " Tage vergangen",
      future: " Tage übrig",
    },
    vaccineList: {
      pageName: "Impfstoffliste",
      id: "Nr.",
      barnyard: "Hof",
      vaccine: "Impfstoff",
      animalName: "Tiername",
      earTag: "Ohrmarke",
      date: "Datum",
      status: "Status",
      search: "Impfstoff suchen",
      zeroCount: "Impfstoff nicht gefunden.",
    },
    vaccinate: {
      pageName: "Impfung durchführen",
      pageDescription: "Geben Sie die Impfaufzeichnungen vollständig ein.",
      vaccine: "Impfstoff",
      selectVaccine: "Impfstoff auswählen...",
      amount: "Menge (ml)",
      totalAmount: "Gesamtmenge (ml)",
      enterAmount: "Menge eingeben...",
      date: "Datum",
      enterDate: "Datum auswählen",
      description: "Beschreibung",
      enterDescription: "Beschreibung eingeben...",
    },
    addPrice: {
      pageName: "Preis aktualisieren",
      pageDescription: "Geben Sie bitte die Preisinformationen ein.",
      breed: "Tier Rasse",
      selectBreed: "Rasse auswählen...",
      price: "Preis",
      enterPrice: "Preis eingeben...",
      currency: "Währung",
      enterCurrency: "Währung eingeben...",
      description: "Beschreibung",
      enterDescription: "Beschreibung eingeben...",
    },
    food: {
      pageName: "Futter",
      feedAnimal: "Füttern",
      selectFood: "Futter auswählen...",
      foodList: "Futterliste",
      pageDescription:
        "Wählen Sie die Art des Futters aus, das Sie verabreichen möchten, und geben Sie die erforderlichen Informationen ein.",
      barnyard: "Hof",
      selectBarnyard: "Hof auswählen...",
      numberOfAnimals: "Anzahl der gefütterten Tiere",
      foodName: "Futternamen",
      date: "Datum",
      enterDate: "Datum auswählen...",
      amount: "Menge (kg)",
      totalAmount: "Gesamtmenge (kg)",
      unitType: "Einheit",
      enterAmount: "Menge eingeben...",
      description: "Beschreibung",
      enterDescription: "Beschreibung eingeben...",
      button: {
        prepareFood: "Futtermischung vorbereiten",
        addFood: "Futter erstellen",
        feedAnimal: "Füttern",
        feedAllAnimal: "Alle füttern",
      },
      zeroCount: "Futteraufzeichnung nicht gefunden.",
    },
    animalFeeds: {
      pageName: "Futterliste",
      foodName: "Futternamen",
      productDescription: "Produktbeschreibung",
      amount: "Menge (kg)",
      unitTypeName: "Einheit",
      description: "Beschreibung",
      completedDate: "Datum",
      zeroCount: "Futteraufzeichnung nicht gefunden.",
    },
    barnyards: {
      pageName: "Höfe",
      barnyard: "Hof",
      withoutBarnyard: "Ohne Hof",
      outBarnyard: "Außerhalb des Hofes",
      show: "Anzeigen",
      zeroBarnyard: "Kein Hof gefunden.",
    },
    barnyardPanel: {
      pageName: "Gehege Panel",
      tabMenu: {
        padokInfo: "Gehege Informationen",
        animals: "Tiere",
        vaccination: "Impfung",
        feed: "Fütterung",
        weight: "Gewicht",
        treatment: "Behandlung",
        overheads: "Overheads",
      },
      barnyardName: "Gehege Name",
      capacity: "Kapazität",
      count: "Tieranzahl",
      barnyardLocation: "Gehegelage",
      companyName: "Firmenname",
    },
    animalInfo: {
      pageName: "Tierinformationen",
      info: "Profil",
      edit: "Bearbeiten",
      tabMenu: {
        weight: "Gewicht",
        vaccination: "Impfung",
        feed: "Fütterung",
        treatment: "Behandlung",
        overheads: "Overheads",
        diseases: "Krankheiten",
      },
      photo: "Foto",
      photoDesc: "Zugelassene Dateitypen: png, jpg, jpeg..",
      race: "Rasse",
      selectRace: "Rasse auswählen...",
      gender: "Geschlecht",
      selectGender: "Geschlecht auswählen...",
      earringNumber: "Ohrmarkennummer",
      nickname: "Spitzname",
      lastWeight: "Letztes Gewicht",
      motherEarNumber: "Mutter Ohrmarkennummer",
      fatherEarNumber: "Vater Ohrmarkennummer",
      price: "Preis",
      companyInfo: "Firmeninformationen",
      companyFirstDate: "Erstes Firmendatum",
      companyEnterDesc: "Firmeneingangsgrund",
      healthInfo: "Gesundheitsinformationen",
      vaccinations: "Impfungen",
      diseases: "Krankheiten",
      barnyard: "Gehege",
      selectBarnyard: "Gehege auswählen...",
      status: "Status",
      selectStatus: "Status auswählen...",
      color: "Farbe",
      description: "Beschreibung",
    },
    breed: {
      pageName: "Tier Rassen",
      name: "Rassenname",
      description: "Beschreibung",
      animalType: "Tierart",
      isDefault: "Standard",
      price: "Preis",
      button: {
        addBreed: "Rasse hinzufügen",
      },
      zeroCount: "Tier Rasse nicht gefunden.",
    },
    disease: {
      pageName: "Krankheiten",
      diseaseList: "Krankheitsliste",
      name: "Krankheitsname",
      description: "Beschreibung",
      createDate: "Erstellungsdatum",
      updateDate: "Aktualisierungsdatum",
      button: {
        addDisease: "Krankheit hinzufügen",
      },
      zeroCount: "Krankheitsaufzeichnung nicht gefunden.",
    },
    addDisease: {
      pageName: "Krankheit hinzufügen",
      pageDescription:
        "Bitte geben Sie die erforderlichen Informationen ein, um einen Krankheitseintrag für das Tier hinzuzufügen.",
      disease: "Krankheit",
      selectDisease: "Krankheit auswählen...",
      startDate: "Startdatum",
      enterStartDate: "Datum auswählen...",
      endDate: "Enddatum",
      enterEndDate: "Datum auswählen...",
      end: "Geheilt",
    },
    buyFeed: {
      pageName: "Futter kaufen",
      feed: "Futternamen",
      description: "Beschreibung",
      grammage: "Gewicht",
      unitPrice: "Stückpreis",
      quantity: "Menge (kg)",
      purchaseDate: "Datum",
      button: {
        buy: "Kaufen",
      },
    },
    animalTags: {
      pageName: "Tier Tags",
      id: "Id",
      shortCode: "Code",
      name: "Name",
      description: "Beschreibung",
      isDefault: "Standard",
      button: {
        remove: "Entfernen",
        add: "Tier Tag hinzufügen",
      },
      zeroCount: "Tier Tag nicht gefunden.",
    },
    animalTypes: {
      pageName: "Tierarten",
      id: "Id",
      shortCode: "Code",
      name: "Name",
      description: "Beschreibung",
      button: {
        remove: "Entfernen",
        add: "Tierart hinzufügen",
      },
      zeroCount: "Tierart nicht gefunden.",
    },
    addAnimalTag: {
      pageName: "Tier Tag hinzufügen",
      pageDescription:
        "Bitte geben Sie die folgenden Informationen ein, um einen Tier Tag hinzuzufügen.",
      animalTag: "Tag Name",
      enterAnimalTag: "Tier Tag eingeben...",
      description: "Beschreibung",
      enterDescription: "Beschreibung eingeben...",
    },
    addBreed: {
      pageName: "Tier Rasse hinzufügen",
      updateBreed: "Tier Rasse aktualisieren",
      pageDescription:
        "Bitte geben Sie die folgenden Informationen ein, um eine Tier Rasse hinzuzufügen.",
      breedName: "Rassenname",
      enterBreedName: "Rasse eingeben...",
      animalType: "Tierart",
      selectAnimalType: "Tierart auswählen...",
      description: "Beschreibung",
      enterDescription: "Beschreibung eingeben...",
    },
    animalTransfer: {
      pageName: "Tiergehege Transfer",
      pageDescription:
        "Bitte geben Sie das Gehege ein, in das das Tier übertragen werden soll, und geben Sie alle erforderlichen Informationen ein.",
      transfer: "Transfer",
      animal: "Tier",
      selectAnimal: "Tier auswählen...",
      barnyard: "Gehege",
      selectBarnyard: "Gehege auswählen...",
      date: "Transferdatum",
      selectDate: "Datum auswählen...",
      description: "Beschreibung",
      enterDescription: "Beschreibung eingeben...",
    },
    forgotPassword: {
      pageName: "Passwort vergessen",
      pageDescription: "Bitte geben Sie Ihre E-Mail-Adresse ein.",
      email: "E-Mail",
    },
    resetPassword: {
      pageName: "Passwort zurücksetzen",
      pageDescription: "Bitte geben Sie Ihr neues Passwort ein.",
      newPassword: "Neues Passwort",
      newPasswordAgain: "Neues Passwort erneut eingeben",
    },
  },
};
