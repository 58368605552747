import { BaseDTO } from "@/core/base/BaseDTO";

export class ForgotPasswordRequestDto extends BaseDTO {
  email?: string;

  constructor(email?: string) {
    super();
    this.email = email;
  }
}
