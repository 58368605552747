import { BaseDTO } from "@/core/base/BaseDTO";

export class ResetPasswordRequestDto extends BaseDTO {
  code?: string;
  password?: string;
  passwordConfirmation?: string;

  constructor(code?: string, password?: string, passwordConfirmation?: string) {
    super();
    this.code = code;
    this.password = password;
    this.passwordConfirmation = passwordConfirmation
  }
}
