
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IBarnyardDetailUseCase } from "@/domain/barnyard/barnyardDetail/usecase/IBarnyardDetailUseCase";
import { BarnyardDetailModel } from "@/domain/barnyard/barnyardDetail/model/BarnyardDetailModel";
import { BarnyardDetailRepository } from "../repository/BarnyardDetailRepository";
import { BarnyardDetailMapper } from "../mapper/BarnyardDetailMapper";

export class BarnyardDetailUseCase implements IBarnyardDetailUseCase {
  getBarnyardDetailRepository: BarnyardDetailRepository;
  getBarnyardDetailMapper = new BarnyardDetailMapper();

  constructor(_getBarnyardDetailRepository: BarnyardDetailRepository) {
    this.getBarnyardDetailRepository = _getBarnyardDetailRepository;
  }

  async execute(model: BarnyardDetailModel): Promise<Result<BarnyardDetailModel>> {
    try {
      const dto = this.getBarnyardDetailMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<BarnyardDetailModel>(dto.getError());
      } else {
        const response = await this.getBarnyardDetailRepository.getAnimal(dto);

        if (response.isSuccess) {
          const domain = this.getBarnyardDetailMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<BarnyardDetailModel>(domain.getValue());
        } else {
          return Result.fail<BarnyardDetailModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<BarnyardDetailModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_GET))
      );
    }
  }
}
