import { AnimalDiseaseListModel } from "@/domain/animal/animalDisease/animalDiseaseList/model/AnimalDiseaseListModel";
import { AnimalDiseaseListMapper } from "../mapper/AnimalDiseaseListMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalDiseaseListUseCase } from "@/domain/animal/animalDisease/animalDiseaseList/usecase/IAnimalDiseaseListUsecase";
import { AnimalDiseaseListRepository } from "../repository/AnimalDiseaseListRepository";

export class AnimalDiseaseListUsecase implements IAnimalDiseaseListUseCase {
  animalDiseaseListRepository: AnimalDiseaseListRepository;
  animalDiseaseListMapper = new AnimalDiseaseListMapper();

  constructor(_animalDiseaseListRepository: AnimalDiseaseListRepository) {
    this.animalDiseaseListRepository = _animalDiseaseListRepository;
  }

  async execute(
    model: AnimalDiseaseListModel
  ): Promise<Result<AnimalDiseaseListModel>> {
    try {
      const dto = this.animalDiseaseListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalDiseaseListModel>(dto.getError());
      } else {
        const response = await this.animalDiseaseListRepository.animalDiseaseList(
          dto
        );

        if (response.isSuccess) {
          const domain = this.animalDiseaseListMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<AnimalDiseaseListModel>(domain.getValue());
        } else {
          return Result.fail<AnimalDiseaseListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AnimalDiseaseListModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_DISEASE_LIST))
      );
    }
  }
}
