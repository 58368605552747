import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { AnimalTagAddRepository } from "@/application/animalTag/animalTagAdd/repository/AnimalTagAddRepository";
import { AnimalTagListRepository } from "@/application/animalTag/animalTagList/repository/AnimalTagListRepository";
import { AnimalTagRemoveRepository } from "@/application/animalTag/animalTagRemove/repository/AnimalTagRemoveRepository";
import { AnimalTagAddUsecase } from "@/application/animalTag/animalTagAdd/usecase/AnimalTagAddUseCase";
import { AnimalTagListUsecase } from "@/application/animalTag/animalTagList/usecase/AnimalTagListUseCase";
import { AnimalTagRemoveUsecase } from "@/application/animalTag/animalTagRemove/usecase/AnimalTagRemoveUseCase";
import { AnimalTagListModel } from "@/domain/animalTag/animalTagList/model/AnimalTagListModel";
import { AnimalTagAddModel } from "@/domain/animalTag/animalTagAdd/model/AnimalTagAddModel";
import { AnimalTagRemoveModel } from "@/domain/animalTag/animalTagRemove/model/AnimalTagRemoveModel";
import { AnimalTagUpdateModel } from "@/domain/animalTag/animalTagUpdate/model/AnimalTagUpdateModel";
import { AnimalTagUpdateRepository } from "@/application/animalTag/animalTagUpdate/repository/AnimalTagUpdateRepository";
import { AnimalTagUpdateUsecase } from "@/application/animalTag/animalTagUpdate/usecase/AnimalTagUpdateUseCase";

export function createAnimalTagController() {
  const animalTagAddRepository = new AnimalTagAddRepository();
  const animalTagListRepository = new AnimalTagListRepository();
  const animalTagRemoveRepository = new AnimalTagRemoveRepository();
  const animalTagUpdateRepository = new AnimalTagUpdateRepository();

  const animalTagAddUseCase = new AnimalTagAddUsecase(animalTagAddRepository);
  const animalTagListUseCase = new AnimalTagListUsecase(
    animalTagListRepository
  );
  const animalTagRemoveUseCase = new AnimalTagRemoveUsecase(
    animalTagRemoveRepository
  );
  const animalTagUpdateUseCase = new AnimalTagUpdateUsecase(
    animalTagUpdateRepository
  );

  return new AnimalTagController(
    animalTagAddUseCase,
    animalTagListUseCase,
    animalTagRemoveUseCase,animalTagUpdateUseCase
  );
}

export class AnimalTagController implements IController {
  constructor(
    private animalTagAddUseCase: AnimalTagAddUsecase,
    private animalTagListUseCase: AnimalTagListUsecase,
    private animalTagRemoveUseCase: AnimalTagRemoveUsecase,
    private animalTagUpdateUseCase: AnimalTagUpdateUsecase
  ) {}

  async animalTagList(): Promise<Result<AnimalTagListModel>> {
    const response = await this.animalTagListUseCase.execute();

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalTagListModel>(response.getError());
    }
  }

  async addAnimalTag(
    animalTagAddModel: AnimalTagAddModel
  ): Promise<Result<AnimalTagAddModel>> {
    const response = await this.animalTagAddUseCase.execute(animalTagAddModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalTagAddModel>(response.getError());
    }
  }

  async removeAnimalTag(
    animalTagRemoveModel: AnimalTagRemoveModel
  ): Promise<Result<AnimalTagRemoveModel>> {
    const response = await this.animalTagRemoveUseCase.execute(
      animalTagRemoveModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalTagRemoveModel>(response.getError());
    }
  }

  async updateAnimalTag(
    animalTagUpdateModel: AnimalTagUpdateModel
  ): Promise<Result<AnimalTagUpdateModel>> {
    const response = await this.animalTagUpdateUseCase.execute(
      animalTagUpdateModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalTagUpdateModel>(response.getError());
    }
  }
}
