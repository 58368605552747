import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalTagListRequestDto } from "@/domain/animalTag/animalTagList/dto/AnimalTagListRequestDto";
import { AnimalTagListResponseDto } from "@/domain/animalTag/animalTagList/dto/AnimalTagListResponseDto";
import { AnimalTagListModel } from "@/domain/animalTag/animalTagList/model/AnimalTagListModel";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";

const paginationMapper = new PaginationMapper();

export class AnimalTagListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AnimalTagListResponseDto): Result<AnimalTagListModel[]> {
    try {
      const animalTagList: AnimalTagListModel[] = [];

      for (const item of dto.data) {
        const animalTagListModel: AnimalTagListModel = {
          animalTag: {
            id: item.id,
            name: item.attributes.name,
            shortCode: item.attributes.shortCode,
            description: item.attributes.description,
            isDefault: item.attributes.isDefault
          },
          page: 0,
          pageSize: 0,
          pagination: dto.meta.pagination
        };
        animalTagList.push(animalTagListModel);
      }

      return Result.ok(animalTagList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_TAG_LIST))
      );
    }
  }

  mapToDTO(domain: AnimalTagListModel): Result<AnimalTagListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const animalTagListRequest = new AnimalTagListRequestDto(
        pagiRequest,
      );
      
      return Result.ok(animalTagListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_TAG_LIST))
      );
    }
  }
}
