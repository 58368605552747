import { BaseDTO } from "@/core/base/BaseDTO";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";

export class AnimalDiseaseListRequestDto extends BaseDTO {
  pagination: PaginationRequestDto;
  id?: number;

  constructor(pagination: PaginationRequestDto, id?: number) {
    super();
    this.pagination = pagination;
    this.id = id;
  }

  toQueryString(): string {
    let queryString = `?pagination[page]=${this.pagination.pagination.page}&pagination[pageSize]=${this.pagination.pagination.pageSize}`;

    return queryString;
  }
}
