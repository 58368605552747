import { VALUE } from "@/domain/Constant";
import * as crypto from "crypto";
import { CryptoKeyService } from "./CryptoKeyService";

const algorithm = "aes-256-cbc"; // AES algoritması

export class AES256EncryptionService {
  static encrypt(text: string | null): string {
    const key = CryptoKeyService.getKeyFromStorage();
    const iv = CryptoKeyService.getIvFromStorage();

    if (text && key && iv) {
      const cipher = crypto.createCipheriv(algorithm, key, iv);
      let encrypted = cipher.update(text, "utf-8", "hex");
      encrypted += cipher.final("hex");
      return encrypted;
    }
    return VALUE.NULL_STRING;
  }

  static decrypt(encryptedText: string | null): string {
    const key = CryptoKeyService.getKeyFromStorage();
    const iv = CryptoKeyService.getIvFromStorage();

    if (encryptedText && key && iv) {
      const decipher = crypto.createDecipheriv(algorithm, key, iv);
      const decrypted = decipher.update(encryptedText, "hex", "utf-8");
      return decrypted;
    }
    return VALUE.NULL_STRING;
  }
}
