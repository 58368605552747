import { Result } from "@/core/utility/ResultType";
import { BadRequestException } from "@/core/exception/BadRequestException";
import UpdateAnimalService from "../service/UpdateAnimalService";
import { IUpdateAnimalRepository } from "@/domain/animal/animalUpdate/repository/IUpdateAnimalRepository";
import { UpdateAnimalRequestDto } from "@/domain/animal/animalUpdate/dto/UpdateAnimalRequestDto";
import { UpdateAnimalResponseDto } from "@/domain/animal/animalUpdate/dto/UpdateAnimalResponseDto";
import { GetAnimalDetailResponseDto } from "@/domain/animal/animalDetail/dto/GetAnimalDetailResponseDto";
import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { REPOSITORY } from "@/domain/Constant";

const updateAnimalService = new UpdateAnimalService();

export class UpdateAnimalRepository implements IUpdateAnimalRepository {
  async updateAnimal(
    request: UpdateAnimalRequestDto
  ): Promise<Result<UpdateAnimalResponseDto>> {
    try {
      return await updateAnimalService
        .post(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<GetAnimalDetailResponseDto>(response.getValue());
          } else {
            return Result.fail<GetAnimalDetailResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<GetAnimalDetailResponseDto>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<GetAnimalDetailResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_UPDATE))
      );
    }
  }
}
