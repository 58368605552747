
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/presentation/store/enums/StoreEnums";

import { COMPONENT_NAME } from "./domain/Constant";
import { CryptoKeyService } from "./infrastructure/encryption/CryptoKeyService";
import * as crypto from "crypto";

export default defineComponent({
  name: COMPONENT_NAME.APP,
  setup() {
    const store = useStore();

    onMounted(() => {
      const key = crypto.randomBytes(32);
      const iv = crypto.randomBytes(16);
      CryptoKeyService.setKeyAndIvToStorage(key, iv);

      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/presentation/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });
  },
});
