import { Entity } from "../validation/Entity";
import { IDomainEvent } from "../events/IDomainEvent";
import { DomainEvents } from "../events/DomainEvents";
import { UniqueEntityID } from "../validation/UniqueEntityID";
import { Result } from "@/core/utility/ResultType";
import { AggregateRootException } from "@/core/exception/base/AggregateRootException";
import { UnknownCause } from "@/core/cause/UnknownCause";

export abstract class AggregateRoot<T> extends Entity<T> {
  private _domainEvents: IDomainEvent[] = [];

  get id(): UniqueEntityID {
    return this._id;
  }

  get domainEvents(): IDomainEvent[] {
    return this._domainEvents;
  }

  protected addDomainEvent(domainEvent: IDomainEvent): Result<any> {
    try {
      this._domainEvents.push(domainEvent);
      DomainEvents.markAggregateForDispatch(this);

      return Result.ok<any>("");
    } catch {
      return Result.fail<any>(
        new AggregateRootException(new UnknownCause("AggregateRoot")),
      );
    }
  }

  public dispatchDomainEvents() {
    DomainEvents.dispatchEventsForAggregate(this.id)
    this.clearEvents()
  }

  public clearEvents(): void {
    this._domainEvents.splice(0, this._domainEvents.length);
  }
}
