import { AnimalFeedAddRepository } from "../repository/AnimalFeedAddRepository";
import { AnimalFeedAddMapper } from "../mapper/AnimalFeedAddMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalFeedAddUseCase } from "@/domain/animal/animalFeed/animalFeedAdd/usecase/IAnimalFeedAddUsecase";
import { AnimalFeedAddModel } from "@/domain/animal/animalFeed/animalFeedAdd/model/AnimalFeedAddModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../../environment";

export class AnimalFeedAddUsecase implements IAnimalFeedAddUseCase {
  addAnimalFeedRepository: AnimalFeedAddRepository;
  addAnimalFeedMapper = new AnimalFeedAddMapper();

  constructor(_addAnimalFeedRepository: AnimalFeedAddRepository) {
    this.addAnimalFeedRepository = _addAnimalFeedRepository;
  }

  async execute(
    model: AnimalFeedAddModel
  ): Promise<Result<AnimalFeedAddModel>> {
    try {
      const dto = this.addAnimalFeedMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<AnimalFeedAddModel>(dto.getError());
      } else {
        const response =
          await this.addAnimalFeedRepository.addAnimalFeed(dto);

        if (response.isSuccess) {

          const domain = this.addAnimalFeedMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
          const analytics = getAnalytics();
          logEvent(analytics, 'animal_feed', {
            product_id: model.product
          });
        }

          return Result.ok<AnimalFeedAddModel>(domain.getValue());
        } else {
          return Result.fail<AnimalFeedAddModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AnimalFeedAddModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_FEED_ADD))
      );
    }
  }
}
