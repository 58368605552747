import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import BreedRemoveService from "../service/BreedRemoveService";
import { IBreedRemoveRepository } from "@/domain/breed/breedRemove/repository/IBreedRemoveRepository";
import { BreedRemoveRequestDto } from "@/domain/breed/breedRemove/dto/BreedRemoveRequestDto";

const removeBreedService = new BreedRemoveService();

export class BreedRemoveRepository implements IBreedRemoveRepository {
  async removeBreed(
    request: BreedRemoveRequestDto
  ): Promise<Result<void>> {
    try {
      return await removeBreedService
        .remove(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<void>(response.getValue());
          } else {
            return Result.fail<void>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<void>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<void>(
        new RepositoryException(new UnknownCause(REPOSITORY.BREED_REMOVE))
      );
    }
  }
}
