import { AnimalTagListRepository } from "../repository/AnimalTagListRepository";
import { AnimalTagListMapper } from "../mapper/AnimalTagListMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalTagListUseCase } from "@/domain/animalTag/animalTagList/usecase/IAnimalTagListUsecase";
import { AnimalTagListModel } from "@/domain/animalTag/animalTagList/model/AnimalTagListModel";

export class AnimalTagListUsecase implements IAnimalTagListUseCase {
  animalTagListRepository: AnimalTagListRepository;
  animalTagListMapper = new AnimalTagListMapper();

  constructor(_animalTagListRepository: AnimalTagListRepository) {
    this.animalTagListRepository = _animalTagListRepository;
  }

  async execute(): Promise<Result<AnimalTagListModel>> {
    try {
      const response = await this.animalTagListRepository.getAnimalTagList();

      if (response.isSuccess) {
        const domain = this.animalTagListMapper.mapToDomain(
          response.getValue()
        );
        return Result.ok<AnimalTagListModel>(domain.getValue());
      } else {
        return Result.fail<AnimalTagListModel>(response.getError());
      }
    } catch (error) {
      return Result.fail<AnimalTagListModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_TAG_LIST))
      );
    }
  }
}
