interface ValueObjectProps {
  [index: string]: any;
}

/**
 * @desc ValueObjects are objects that we determine their
 * equality through their structrual property.
 */

export abstract class BaseValueObject<T extends ValueObjectProps> {
  public props: T;

  constructor(props: T) {
    const baseProps: any = {
      ...props,
    };

    this.props = baseProps;
  }

  public equals(p: BaseValueObject<T>): boolean {
    return this.isSameClass(p) && this.hasEqualProps(p);
  }

  private isSameClass(p: BaseValueObject<T>): boolean {
    return this.constructor === p.constructor;
  }

  private hasEqualProps(p: BaseValueObject<T>): boolean {
    if (Object.keys(this.props).length !== Object.keys(p.props).length)
      return false;
    for (const key in this.props)
      if (this.props[key] !== p.props[key]) return false;
    return true;
  }
}
