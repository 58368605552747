import LoginRepository from "@/application/authentication/userLogin/repository/LoginRepository";
import { LoginUseCase } from "@/application/authentication/userLogin/usecase/LoginUseCase";

import { Result } from "@/core/utility/ResultType";
import { User } from "@/domain/authentication/loginUser/entity/User";
import { LoginModel } from "@/domain/authentication/loginUser/model/LoginModel";
import router from "../router";
import { IController } from "@/core/base/IController";
import { AboutUserModel } from "@/domain/authentication/aboutUser/model/AboutUserModel";
import AboutUserRepository from "@/application/authentication/aboutUser/repository/AboutUserRepository";
import { AboutUserUseCase } from "@/application/authentication/aboutUser/usecase/AboutUserUseCase";
import { ChangeTenantEstateModel } from "@/domain/authentication/changeTenantEstate/model/ChangeTenantEstateModel";
import ChangeTenantEstateRepository from "@/application/authentication/changeTenantEstate/repository/ChangeTenantEstateRepository";
import { ChangeTenantEstateUseCase } from "@/application/authentication/changeTenantEstate/usecase/ChangeTenantEstateUseCase";
import { ResetPasswordRepository } from "@/application/authentication/resetPassword/repository/ResetPasswordRepository";
import { ForgotPasswordRepository } from "@/application/authentication/forgotPassword/repository/ForgotPasswordRepository";
import { ResetPasswordUsecase } from "@/application/authentication/resetPassword/usecase/ResetPasswordUseCase";
import { ForgotPasswordUsecase } from "@/application/authentication/forgotPassword/usecase/ForgotPasswordUseCase";
import { ResetPasswordModel } from "@/domain/authentication/resetPassword/model/ResetPasswordModel";
import { ForgotPasswordModel } from "@/domain/authentication/forgotPassword/model/ForgotPasswordModel";
import { ROUTE_PAGES } from "@/domain/Constant";

export function createUserController() {
  const loginRepository = new LoginRepository();
  const aboutUserRepository = new AboutUserRepository();
  const changeTenantEstateRepository = new ChangeTenantEstateRepository();
  const resetPasswordRepository = new ResetPasswordRepository();
  const forgotPasswordRepository = new ForgotPasswordRepository();

  const loginUseCase = new LoginUseCase(loginRepository);
  const aboutUserUseCase = new AboutUserUseCase(aboutUserRepository);
  const changeTenantEstateUseCase = new ChangeTenantEstateUseCase(changeTenantEstateRepository);
  const resetPasswordUseCase = new ResetPasswordUsecase(resetPasswordRepository);
  const forgotPasswordUseCase = new ForgotPasswordUsecase(forgotPasswordRepository);

  return new UserController(loginUseCase, aboutUserUseCase, changeTenantEstateUseCase, resetPasswordUseCase, forgotPasswordUseCase);
}

export class UserController implements IController {
  constructor(
    private loginUseCase: LoginUseCase,
    private aboutUserUseCase: AboutUserUseCase,
    private changeTenantEstateUseCase: ChangeTenantEstateUseCase,
    private resetPasswordUseCase: ResetPasswordUsecase,
    private forgotPasswordUseCase: ForgotPasswordUsecase
  ) {}

  async login(loginModel: LoginModel): Promise<Result<LoginModel>> {
    const userResult: Result<User> = User.create({
      email: loginModel.email,
      password: loginModel.password,
    });

    if (userResult.isSuccess) {
      const user: User = userResult.getValue();
      return user.login().then((loginResult) => {
        if (loginResult.isSuccess) {
          router.push({ name: ROUTE_PAGES.DASHBOARD });
        }
        return loginResult;
      });
    } else {
      return Result.fail<LoginModel>(userResult.getError());
    }
  }

  async getUser(): Promise<Result<AboutUserModel>> {
    const response = await this.aboutUserUseCase.execute();

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AboutUserModel>(response.getError());
    }
  }

  async changeTenantEstate(model: ChangeTenantEstateModel): Promise<Result<ChangeTenantEstateModel>> {
    const response = await this.changeTenantEstateUseCase.execute(model);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AboutUserModel>(response.getError());
    }
  }

  async resetPassword(model: ResetPasswordModel): Promise<Result<void>> {
    const response = await this.resetPasswordUseCase.execute(model);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<void>(response.getError());
    }
  }

  async forgotPassword(model: ForgotPasswordModel): Promise<Result<ForgotPasswordModel>> {
    const response = await this.forgotPasswordUseCase.execute(model);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<ForgotPasswordModel>(response.getError());
    }
  }
}
