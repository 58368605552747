import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalDiseaseAddRequestDto } from "@/domain/animal/animalDisease/animalDiseaseAdd/dto/AnimalDiseaseAddRequestDto";
import { AnimalDiseaseAddResponseDto } from "@/domain/animal/animalDisease/animalDiseaseAdd/dto/AnimalDiseaseAddResponseDto";
import { AnimalDiseaseAddModel } from "@/domain/animal/animalDisease/animalDiseaseAdd/model/AnimalDiseaseAddModel";

export class AnimalDiseaseAddMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AnimalDiseaseAddResponseDto): Result<AnimalDiseaseAddModel> {
    try {
      const addAnimalDiseaseModel: AnimalDiseaseAddModel = {
        disease: dto.data.attributes.disease.data.id,
        diseaseName: dto.data.attributes.disease.data.attributes.name,
        diseaseDesc: dto.data.attributes.disease.data.attributes.description,
        startDate: dto.data.attributes.startDate,
        endDate: dto.data.attributes.endDate,
      };
      return Result.ok(addAnimalDiseaseModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_DISEASE_ADD))
      );
    }
  }

  mapToDTO(domain: AnimalDiseaseAddModel): Result<AnimalDiseaseAddRequestDto> {
    try {
      const addAnimalDiseaseRequest = new AnimalDiseaseAddRequestDto(
        domain.animal,
        domain.disease,
        domain.startDate,
        domain.endDate,
      );

      return Result.ok(addAnimalDiseaseRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_DISEASE_ADD))
      );
    }
  }
}
