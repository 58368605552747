import { IValueValidator } from "@/core/base/IValueValidator";
import { EmptyValueCause } from "@/core/cause/EmptyValueCause";
import { NullValueCause } from "@/core/cause/NullValueCause";
import { ValidationException } from "@/core/exception/ValidationException";
import { Result } from "@/core/utility/ResultType";
import { VALIDATOR_ERROR } from "@/domain/Constant";

export default class StringNotBlankValidator
  implements IValueValidator<string>
{
   validate(value: string | null | undefined) : Result<string>{
    if (value == null) {
      return Result.fail<string>(
        new ValidationException(new NullValueCause(VALIDATOR_ERROR.required))
      );
    } else if (value.trim().length == 0) {
      return Result.fail<string>(
        new ValidationException(new EmptyValueCause(VALIDATOR_ERROR.required))
      );
    }
    return Result.ok<string>(value);
  }
}
