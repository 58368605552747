import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalWeightListRequestDto } from "@/domain/animal/animalWeight/animalWeightList/dto/AnimalWeightListRequestDto";
import { AnimalWeightListResponseDto } from "@/domain/animal/animalWeight/animalWeightList/dto/AnimalWeightListResponseDto";
import { AnimalWeightListModel } from "@/domain/animal/animalWeight/animalWeightList/model/AnimalWeightListModel";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";

const paginationMapper = new PaginationMapper();

export class AnimalWeightListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AnimalWeightListResponseDto): Result<AnimalWeightListModel[]> {
    try {
      const animalWeightList: AnimalWeightListModel[] = [];

      for (const item of dto.data) {
        const animalWeightListModel: AnimalWeightListModel = {
          weight: {
            id: item.id,
            animalId: 0,
            weight: item.attributes.weight,
            description: item.attributes.description,
            date: item.attributes.completedDate,
          },
          page: 0,
          pageSize: 0,
          pagination: dto.meta.pagination
        };
        animalWeightList.push(animalWeightListModel);
      }

      return Result.ok(animalWeightList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_WEIGHT_LIST))
      );
    }
  }

  mapToDTO(domain: AnimalWeightListModel): Result<AnimalWeightListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const animalRequest = new AnimalWeightListRequestDto(
        pagiRequest,
      );

      return Result.ok(animalRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_WEIGHT_LIST))
      );
    }
  }
}
