import { User } from "../entity/User";
import { IDomainEvent } from "@/domain/events/IDomainEvent";
import { UniqueEntityID } from "@/domain/validation/UniqueEntityID";

export class UserCreated implements IDomainEvent {
  public dateTimeOccurred: Date;
  public user: User;

  constructor(user: User) {
    this.dateTimeOccurred = new Date();
    this.user = user;
  }

  getAggregateId(): UniqueEntityID {
    return this.user.id;
  }
}
