
import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { AnimalDetailModel } from "@/domain/animal/animalDetail/model/AnimalDetailModel";
import { CreateAnimalRequestDto } from "@/domain/animal/animalAdd/dto/CreateAnimalRequestDto";
import { CreateAnimalResponseDto } from "@/domain/animal/animalAdd/dto/CreateAnimalResponseDto";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/domain/Constant";

export class CreateAnimalMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: CreateAnimalResponseDto): Result<AnimalDetailModel> {
    try {
      const animalModel: AnimalDetailModel = {
        id: dto.data.id,
        earTag: dto.data.attributes.earTag,
        motherEarTag: dto.data.attributes.motherEarTag,
        fatherEarTag: dto.data.attributes.fatherEarTag,
        name: dto.data.attributes.name,
        gender: dto.data.attributes.gender,
        acquisitionDate: dto.data.attributes.acquisitionDate,
        birthDate: dto.data.attributes.birthDate,
        acquisitionPrice: dto.data.attributes.acquisitionPrice,
        acquisitionReason: dto.data.attributes.acquisitionReason,
        liveWeight: dto.data.attributes.liveWeight,
        color: dto.data.attributes.color,
        status: dto.data.attributes.status,
        leaveDate: dto.data.attributes.leaveDate,
        leavePrice: dto.data.attributes.leavePrice,
        leaveReason: dto.data.attributes.leaveReason,
        animalTags: dto.data.attributes.animalTags
      };

      if(dto.data.attributes.profilePhoto.data != null)
      {
        animalModel.profilePhotoUrl = dto.data.attributes.profilePhoto.data.attributes.url;
      }

      return Result.ok(animalModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_ADD))
      );
    }
  }

  mapToDTO(domain: AnimalDetailModel): Result<CreateAnimalRequestDto> {
    try {
      const animalRequest: CreateAnimalRequestDto = {
        data: {
          earTag: domain.earTag,
          name: domain.name,
          motherEarTag: domain.motherEarTag,
          fatherEarTag: domain.fatherEarTag,
          animalBreed: domain.animalBreedId,
          barnyard: Number(domain.barnyard),
          gender: domain.gender,
          acquisitionReason: domain.acquisitionReason,
          acquisitionPrice: Number(domain.acquisitionPrice),
          liveWeight: Number(domain.liveWeight),
          color: domain.color,
          acquisitionDate: domain.acquisitionDate,
          birthDate: domain.birthDate,
          animalTags: domain.animalTags
        },
        profilePhoto: domain.profilePhoto
      };

      return Result.ok(animalRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_ADD))
      );
    }
  }
}
