import { IValueValidator } from "@/core/base/IValueValidator";
import { NotValidEmailCause } from "@/core/cause/NotValidEmailCause";
import { NullValueCause } from "@/core/cause/NullValueCause";
import { ValidationException } from "@/core/exception/ValidationException";
import { Result } from "@/core/utility/ResultType";
import { VALIDATOR_ERROR } from "@/domain/Constant";
import { ErrorLangMessage } from "@/presentation/helper/ErrorMessage";
import { IsNotEmpty, IsEmail, validateSync } from "class-validator";

export default class EmailValidator implements IValueValidator<String> {
  @IsNotEmpty()
  @IsEmail()
  private email: string | null | undefined;

  validate(value: string | null | undefined) : Result<any> {
    this.email = value;
    const errors = validateSync(this);

    if (errors) {
      for (const error of errors) {
        /* istanbul ignore next */
        const errorMessages = (error.constraints || {}) as Record<
          string,
          string
        >;
        if (errorMessages.isNotEmpty) {
          let errorMessage = ErrorLangMessage.getErrorMessage(VALIDATOR_ERROR.required);
          return Result.fail<string>(
            new ValidationException(new NullValueCause(errorMessage))
          );
        } else if (errorMessages.isEmail) {
          let errorMessage = ErrorLangMessage.getErrorMessage(VALIDATOR_ERROR.email);
          return Result.fail<string>(
            new ValidationException(new NotValidEmailCause(errorMessage))
          );
        }
      }
    }
    return Result.ok<any>(value);
  }
}
