import { BaseDTO } from "@/core/base/BaseDTO";

export class AnimalWeightUpdateRequestDto extends BaseDTO {
  data: {
    animal?: number;
    weight?: number;
    completedDate?: Date;
    description?: string;
  };
  constructor(
    animalId?: number,
    weight?: number,
    completedDate?: Date,
    description?: string
  ) {
    super();
    this.data = {
      animal: animalId,
      weight: weight,
      completedDate: completedDate,
      description: description,
    };
  }
}
