import { BaseDTO } from "@/core/base/BaseDTO";

export class AnimalTagUpdateRequestDto extends BaseDTO {
  data: {
    name?: string;
    description?: string;
  };

  constructor(name?: string, description?: string) {
    super();
    this.data = {
      name,
      description
    };
  }
}
