import { IUpdateAnimalUseCase } from "@/domain/animal/animalUpdate/usecase/IUpdateAnimalUsecase";
import { UpdateAnimalRepository } from "../repository/UpdateAnimalRepository";
import { UpdateAnimalMapper } from "../mapper/UpdateAnimalMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { UpdateAnimalModel } from "@/domain/animal/animalUpdate/model/UpdateAnimalModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";


export class UpdateAnimalUseCase implements IUpdateAnimalUseCase {
  updateAnimalRepository: UpdateAnimalRepository;
  updateAnimalMapper = new UpdateAnimalMapper();

  constructor(_updateAnimalRepository: UpdateAnimalRepository) {
    this.updateAnimalRepository = _updateAnimalRepository;
  }

  async execute(model: UpdateAnimalModel): Promise<Result<UpdateAnimalModel>> {
    try {
      const dto = this.updateAnimalMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<UpdateAnimalModel>(dto.getError());
      } else {
        const response = await this.updateAnimalRepository.updateAnimal(dto);

        if (response.isSuccess) {
          const domain = this.updateAnimalMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
          const analytics = getAnalytics();
          logEvent(analytics, 'animal_update', {
            updated_animal_id: model.id
          });
        }

          return Result.ok<UpdateAnimalModel>(domain.getValue());
        } else {
          return Result.fail<UpdateAnimalModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<UpdateAnimalModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_UPDATE))
      );
    }
  }
}
