import { GetAnimalDetailRepository } from "../repository/GetAnimalDetailRepository";
import { AnimalDetailModel } from "@/domain/animal/animalDetail/model/AnimalDetailModel";
import { IGetAnimalDetailUseCase } from "@/domain/animal/animalDetail/usecase/IGetAnimalDetailUseCase";
import { GetAnimalDetailMapper } from "../mapper/GetAnimalDetailMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";

export class GetAnimalDetailUseCase implements IGetAnimalDetailUseCase {
  getAnimalDetailRepository: GetAnimalDetailRepository;
  getAnimalDetailMapper = new GetAnimalDetailMapper();

  constructor(_getAnimalDetailRepository: GetAnimalDetailRepository) {
    this.getAnimalDetailRepository = _getAnimalDetailRepository;
  }

  async execute(model: AnimalDetailModel): Promise<Result<AnimalDetailModel>> {
    try {
      const dto = this.getAnimalDetailMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalDetailModel>(dto.getError());
      } else {
        const response = await this.getAnimalDetailRepository.getAnimal(dto);

        if (response.isSuccess) {
          const domain = this.getAnimalDetailMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<AnimalDetailModel>(domain.getValue());
        } else {
          return Result.fail<AnimalDetailModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AnimalDetailModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_GET))
      );
    }
  }
}
