import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import BreedUpdateService from "../service/BreedUpdateService";
import { IBreedUpdateRepository } from "@/domain/breed/breedUpdate/repository/IBreedUpdateRepository";
import { BreedUpdateResponseDto } from "@/domain/breed/breedUpdate/dto/BreedUpdateResponseDto";
import { BreedUpdateRequestDto } from "@/domain/breed/breedUpdate/dto/BreedUpdateRequestDto";

const updateBreedService = new BreedUpdateService();

export class BreedUpdateRepository implements IBreedUpdateRepository {
  async updateBreed(
    request: BreedUpdateRequestDto, id?: number
  ): Promise<Result<BreedUpdateResponseDto>> {
    try {
      return await updateBreedService
        .update(request, id)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<BreedUpdateResponseDto>(response.getValue());
          } else {
            return Result.fail<BreedUpdateResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<BreedUpdateResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<BreedUpdateResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.BREED_UPDATE))
      );
    }
  }
}
