import { BaseDTO } from "@/core/base/BaseDTO";

export class AnimalVaccineAddRequestDto extends BaseDTO {
  data: {
    animal?:  number;
    vaccine?: number;
    vaccineScheduleItem?: number;
    amount?: number;
    description?: string;
    completedDate?: Date;
  };

  constructor(
    animal?:  number,
    vaccine?: number,
    vaccineScheduleItem?: number,
    amount?: number,
    description?: string,
    completedDate?: Date
  ) {
    amount = Number(amount)
    super();
    this.data = {
      animal,
      vaccine,
      vaccineScheduleItem,
      amount,
      description,
      completedDate,
    };
  }
}
