import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { IFeedBuyService } from "@/domain/feed/feedBuy/service/IFeedBuyService";
import { FeedBuyRequestDto } from "@/domain/feed/feedBuy/dto/FeedBuyRequestDto";
import { FeedBuyResponseDto } from "@/domain/feed/feedBuy/dto/FeedBuyResponseDto";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class FeedBuyService implements IFeedBuyService {
  async buy(request: FeedBuyRequestDto): Promise<Result<FeedBuyResponseDto>> {
    try {
      return await instance
        .post(API_ROUTE.PRODUCT_BUY, request)
        .then((response) => {
          return Result.ok<FeedBuyResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<FeedBuyResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_BUY_FOOD)))
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.FEED_BUY))
      );
    }
  }
}
