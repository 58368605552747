import { ResetPasswordRepository } from "../repository/ResetPasswordRepository";
import { ResetPasswordMapper } from "../mapper/ResetPasswordMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IResetPasswordUseCase } from "@/domain/authentication/resetPassword/usecase/IResetPasswordUsecase";
import { ResetPasswordModel } from "@/domain/authentication/resetPassword/model/ResetPasswordModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";

export class ResetPasswordUsecase implements IResetPasswordUseCase {
  resetPasswordRepository: ResetPasswordRepository;
  resetPasswordMapper = new ResetPasswordMapper();

  constructor(_resetPasswordRepository: ResetPasswordRepository) {
    this.resetPasswordRepository = _resetPasswordRepository;
  }

  async execute(model: ResetPasswordModel): Promise<Result<ResetPasswordModel>> {
    try {
      const dto = this.resetPasswordMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<ResetPasswordModel>(dto.getError());
      } else {
        const response = await this.resetPasswordRepository.resetPassword(dto);
        
        if (environment.env == "prod") {
          const analytics = getAnalytics();
          logEvent(analytics, 'reset_password');
          }
          
        if (response.isSuccess) {
          const domain = this.resetPasswordMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<ResetPasswordModel>(domain.getValue());
        } else {
          return Result.fail<ResetPasswordModel>(response.getError())
        }
      }
    } catch (error) {
      return Result.fail<ResetPasswordModel>(
        new UseCaseException(new UnknownCause(USECASE.RESET_PASSWORD))
      );
    }
  }
}
