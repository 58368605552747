import { DeleteAnimalRepository } from "../repository/DeleteAnimalRepository";
import { DeleteAnimalModel } from "@/domain/animal/animalDelete/model/DeleteAnimalModel";
import { IDeleteAnimalUseCase } from "@/domain/animal/animalDelete/usecase/IDeleteAnimalUseCase";
import { DeleteAnimalMapper } from "../mapper/DeleteAnimalMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";

export class DeleteAnimalUseCase implements IDeleteAnimalUseCase {
  deleteAnimalRepository: DeleteAnimalRepository;
  deleteAnimalMapper = new DeleteAnimalMapper();

  constructor(_deleteAnimalRepository: DeleteAnimalRepository) {
    this.deleteAnimalRepository = _deleteAnimalRepository;
  }

  async execute(model: DeleteAnimalModel): Promise<Result<DeleteAnimalModel>> {
    try {
      const dto = this.deleteAnimalMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<DeleteAnimalModel>(dto.getError());
      } else {
        const response = await this.deleteAnimalRepository.deleteAnimal(dto);

        if (response.isSuccess) {
          const domain = this.deleteAnimalMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
          const analytics = getAnalytics();
          logEvent(analytics, 'animal_delete', {
            deleted_animal_id: model.id
          });
        }

          return Result.ok<DeleteAnimalModel>(domain.getValue());
        } else {
          return Result.fail<DeleteAnimalModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<DeleteAnimalModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_DELETE))
      );
    }
  }
}
