import { AnimalFeedListRepository } from "../repository/AnimalFeedListRepository";
import { AnimalFeedListModel } from "@/domain/animal/animalFeed/animalFeedList/model/AnimalFeedListModel";
import { AnimalFeedListMapper } from "../mapper/AnimalFeedListMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalFeedListUseCase } from "@/domain/animal/animalFeed/animalFeedList/usecase/IAnimalFeedListUsecase";

export class AnimalFeedListUsecase implements IAnimalFeedListUseCase {
  animalRepository: AnimalFeedListRepository;
  animalFeedListMapper = new AnimalFeedListMapper();

  constructor(_animalRepository: AnimalFeedListRepository) {
    this.animalRepository = _animalRepository;
  }

  async execute(
    model: AnimalFeedListModel
  ): Promise<Result<AnimalFeedListModel>> {
    try {
      const dto = this.animalFeedListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalFeedListModel>(dto.getError());
      } else {
        const response = await this.animalRepository.animalFeedList(
          dto,
          Number(model.feed.animalId)
        );

        if (response.isSuccess) {
          const domain = this.animalFeedListMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<AnimalFeedListModel>(domain.getValue());
        } else {
          return Result.fail<AnimalFeedListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AnimalFeedListModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_FEED_LIST))
      );
    }
  }
}
