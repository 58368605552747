import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { FeedListRepository } from "@/application/feed/feedList/repository/FeedListRepository";
import { FeedListUseCase } from "@/application/feed/feedList/usecase/FeedListUseCase";
import { FeedListModel } from "@/domain/feed/feedList/model/FeedListModel";

export function createFeedController() {
  const feedListRepository = new FeedListRepository();
  const feedListUseCase = new FeedListUseCase(
    feedListRepository
  );
  return new FeedController(feedListUseCase);
}

export class FeedController implements IController {
  constructor(private feedListUseCase: FeedListUseCase) {}

  async feedList(
    feedListModel: FeedListModel
  ): Promise<Result<FeedListModel>> {
    const response = await this.feedListUseCase.execute(
      feedListModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<FeedListModel>(response.getError());
    }
  }
}
