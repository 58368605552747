import { createApp } from "vue";
import App from "./App.vue";

import router from "./presentation/router";
import store from "./presentation/store";
import ElementPlus from "element-plus";
import i18n from "./presentation/language/i18n";

//imports for app initialization
import { initApexCharts } from "./presentation/plugins/ApexCharts";
import { initInlineSvg } from "./presentation/plugins/Inline-svg";
import { initVeeValidate } from "./presentation/plugins/Vee-validate";

import "./presentation/plugins/KeenThemes";
import "./presentation/plugins/Prismjs";
import "bootstrap";
import { STORE } from "./domain/Constant";
import { AxiosClient } from "./infrastructure/network/axios/AxiosClient";

import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { getAnalytics } from "firebase/analytics";
import { environment } from "../environment";

if (environment.env == "prod") {
  const firebaseConfig = {
    apiKey: "AIzaSyC6zdCX6bjsTdXxVMky_4lLLtbMNDaBr6Y",
    authDomain: "maldavar.firebaseapp.com",
    projectId: "maldavar",
    storageBucket: "maldavar.appspot.com",
    messagingSenderId: "436884542154",
    appId: "1:436884542154:web:4ec633502e8ae1afe21a33",
    measurementId: "G-E91YP2L3SK",
  };

  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseApp);
  const perf = getPerformance(firebaseApp);
}

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(i18n);

initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

AxiosClient.setInstance(app).then(() => {
  app.mount("#app");
});

store.dispatch(STORE.CONTROLLERS);
