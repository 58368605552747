import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalTagListService from "../service/AnimalTagListService";
import { AnimalTagListResponseDto } from "@/domain/animalTag/animalTagList/dto/AnimalTagListResponseDto";
import { IAnimalTagListRepository } from "@/domain/animalTag/animalTagList/repository/IAnimalTagListRepository";

const animalTagListService = new AnimalTagListService();

export class AnimalTagListRepository implements IAnimalTagListRepository {
  async getAnimalTagList(): Promise<Result<AnimalTagListResponseDto>> {
    try {
      return await animalTagListService
        .getList()
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<AnimalTagListResponseDto>(response.getValue());
          } else {
            return Result.fail<AnimalTagListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<AnimalTagListResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<AnimalTagListResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_TAG_LIST))
      );
    }
  }
}
