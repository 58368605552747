import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import PriceAddService from "../service/PriceAddService";
import { IPriceAddRepository } from "@/domain/breed/priceAdd/repository/IPriceAddRepository";
import { PriceAddRequestDto } from "@/domain/breed/priceAdd/dto/PriceAddRequestDto";

const addPriceService = new PriceAddService();

export class PriceAddRepository implements IPriceAddRepository {
  async addPrice(
    request: PriceAddRequestDto
  ): Promise<Result<boolean>> {
    try {
      return await addPriceService
        .add(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<boolean>(response.getValue());
          } else {
            return Result.fail<boolean>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<boolean>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<boolean>(
        new RepositoryException(new UnknownCause(REPOSITORY.PRICE_ADD))
      );
    }
  }
}
