import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import BarnyardDetailService from "../service/BarnyardDetailService";
import { IBarnyardDetailRepository } from "@/domain/barnyard/barnyardDetail/repository/IBarnyardDetailRepository";
import { BarnyardDetailRequestDto } from "@/domain/barnyard/barnyardDetail/dto/BarnyardDetailRequestDto";
import { BarnyardDetailResponseDto } from "@/domain/barnyard/barnyardDetail/dto/BarnyardDetailResponseDto";

const getAnimalService = new BarnyardDetailService();

export class BarnyardDetailRepository implements IBarnyardDetailRepository {
  async getAnimal(request: BarnyardDetailRequestDto): Promise<Result<BarnyardDetailResponseDto>> {
    try {
      return await getAnimalService
        .get(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<BarnyardDetailResponseDto>(response.getValue());
          } else {
            return Result.fail<BarnyardDetailResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.BARNYARD_DETAIL)));
    }
  }
}
