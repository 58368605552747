import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalWeightUpdateRequestDto } from "@/domain/animal/animalWeight/animalWeightUpdate/dto/AnimalWeightUpdateRequestDto";
import { AnimalWeightUpdateModel } from "@/domain/animal/animalWeight/animalWeightUpdate/model/AnimalWeightUpdateModel";

export class AnimalWeightUpdateMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(
    dto: any
  ): Result<AnimalWeightUpdateModel> {
    try {
      const animalWeightUpdateModel: AnimalWeightUpdateModel = {
        animalId: 0,
        ok: dto.ok,
      };
      return Result.ok(animalWeightUpdateModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_WEIGHT_UPDATE))
      );
    }
  }

  mapToDTO(
    domain: AnimalWeightUpdateModel
  ): Result<AnimalWeightUpdateRequestDto> {
    try {
      const animalWeightUpdateRequest = new AnimalWeightUpdateRequestDto(
        domain.animalId,
        domain.liveWeight,
        domain.date,
        domain.description
      );

      return Result.ok(animalWeightUpdateRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_WEIGHT_UPDATE))
      );
    }
  }
}
