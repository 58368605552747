import { BadRequestCause } from "@/core/cause/BadRequestCause";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { Result } from "@/core/utility/ResultType";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { LoginRequestDto } from "@/domain/authentication/loginUser/dto/LoginRequestDto";
import { LoginResponseDto } from "@/domain/authentication/loginUser/dto/LoginResponseDto";
import { IAuthService } from "@/domain/authentication/loginUser/service/IAuthService";
import instance from "@/infrastructure/network/axios/AxiosService";
import i18n from "@/presentation/language/i18n";

export default class AuthService implements IAuthService {
  async login(request: LoginRequestDto): Promise<Result<LoginResponseDto>> {
    try {
      return await instance
        .post<LoginResponseDto>(API_ROUTE.AUTH_LOGIN, request)
        .then((response) => {
          return Result.ok<LoginResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<LoginResponseDto>(
            new BadRequestException(
              new BadRequestCause(i18n.global.t(SWAL_MESSAGES.LOGIN_ERROR_MESSAGE))
            )
          );
        });
    } catch (e) {
      return Result.fail<LoginResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.LOGIN))
      );
    }
  }
}
