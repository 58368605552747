import { LocalStorage } from "@/core/storage/LocalStorage";
import { LoginResponseDto } from "@/domain/authentication/loginUser/dto/LoginResponseDto";
import { JwtTokenManager } from "../storage/JwtTokenManager";
import { LOCAL_STORAGE } from "@/domain/Constant";
import { AES256EncryptionService } from "../encryption/AES256EncryptionService";
import { TenantEstateResponse } from "@/domain/tenantEstate/dto/TenantEstateResponse";

const localStorage = new LocalStorage();
const jwtTokenManager = new JwtTokenManager();

export class UserManager {
  saveToken(jwt: string) {
    jwtTokenManager.saveToken(jwt);
  }

  updateLoginDataInfo(response: LoginResponseDto) {
    const tenantUsers = response.data.attributes.tenantUsers;
    const encryptedTenantUsers = AES256EncryptionService.encrypt(
      JSON.stringify(tenantUsers),
    );

    localStorage.setItem(LOCAL_STORAGE.USER_TENANTS, encryptedTenantUsers);
    this.getPermissionGroups(response);
  }

  getPermissionGroups = (data): void => {
    const tenantRolePermissionGroups: any[] =
      data.data.attributes.activeTenantUser.data.attributes.tenantRole.data
        .attributes.tenantRolePermissionGroups.data;

    const permissions: string[] = [];
    tenantRolePermissionGroups.forEach((permission) => {
      permissions.push(permission.attributes.shortCode);
    });

    const encryptedPermissions = AES256EncryptionService.encrypt(
      JSON.stringify(permissions),
    );
    localStorage.setItem(LOCAL_STORAGE.PERMISSIONS, encryptedPermissions);
  };

  setActiveTenantName(response: TenantEstateResponse) {
    localStorage.setItem(LOCAL_STORAGE.ACTIVE_TENANT, response.data.attributes.name);
  }
}
