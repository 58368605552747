import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { VaccinationListRepository } from "@/application/vaccination/vaccinationList/repository/VaccinationListRepository";
import { VaccinationListUseCase } from "@/application/vaccination/vaccinationList/usecase/VaccinationListUseCase";
import { VaccinationListModel } from "@/domain/vaccination/vaccinationList/model/VaccinationListModel";

export function createVaccinationController() {
  const vaccinationListRepository = new VaccinationListRepository();
  const vaccinationListUseCase = new VaccinationListUseCase(
    vaccinationListRepository
  );
  return new VaccinationController(vaccinationListUseCase);
}

export class VaccinationController implements IController {
  constructor(private vaccinationListUseCase: VaccinationListUseCase) {}

  async vaccinationList(
    vaccinationListModel: VaccinationListModel
  ): Promise<Result<VaccinationListModel>> {
    const response = await this.vaccinationListUseCase.execute(
      vaccinationListModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<VaccinationListModel>(response.getError());
    }
  }
}
