import { Password } from "@/core/value/object/Password";
import { AggregateRoot } from "@/domain/entity/AggregateRoot";
import { UniqueEntityID } from "@/domain/validation/UniqueEntityID";
import { Result } from "@/core/utility/ResultType";
import { UserCreated } from "../events/Create";
import { LoginStarted } from "../events/LoginStarted";
import { LoginUseCase } from "@/application/authentication/userLogin/usecase/LoginUseCase";
import LoginRepository from "@/application/authentication/userLogin/repository/LoginRepository";
import { LoginModel } from "@/domain/authentication/loginUser/model/LoginModel";
import router from "@/presentation/router";
import { LoginCompleted } from "../events/LoginCompleted";
import { EmailAddress } from "@/core/value/object/EmailAddress";
import { ROUTE_PAGES } from "@/domain/Constant";
import { UserProps } from "./UserProps";

export class User extends AggregateRoot<UserProps> {
  private constructor(props: UserProps, id?: UniqueEntityID) {
    super(props, id);
  }

  get userId(): number | undefined {
    return this.props.id;
  }

  get jwt(): string | undefined {
    return this.props.jwt;
  }

  get username(): string | undefined {
    return this.props.username;
  }

  get email(): EmailAddress | string {
    return this.props.email;
  }

  get password(): Password | string {
    return this.props.password;
  }

  get activeTenantUser(): any | undefined {
    return this.props.activeTenantUser;
  }

  get tenantUsers(): any[] | undefined {
    return this.props.tenantUsers;
  }

  public setId(id: number) {
    this.props.id = id;
  }

  public setUsername(username: string) {
    this.props.username = username;
  }

  public setEmail(email: string | EmailAddress) {
    this.props.email = email;
  }

  public setPassword(password: string | Password) {
    this.props.password = password;
  }

  public setActiveTenantUser(activeTenantUser: any) {
    this.props.activeTenantUser = activeTenantUser;
  }

  public setTenantUsers(tenantUsers: any[]) {
    this.props.tenantUsers = tenantUsers;
  }

  public setJwt(jwt: string) {
    this.props.jwt = jwt;
  }

  public static create(props: UserProps): Result<User> {
    const userProps: UserProps = {
      id: props.id,
      email: props.email,
      password: props.password,
      activeTenantUser: props.activeTenantUser,
      jwt: props.jwt,
      username: props.username,
      tenantUsers: props.tenantUsers,
    };

    const user = new User(userProps);
    user.addDomainEvent(new UserCreated(user));

    return Result.ok<User>(user);
  }

  public async login(): Promise<Result<LoginModel>> {
    const loginUseCase: LoginUseCase = new LoginUseCase(new LoginRepository());
    this.addDomainEvent(new LoginStarted(this, loginUseCase)); // loginStarted

    const loginModel: LoginModel = new LoginModel(
      this.props.email.toString(),
      this.props.password.toString(),
    );

    const response = await loginUseCase.execute(loginModel);
    if (!response.getError()) {
      this.addDomainEvent(new LoginCompleted(this));
      this.setJwt(response.getValue().jwt);
      this.setId(response.getValue().id);
      this.setUsername(response.getValue().username);
      this.setTenantUsers(response.getValue().tenantUsers);
      this.setActiveTenantUser(response.getValue().activeTenantUser);
      router.push({ name: ROUTE_PAGES.DASHBOARD });
      return Result.ok<LoginModel>(response.getValue());
    }
    else{
      return Result.fail<LoginModel>(response.getError());
    }
  }
}
