import { BreedAddRepository } from "../repository/BreedAddRepository";
import { BreedAddMapper } from "../mapper/BreedAddMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IBreedAddUseCase } from "@/domain/breed/breedAdd/usecase/IBreedAddUsecase";
import { BreedAddModel } from "@/domain/breed/breedAdd/model/BreedAddModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";

export class BreedAddUsecase implements IBreedAddUseCase {
  breedAddRepository: BreedAddRepository;
  breedAddMapper = new BreedAddMapper();

  constructor(_breedAddRepository: BreedAddRepository) {
    this.breedAddRepository = _breedAddRepository;
  }

  async execute(model: BreedAddModel): Promise<Result<BreedAddModel>> {
    try {
      const dto = this.breedAddMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<BreedAddModel>(dto.getError());
      } else {
        const response = await this.breedAddRepository.addBreed(dto);

        if (response.isSuccess) {
          const domain = this.breedAddMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
          const analytics = getAnalytics();
          logEvent(analytics, 'add_breed', {
            breed_id: domain.getValue().id
          });
        }

          return Result.ok<BreedAddModel>(domain.getValue());
        } else {
          return Result.fail<BreedAddModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<BreedAddModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_TAG_ADD))
      );
    }
  }
}
