import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalTypeListService from "../service/AnimalTypeListService";
import { IAnimalTypeListRepository } from "@/domain/animal/animalTypeList/repository/IAnimalTypeListRepository";
import { AnimalTypeListRequestDto } from "@/domain/animal/animalTypeList/dto/AnimalTypeListRequestDto";
import { AnimalTypeListResponseDto } from "@/domain/animal/animalTypeList/dto/AnimalTypeListResponseDto";

const animalTypeListService = new AnimalTypeListService();

export class AnimalTypeListRepository implements IAnimalTypeListRepository {
  async animalTypeList(
    request: AnimalTypeListRequestDto
  ): Promise<Result<AnimalTypeListResponseDto>> {
    try {
      return await animalTypeListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<AnimalTypeListResponseDto>(response.getValue());
          } else {
            return Result.fail<AnimalTypeListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<AnimalTypeListResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<AnimalTypeListResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_TYPE_LIST))
      );
    }
  }
}
