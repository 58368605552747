export default {
  project: "Maldavar",
  year: "2024",
  version: "1.32",
  dashboard: "Tableau de bord",
  settings: "Paramètres",
  authentication: "Authentification",
  signIn: "Se connecter",
  signUp: "S'inscrire",
  passwordReset: "Réinitialisation du mot de passe",
  error404: "Erreur 404",
  error500: "Erreur 500",
  createAccount: "Créer un compte",
  signin: "Se connecter",
  adminPassword: "Bienvenue, veuillez entrer vos informations de connexion.",
  email: "E-mail",
  password: "Mot de passe",
  forgotPassword: "J'ai oublié mon mot de passe",
  login: "Connexion",
  pleaseWait: "Veuillez patienter...",
  animal: "Animal",
  barnyards: "Enclos",
  allAnimals: "Tous les animaux",
  createAnimal: "Ajouter un animal",
  weight: "Poids vif",
  animalTags: "Tags d'animal",
  vaccine: "Vaccin",
  vaccineList: "Liste des vaccins",
  food: "Nourriture",
  treatment: "Traitement",
  overheads: "Frais généraux",
  current: "Courant",
  sales: "Ventes",
  order: "Commande",
  shipment: "Expédition",
  bill: "Facture",
  waybill: "Bon de livraison",
  buy: "Acheter",
  buyAnimal: "Acheter un animal",
  buyFeed: "Acheter de la nourriture",
  supplies: "Fournitures",
  machine: "Machine/Équipement",
  basicKnowledge: "Connaissances de base",
  vehicles: "Véhicules",
  breeds: "Races",
  animalTypes: "Types d'animaux",
  vaccines: "Informations sur les vaccins",
  diseases: "Maladies",
  foods: "Types de nourriture",
  treatments: "Traitements",
  tags: "Étiquettes",
  staff: "Personnel",
  staffList: "Liste du personnel",
  authorization: "Autorisation",
  common: {
    layout: {
      about: "À propos de nous",
      contact: "Contact",
      contactUs: "Contactez-nous",
    },
    button: {
      info: "Info",
      edit: "Modifier",
      detail: "Détail",
      save: "Enregistrer",
      cancel: "Annuler",
      return: "Retour",
      add: "Ajouter",
      update: "Mettre à jour",
      send: "Envoyer",
      loader: "Veuillez patienter...",
      yes: "Oui",
      no: "Non",
    },
    menu: {
      myProfile: "Mon profil",
      changeTenant: "Changer de ferme",
      language: "Langue",
      accountSettings: "Paramètres du compte",
      signOut: "Déconnexion",
    },
    table: {
      process: "Processus",
      notProcess: "Impossible de traiter car il est par défaut",
      pagination: {
        desc: " sur ",
        desc1: "résultats.",
        desc2: "éléments par page",
      },
    },
  },
  swalMessages: {
    CONFIRM_BUTTON_TEXT: "D'accord",
    API_ERROR_MESSAGE: "Erreur technique.",
    ERROR_CODE: " Code d'erreur: ",
    UNKNOWN_ERROR_MESSAGE: "Une erreur inconnue s'est produite.",
    ARE_YOU_SURE: "Êtes-vous sûr?",
    SURE_DELETE_ANIMAL: "Êtes-vous sûr de vouloir supprimer cet animal?",
    SURE_TREAT_ANIMAL:
      "Êtes-vous sûr de vouloir mettre à jour cet animal comme guéri?",

    SUCCESS: {
      SUCCESS: "Opération réussie!",
      LOGIN_SUCCESS_MESSAGE: "Connexion réussie.",
      SUCCESS_FORGOT_PASSWORD:
        "Le e-mail de réinitialisation du mot de passe a été envoyé avec succès.",
      SUCCESS_RESET_PASSWORD: "Le mot de passe a été réinitialisé avec succès.",

      SUCCESS_CREATE_ANIMAL:
        "Félicitations, l'animal a été ajouté avec succès.",
      SUCCESS_UPDATE_ANIMAL:
        "Félicitations, l'animal a été mis à jour avec succès.",
      SUCCESS_DELETE_ANIMAL:
        "Félicitations, l'animal a été supprimé avec succès.",
      SUCCESS_TRANSFER_ANIMAL:
        "Félicitations, l'animal a été transféré avec succès.",

      SUCCESS_UPDATE_ANIMAL_WEIGHT:
        "Félicitations, le poids de l'animal a été mis à jour avec succès.",
      SUCCESS_ADD_ANIMAL_VACCINE:
        "Félicitations, le vaccin de l'animal a été administré avec succès.",
      SUCCESS_ADD_ANIMAL_TAG:
        "Félicitations, l'étiquette de l'animal a été créée avec succès.",
      SUCCESS_UPDATE_ANIMAL_TAG:
        "Félicitations, l'étiquette de l'animal a été mise à jour avec succès.",
      SUCCESS_REMOVE_ANIMAL_TAG:
        "Félicitations, l'étiquette de l'animal a été supprimée avec succès.",
      SUCCESS_BUY_FOOD:
        "Félicitations, l'achat de nourriture a été ajouté avec succès!",
      SUCCESS_ADD_BREED:
        "Félicitations, la race d'animal a été ajoutée avec succès!",
      SUCCESS_UPDATE_BREED:
        "Félicitations, la race d'animal a été mise à jour avec succès!",
      SUCCESS_REMOVE_BREED:
        "Félicitations, la race d'animal a été supprimée avec succès!",
      SUCCESS_UPDATE_PRICE:
        "Félicitations, le prix de la race d'animal a été mis à jour avec succès!",
      SUCCESS_ADD_ANIMAL_FEED:
        "Félicitations, le record d'alimentation de l'animal a été ajouté avec succès!",
      SUCCESS_ADD_ANIMAL_DISEASE:
        "Félicitations, le record de maladie de l'animal a été ajouté avec succès!",
      SUCCESS_UPDATE_ANIMAL_DISEASE:
        "Félicitations, la maladie de l'animal a été mise à jour avec succès!",
    },

    ERROR: {
      ERROR: "Opération échouée!",
      SESSION_TIME_OUT:
        "La session a expiré. Veuillez vous reconnecter avec vos informations d'utilisateur.",
      LOGIN_ERROR_MESSAGE:
        "Vos informations de connexion sont incorrectes, veuillez réessayer.",
      ERROR_FORGOT_PASSWORD:
        "Erreur technique. Le e-mail de réinitialisation du mot de passe n'a pas pu être envoyé.",
      ERROR_RESET_PASSWORD:
        "Erreur technique. Échec de la réinitialisation du mot de passe. Veuillez réessayer.",
      ERROR_CHANGE_TENANT_USER:
        "Erreur technique! Échec du changement de ferme.",
      ERROR_ABOUT_USER:
        "Erreur technique! Échec du chargement des informations personnelles.",

      ERROR_CREATE_ANIMAL: "Erreur technique! Échec de l'ajout de l'animal.",
      ERROR_UPDATE_ANIMAL:
        "Erreur technique! Échec de la mise à jour de l'animal.",
      ERROR_DELETE_ANIMAL:
        "Erreur technique! Échec de la suppression de l'animal.",
      ERROR_TRANSFER_ANIMAL:
        "Erreur technique! Échec du transfert de l'animal.",

      ERROR_UPDATE_ANIMAL_WEIGHT:
        "Erreur technique! Échec de la mise à jour du poids de l'animal.",
      ERROR_ADD_ANIMAL_VACCINE:
        "Erreur technique! Échec de la vaccination de l'animal.",
      ERROR_ADD_ANIMAL_TAG:
        "Erreur technique! Échec de la création de l'étiquette de l'animal.",
      ERROR_UPDATE_ANIMAL_TAG:
        "Erreur technique! Échec de la mise à jour de l'étiquette de l'animal.",
      ERROR_REMOVE_ANIMAL_TAG:
        "Erreur technique! Échec de la suppression de l'étiquette de l'animal.",
      ERROR_BUY_FOOD:
        "Erreur technique! Échec de l'ajout de l'achat de nourriture.",
      ERROR_ADD_BREED:
        "Erreur technique! Échec de l'ajout de la race d'animal.",
      ERROR_UPDATE_BREED:
        "Erreur technique! Échec de la mise à jour de la race d'animal.",
      ERROR_REMOVE_BREED:
        "Erreur technique! Échec de la suppression de la race d'animal.",
      ERROR_UPDATE_PRICE:
        "Erreur technique! Échec de la mise à jour du prix de la race d'animal.",
      ERROR_ADD_ANIMAL_FEED:
        "Erreur technique! Échec de l'ajout de l'alimentation de l'animal.",
      ERROR_ADD_ANIMAL_DISEASE:
        "Erreur technique! Échec de l'ajout de la maladie de l'animal.",
      ERROR_UPDATE_ANIMAL_DISEASE:
        "Erreur technique! Échec de la mise à jour de la maladie de l'animal.",

      ERROR_ANIMAL_LIST: "Erreur technique! Échec de la liste des animaux.",
      ERROR_ANIMAL_COUNT:
        "Erreur technique! Impossible de récupérer le nombre d'animaux.",
      ERROR_MOST_VALUABLE_ANIMAL:
        "Erreur technique! Impossible de récupérer l'animal le plus précieux.",
      ERROR_ANIMAL_WEIGHT_AVERAGE:
        "Erreur technique! Impossible de récupérer la moyenne du poids des animaux.",
      ERROR_TOTAL_COST:
        "Erreur technique! Impossible de récupérer le coût total.",
      ERROR_TOTAL_VALUE_ANIMALS:
        "Erreur technique! Impossible de récupérer la valeur totale des animaux.",
      ERROR_ANIMAL_TAG_LIST:
        "Erreur technique! Échec de la liste des étiquettes d'animal.",
      ERROR_ANIMAL_TYPE_LIST:
        "Erreur technique! Échec de la liste des types d'animal.",
      ERROR_ANIMAL_WEIGHT_LIST:
        "Erreur technique! Échec de la liste du poids des animaux.",
      ERROR_GET_ANIMAL_DETAIL:
        "Erreur technique! Impossible de récupérer les détails de l'animal.",
      ERROR_BARNYARD_LIST: "Erreur technique! Échec de la liste des enclos.",
      ERROR_BARNYARD_DETAIL:
        "Erreur technique! Impossible de récupérer les détails de l'enclos.",
      ERROR_BREED_LIST:
        "Erreur technique! Échec de la liste des races d'animal.",
      ERROR_FOOD_LIST: "Erreur technique! Échec de la liste des aliments.",
      ERROR_VACCINE_LIST: "Erreur technique! Échec de la liste des vaccins.",
      ERROR_ANIMAL_VACCINE_LIST:
        "Erreur technique! Échec de la liste des vaccinations d'animal.",
      ERROR_DISEASE_LIST: "Erreur technique! Échec de la liste des maladies.",
      ERROR_ANIMAL_DISEASE_LIST:
        "Erreur technique! Échec de la liste des maladies d'animal.",
      ERROR_ANIMAL_FEED_LIST:
        "Erreur technique! Échec de la liste de l'alimentation des animaux.",
    },
  },
  customErrorMessages: {
    useCase: {
      error: "Erreur de cas d'utilisation.",
    },
    mapper: {
      error: "Erreur de mappage.",
    },
    controller: {
      error: "Erreur du contrôleur.",
    },
    repository: {
      error: "Erreur du dépôt.",
    },
    aggregateRoot: {
      error: "Erreur de l'agrégat racine.",
    },
    domainEvent: {
      error: "Erreur d'événement de domaine.",
    },
    email: {
      required: "Veuillez entrer votre adresse e-mail.",
      invalid: "Veuillez entrer une adresse e-mail valide.",
    },
    password: {
      required: "Veuillez entrer votre mot de passe.",
      min: "Le mot de passe doit comporter au moins 6 caractères.",
      match: "Vos mots de passe doivent être identiques.",
    },
    identity: {
      required: "Veuillez entrer votre numéro d'identification.",
      length: "Le numéro d'identification doit comporter 11 chiffres.",
    },
    credential: {
      required: "Veuillez entrer votre adresse e-mail et votre mot de passe.",
      credential: "Adresse e-mail ou mot de passe non valide.",
    },
    required: "Veuillez ne pas laisser ce champ vide.",
  },
  validators_error: {
    required: "Veuillez ne pas laisser ce champ vide.",
    typeError: "Veuillez entrer une donnée valide.",
    email: "Veuillez entrer une adresse e-mail valide.",
    minlength: "Ne peut pas avoir moins de caractères.",
    maxlength: "Ne peut pas avoir plus de caractères.",
    zeroValue: "La valeur ne peut pas être inférieure à 0.",
  },
  menuPages: {
    name: "Pages",
    dashboard: {
      pageName: "Tableau de bord",
      statistics: {
        header: "Menu Rapide",
        barnyardList: "Liste des Enclos",
        animalList: "Liste des Animaux",
        addAnimal: "Ajouter un Nouvel Animal",
        accounting: "Comptabilité",
        weight: "Poids",
        animalTag: "Étiquettes d'Animaux",
        breeds: "Races d'Animaux",
      },
      tenantInfo: {
        header: "Informations sur la Ferme",
        desc: "Informations détaillées sur la ferme...",
        tenant: "Ferme",
        barnyardCount: "Nombre d'Enclos",
        animalCount: "Nombre d'Animaux",
        occupancy: "Occupation",
        totalAnimalValue: "Valeur Totale des Animaux",
        totalAmount: "Montant Total",
        unit: "Unité",
        kilo: "Kilo",
      },
      animalWeight: {
        header: "Augmentation de Poids des Animaux",
        desc: "Statistiques sur l'augmentation de poids des animaux...",
      },
      mostValuableAnimal: {
        header: "Animal le Plus Précieux",
        desc: "Informations détaillées sur l'animal le plus précieux de la ferme...",
        animal: {
          name: "Nom de l'Animal",
          eartag: "Numéro d'Identification",
          gender: "Sexe",
          weight: "Poids",
          color: "Couleur",
          value: "Valeur",
        },
      },
      accounting: {
        header: "Comptabilité",
        desc: "Informations de comptabilité...",
      },
    },
    error404: {
      nothing: "Il semble qu'il n'y ait rien ici",
      return: "Retour à la page d'accueil",
    },
    error500: {
      error: "Erreur Système",
      description:
        "Quelque chose s'est mal passé ! Veuillez réessayer plus tard !",
      return: "Retour à la page d'accueil",
    },
    animalList: {
      pageName: "Animaux",
      animalList: "Liste des Animaux",
      animal: "Animal",
      earTag: "Numéro d'Identification",
      gender: "Sexe",
      color: "Couleur",
      barnyard: "Enclos",
      lastWeight: "Dernier Poids",
      status: "Statut",
      pregnant: "Enceinte",
      search: "Rechercher un Animal",
      tag: "Étiquette",
      zeroCount: "Aucun animal trouvé.",
      seeAll: "Voir Tout",
    },
    createAnimal: {
      pageName: "Ajouter un Animal",
      photo: "Photo",
      photoDesc: "Types de fichiers autorisés: png, jpg, jpeg..",
      race: "Race",
      selectRace: "Sélectionnez la race...",
      gender: "Genre",
      selectGender: "Sélectionnez le genre...",
      earringNumber: "Numéro de Boucle",
      nickname: "Surnom",
      lastWeight: "Dernier Poids",
      motherEarNumber: "Numéro de Boucle de la Mère",
      fatherEarNumber: "Numéro de Boucle du Père",
      price: "Prix d'Achat",
      companyInfo: "Informations sur l'Entreprise",
      companyFirstDate: "Date d'Entrée dans l'Entreprise",
      companyEnterDesc: "Raison de l'Entrée dans l'Entreprise",
      healthInfo: "Informations de Santé",
      barnyard: "Enclos",
      selectBarnyard: "Sélectionnez l'enclos...",
      status: "Statut",
      selectStatus: "Sélectionnez le statut...",
      tags: "Étiquettes",
      tagsValid: "Maximum 3 étiquettes peuvent être sélectionnées.",
      selectTags: "Sélectionnez...",
      color: "Couleur",
      birthDate: "Date de Naissance",
    },
    weight: {
      pageName: "Poids",
      animals: "Animaux",
      myWeight: "Mon Poids",
      animal: "Animal",
      barnyard: "Enclos",
      lastWeight: "Dernier Poids",
      liveWeight: "Poids Vivant",
      status: "Statut",
      description: "Description",
      addWeight: "Ajouter un Poids",
    },
    addWeight: {
      pageName: "Ajouter un Poids",
      pageDescription:
        "Veuillez saisir les informations nécessaires pour ajouter un poids à l'animal.",
      liveWeight: "Poids Vivant",
      enterWeight: "Entrez le poids...",
      date: "Date",
      enterDate: "Sélectionnez une date",
      description: "Description",
      enterDescription: "Entrez une description...",
    },
    myWeight: {
      pageName: "Mon Poids",
      animals: "Animaux",
      myWeight: "Mon Poids",
      animal: "Animal",
      barnyard: "Enclos",
      date: "Date",
      lastWeight: "Dernier Poids",
      liveWeight: "Poids Vivant",
      weightResult: "Résultat du Poids",
      zeroCount: "Aucune information de poids trouvée.",
    },
    animalWeight: {
      pageName: "Poids des Animaux",
      graphic: "Graphique",
      list: "Liste",
    },
    vaccineSelect: {
      pageName: "Vaccin",
      animalSelection: "Sélection de l'Animal",
      vaccination: "Vaccination",
      animal: "Animal",
      barnyard: "Enclos",
    },
    vaccination: {
      pageName: "Vaccination",
      vaccinationList: "Liste des Vaccinations",
      vaccinationInfos: "Informations sur la Vaccination",
      animalSelection: "Sélection de l'Animal",
      vaccination: "Vaccination",
      animal: "Animal",
      id: "N°",
      barnyard: "Enclos",
      vaccine: "Vaccin",
      mililiter: "ml",
      price: "Prix",
      date: "Date",
      description: "Description",
      active: "Actif",
      status: "Statut",
      createVaccination: "Créer une Vaccination",
      addVaccination: "Faire une Vaccination",
      addAllVaccination: "Vacciner Tous",
      zeroCount: "Aucune information sur la vaccination trouvée.",
      past: " jours écoulés",
      future: " jours restants",
    },
    vaccineList: {
      pageName: "Liste des Vaccins",
      id: "N°",
      barnyard: "Enclos",
      vaccine: "Vaccin",
      animalName: "Nom de l'Animal",
      earTag: "Numéro de Boucle",
      date: "Date",
      status: "Statut",
      search: "Recherche de Vaccin",
      zeroCount: "Aucun vaccin trouvé.",
    },
    vaccinate: {
      pageName: "Vaccination",
      pageDescription:
        "Saisissez les informations complètes sur la vaccination.",
      vaccine: "Vaccin",
      selectVaccine: "Sélectionnez le vaccin...",
      amount: "Quantité (ml)",
      totalAmount: "Quantité Totale (ml)",
      enterAmount: "Entrez la quantité...",
      date: "Date",
      enterDate: "Sélectionnez une date",
      description: "Description",
      enterDescription: "Entrez une description...",
    },
    addPrice: {
      pageName: "Mettre à Jour le Prix",
      pageDescription: "Veuillez entrer les informations sur le prix.",
      breed: "Race de l'Animal",
      selectBreed: "Sélectionnez la race...",
      price: "Prix",
      enterPrice: "Entrez le prix...",
      currency: "Devise",
      enterCurrency: "Entrez la devise...",
      description: "Description",
      enterDescription: "Entrez une description...",
    },
    food: {
      pageName: "Nourriture",
      feedAnimal: "Nourrir l'Animal",
      selectFood: "Sélectionnez la nourriture...",
      foodList: "Liste de Nourriture",
      pageDescription:
        "Saisissez le type de nourriture que vous souhaitez donner et les autres informations nécessaires.",
      barnyard: "Enclos",
      selectBarnyard: "Sélectionnez l'enclos...",
      numberOfAnimals: "Nombre d'Animaux Nourris",
      foodName: "Nom de la Nourriture",
      date: "Date",
      enterDate: "Sélectionnez une date...",
      amount: "Quantité (kg)",
      totalAmount: "Quantité Totale (kg)",
      unitType: "Unité",
      enterAmount: "Entrez la quantité...",
      description: "Description",
      enterDescription: "Entrez une description...",
      button: {
        prepareFood: "Préparer le Mélange Alimentaire",
        addFood: "Ajouter de la Nourriture",
        feedAnimal: "Nourrir l'Animal",
        feedAllAnimal: "Nourrir Tous les Animaux",
      },
      zeroCount: "Aucun enregistrement de nourriture trouvé.",
    },
    animalFeeds: {
      pageName: "Liste de Nourriture",
      foodName: "Nom de la Nourriture",
      productDescription: "Description du Produit",
      amount: "Quantité (kg)",
      unitTypeName: "Unité",
      description: "Description",
      completedDate: "Date",
      zeroCount: "Aucun enregistrement de nourriture trouvé.",
    },
    barnyards: {
      pageName: "Enclos",
      barnyard: "Enclos",
      withoutBarnyard: "Sans Enclos",
      outBarnyard: "Hors Enclos",
      show: "Afficher",
      zeroBarnyard: "Aucun enclos trouvé.",
    },
    barnyardPanel: {
      pageName: "Panneau de l'Enclos",
      tabMenu: {
        padokInfo: "Informations sur l'Enclos",
        animals: "Animaux",
        vaccination: "Vaccination",
        feed: "Alimentation",
        weight: "Poids",
        treatment: "Traitement",
        overheads: "Frais généraux",
      },
      barnyardName: "Nom de l'Enclos",
      capacity: "Capacité",
      count: "Nombre d'Animaux",
      barnyardLocation: "Emplacement de l'Enclos",
      companyName: "Nom de l'Entreprise",
    },
    animalInfo: {
      pageName: "Informations sur l'Animal",
      info: "Profil",
      edit: "Modifier",
      tabMenu: {
        weight: "Poids",
        vaccination: "Vaccination",
        feed: "Alimentation",
        treatment: "Traitement",
        overheads: "Frais généraux",
        diseases: "Maladies",
      },
      photo: "Photo",
      photoDesc: "Types de fichiers autorisés : png, jpg, jpeg...",
      race: "Race",
      selectRace: "Sélectionnez la Race...",
      gender: "Genre",
      selectGender: "Sélectionnez le Genre...",
      earringNumber: "Numéro de Boucle",
      nickname: "Surnom",
      lastWeight: "Dernier Poids",
      motherEarNumber: "Numéro de Boucle de la Mère",
      fatherEarNumber: "Numéro de Boucle du Père",
      price: "Prix",
      companyInfo: "Informations sur l'Entreprise",
      companyFirstDate: "Date d'Entrée dans l'Entreprise",
      companyEnterDesc: "Raison de l'Entrée dans l'Entreprise",
      healthInfo: "Informations de Santé",
      vaccinations: "Vaccinations",
      diseases: "Maladies",
      barnyard: "Enclos",
      selectBarnyard: "Sélectionnez l'Enclos...",
      status: "Statut",
      selectStatus: "Sélectionnez le Statut...",
      color: "Couleur",
      description: "Description",
    },
    breed: {
      pageName: "Races d'Animaux",
      name: "Nom de la Race",
      description: "Description",
      animalType: "Type d'Animal",
      isDefault: "Par Défaut",
      price: "Prix",
      button: {
        addBreed: "Ajouter une Race",
      },
      zeroCount: "Aucune race d'animal trouvée.",
    },
    disease: {
      pageName: "Maladies",
      diseaseList: "Liste des Maladies",
      name: "Nom de la Maladie",
      description: "Description",
      createDate: "Date de Création",
      updateDate: "Date de Modification",
      button: {
        addDisease: "Ajouter une Maladie",
      },
      zeroCount: "Aucun enregistrement de maladie trouvé.",
    },
    addDisease: {
      pageName: "Ajouter une Maladie",
      pageDescription:
        "Veuillez entrer les informations nécessaires pour ajouter un enregistrement de maladie à l'animal.",
      disease: "Maladie",
      selectDisease: "Sélectionnez la Maladie...",
      startDate: "Date de Début",
      enterStartDate: "Sélectionnez une Date...",
      endDate: "Date de Fin",
      enterEndDate: "Sélectionnez une Date...",
      end: "Guéri",
    },
    buyFeed: {
      pageName: "Acheter de la Nourriture",
      feed: "Nom de l'Aliment",
      description: "Description",
      grammage: "Grammage",
      unitPrice: "Prix Unitaire",
      quantity: "Quantité (kg)",
      purchaseDate: "Date d'Achat",
      button: {
        buy: "Acheter",
      },
      animalTags: {
        pageName: "Étiquettes d'Animaux",
        id: "Id",
        shortCode: "Code court",
        name: "Nom",
        description: "Description",
        isDefault: "Par Défaut",
        button: {
          remove: "Supprimer",
          add: "Créer une Étiquette d'Animal",
        },
        zeroCount: "Aucune étiquette d'animal trouvée.",
      },
      animalTypes: {
        pageName: "Types d'Animaux",
        id: "Id",
        shortCode: "Code court",
        name: "Nom",
        description: "Description",
        button: {
          remove: "Supprimer",
          add: "Créer un Type d'Animal",
        },
        zeroCount: "Aucun type d'animal trouvé.",
      },
      addAnimalTag: {
        pageName: "Ajouter une Étiquette d'Animal",
        pageDescription:
          "Veuillez entrer les informations suivantes pour ajouter une étiquette d'animal.",
        animalTag: "Nom de l'Étiquette",
        enterAnimalTag: "Entrez le nom de l'étiquette de l'animal...",
        description: "Description",
        enterDescription: "Entrez une description...",
      },
      addBreed: {
        pageName: "Ajouter une Race d'Animal",
        updateBreed: "Mettre à Jour la Race d'Animal",
        pageDescription:
          "Veuillez entrer les informations suivantes pour ajouter une race d'animal.",
        breedName: "Nom de la Race",
        enterBreedName: "Entrez le nom de la race d'animal...",
        animalType: "Type d'Animal",
        selectAnimalType: "Sélectionnez le type d'animal...",
        description: "Description",
        enterDescription: "Entrez une description...",
      },
      animalTransfer: {
        pageName: "Transfert d'Animaux dans l'Enclos",
        pageDescription:
          "Veuillez entrer les informations nécessaires pour transférer l'animal dans l'enclos.",
        transfer: "Transfert",
        animal: "Animal",
        selectAnimal: "Sélectionnez l'animal...",
        barnyard: "Enclos",
        selectBarnyard: "Sélectionnez l'enclos...",
        date: "Date de Transfert",
        selectDate: "Sélectionnez la date...",
        description: "Description",
        enterDescription: "Entrez une description...",
      },
      forgotPassword: {
        pageName: "Mot de Passe Oublié",
        pageDescription: "Veuillez entrer votre adresse e-mail.",
        email: "E-mail",
      },
      resetPassword: {
        pageName: "Réinitialiser le Mot de Passe",
        pageDescription: "Veuillez entrer votre nouveau mot de passe.",
        newPassword: "Nouveau Mot de Passe",
        newPasswordAgain: "Confirmez le Nouveau Mot de Passe",
      },
    },
  },
};
