import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalVaccineListService from "../service/AnimalVaccineListService";
import { IAnimalVaccineListRepository } from "@/domain/animal/animalVaccine/animalVaccineList/repository/IAnimalVaccineListRepository";
import { AnimalVaccineItemResponseDto } from "@/domain/animal/animalVaccine/animalVaccineList/dto/AnimalVaccineItemResponseDto";
import { AnimalVaccineListRequestDto } from "@/domain/animal/animalVaccine/animalVaccineList/dto/AnimalVaccineListRequestDto";

const animalVaccineListService = new AnimalVaccineListService();

export class AnimalVaccineListRepository implements IAnimalVaccineListRepository {
  async animalVaccineList(
    request: AnimalVaccineListRequestDto
  ): Promise<Result<AnimalVaccineItemResponseDto>> {
    try {
      return await animalVaccineListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<AnimalVaccineItemResponseDto>(response.getValue());
          } else {
            return Result.fail<AnimalVaccineItemResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<AnimalVaccineItemResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<AnimalVaccineItemResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_VACCINE_LIST))
      );
    }
  }
}
