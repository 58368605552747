import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { DiseaseListRepository } from "@/application/disease/diseaseList/repository/DiseaseListRepository";
import { DiseaseListUseCase } from "@/application/disease/diseaseList/usecase/DiseaseListUseCase";
import { DiseaseListModel } from "@/domain/disease/diseaseList/model/DiseaseListModel";

export function createDiseaseController() {
  const diseaseListRepository = new DiseaseListRepository();
  const diseaseListUseCase = new DiseaseListUseCase(diseaseListRepository);
  return new DiseaseController(diseaseListUseCase);
}

export class DiseaseController implements IController {
  constructor(private diseaseListUseCase: DiseaseListUseCase) {}

  async diseaseList(
    diseaseListModel: DiseaseListModel
  ): Promise<Result<DiseaseListModel>> {
    const response = await this.diseaseListUseCase.execute(diseaseListModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<DiseaseListModel>(response.getError());
    }
  }
}
