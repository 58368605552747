import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "./modules/AuthModule";
import BodyModule from "./modules/BodyModule";
import BreadcrumbsModule from "./modules/BreadcrumbsModule";
import LayoutConfigModule from "./modules/LayoutConfigModule";
import ControllersModule from "./modules/ControllersModule";
import { AnimalListModel } from "@/domain/animal/animalList/model/AnimalListModel";

config.rawError = true;

const store = createStore({
  modules: {
    ControllersModule,
    BodyModule,
    BreadcrumbsModule,
    LayoutConfigModule,
    AuthModule,
  },
  state: {
    checkedAnimals: null,
    selectedAnimalId: null,
    selectedBreedId: null,
    selectedBarnyardId: null,
    selectedScheduleVaccineId: null,
    newAnimal: 0,
    newTransfer: 0,
    newVaccine: 0,
    newWeight: 0,
    newBreed: 0,
    newAnimalTag: 0,
    newAnimalType: 0,
    newDisease: 0,
    newFood: 0,
  },
  mutations: {
    checkedAnimals(state, animal: AnimalListModel) {
      state.checkedAnimals = animal;
    },
    selectedAnimalId(state, animalId: number) {
      state.selectedAnimalId = animalId;
    },
    selectedBreedId(state, breedId: number) {
      state.selectedBreedId = breedId;
    },
    selectedBarnyardId(state, barnyardId: number) {
      state.selectedBarnyardId = barnyardId;
    },
    checkedAnimalsId(state, animalId: number[]) {
      state.checkedAnimalsId = animalId;
    },
    selectedScheduleVaccineId(state, scheduleVaccineId: number) {
      state.selectedScheduleVaccineId = scheduleVaccineId;
    },
    newAnimal(state, newAnimal: number) {
      state.newAnimal = newAnimal;
    },
    newTransfer(state, newTransfer: number) {
      state.newTransfer = newTransfer;
    },
    newVaccine(state, newVaccine: number) {      
      state.newVaccine = newVaccine;
    },
    newWeight(state, newWeight: number) {      
      state.newWeight = newWeight;
    },
    newBreed(state, newBreed: number) {      
      state.newBreed = newBreed;
    },
    newAnimalTag(state, newAnimalTag: number) {      
      state.newAnimalTag = newAnimalTag;
    },
    newAnimalType(state, newAnimalType: number) {      
      state.newAnimalType = newAnimalType;
    },
    newDisease(state, newDisease: number) {      
      state.newDisease = newDisease;
    },
    newFood(state, newFood: number) {      
      state.newFood = newFood;
    },
  },
  actions: {
    checkedAnimals({ commit }, animal: AnimalListModel) {
      commit("checkedAnimals", animal);
    },
    selectedAnimalId({ commit }, animalId: number) {
      commit("selectedAnimalId", animalId);
    },
    selectedBreedId({ commit }, breedId: number) {
      commit("selectedBreedId", breedId);
    },
    selectedBarnyardId({ commit }, barnyardId: number) {
      commit("selectedBarnyardId", barnyardId);
    },
    checkedAnimalsId({ commit }, animalId: number[]) {
      commit("checkedAnimalsId", animalId);
    },
    selectedScheduleVaccineId({ commit }, scheduleVaccineId: number) {
      commit("selectedScheduleVaccineId", scheduleVaccineId);
    },
    newAnimal({ commit }, newAnimal: number) {
      commit("newAnimal", newAnimal);
    },
    newTransfer({ commit }, newTransfer: number) {
      commit("newTransfer", newTransfer);
    },
    newVaccine({ commit }, newVaccine: number) {
      commit("newVaccine", newVaccine);
    },
    newWeight({ commit }, newWeight: number) {
      commit("newWeight", newWeight);
    },
    newBreed({ commit }, newBreed: number) {
      commit("newBreed", newBreed);
    },
    newAnimalTag({ commit }, newAnimalTag: number) {
      commit("newAnimalTag", newAnimalTag);
    },
    newAnimalType({ commit }, newAnimalType: number) {
      commit("newAnimalType", newAnimalType);
    },
    newDisease({ commit }, newDisease: number) {
      commit("newDisease", newDisease);
    },
    newFood({ commit }, newFood: number) {
      commit("newFood", newFood);
    },
  },
  getters: {
    checkedAnimals: (state) => state.checkedAnimals,
    selectedAnimalId: (state) => state.selectedAnimalId,
    selectedBreedId: (state) => state.selectedBreedId,
    selectedBarnyardId: (state) => state.selectedBarnyardId,
    checkedAnimalsId: (state) => state.checkedAnimalsId,
    selectedScheduleVaccineId: (state) => state.selectedScheduleVaccineId,
    newAnimal: (state) => state.newAnimal,
    newTransfer: (state) => state.newTransfer,
    newVaccine: (state) => state.newVaccine,
    newWeight: (state) => state.newWeight,
    newBreed: (state) => state.newBreed,
    newAnimalTag: (state) => state.newAnimalTag,
    newAnimalType: (state) => state.newAnimalType,
    newDisease: (state) => state.newDisease,
    newFood: (state) => state.newFood,
  },
});

export default store;
