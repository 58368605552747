import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalTagRemoveRequestDto } from "@/domain/animalTag/animalTagRemove/dto/AnimalTagRemoveRequestDto";
import { AnimalTagRemoveResponseDto } from "@/domain/animalTag/animalTagRemove/dto/AnimalTagRemoveResponseDto";
import { AnimalTagRemoveModel } from "@/domain/animalTag/animalTagRemove/model/AnimalTagRemoveModel";

export class AnimalTagRemoveMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AnimalTagRemoveResponseDto): Result<AnimalTagRemoveModel> {
    try {
      const animalTagRemoveModel: AnimalTagRemoveModel = {
        id: dto.data.id,
        name: dto.data.attributes.name,
        shortCode: dto.data.attributes.shortCode,
      };

      return Result.ok(animalTagRemoveModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_TAG_REMOVE))
      );
    }
  }

  mapToDTO(domain: AnimalTagRemoveModel): Result<AnimalTagRemoveRequestDto> {
    try {
      const animalRequest = new AnimalTagRemoveRequestDto(domain.id);

      return Result.ok(animalRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_TAG_REMOVE))
      );
    }
  }
}
