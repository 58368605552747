import { BaseDomain } from "@/core/base/BaseDomain";
import { BaseDTO } from "@/core/base/BaseDTO";
import { Result } from "@/core/utility/ResultType";
import { IMapper } from "@/core/base/IMapper";
import { TenantUserMapper } from "@/application/tenantUser/mapper/TenantUserMapper";
import { TenantUserModel } from "@/domain/tenantUser/model/TenantUserModel";
import { EmailAddress } from "@/core/value/object/EmailAddress";
import { AboutUserModel } from "@/domain/authentication/aboutUser/model/AboutUserModel";
import { AboutUserResponseDto } from "@/domain/authentication/aboutUser/dto/AboutUserResponseDto";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/domain/Constant";

export class AboutUserMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AboutUserResponseDto): Result<AboutUserModel> {
    const tenantUserMapper: TenantUserMapper = new TenantUserMapper();
    const activeTenantUser: TenantUserModel = tenantUserMapper.mapToDomain(
      dto.data.attributes.activeTenantUser
    ) as TenantUserModel;
    const tenantUsers: TenantUserModel[] = tenantUserMapper.mapToDomain(
      dto.data.attributes.tenantUsers
    ) as TenantUserModel[];

    try {

    const aboutUserModel: AboutUserModel = {
      id: dto.data.id !== undefined ? dto.data.id : 0,
      email: new EmailAddress({ value: dto.data.attributes.email }),
      username: dto.data.attributes.username,
      activeTenantUser: activeTenantUser,
      tenantUsers: tenantUsers,
      name: dto.data.attributes.name,
      surname: dto.data.attributes.surname,
      blocked: dto.data.attributes.blocked,
      confirmed: dto.data.attributes.confirmed,
      phoneNumber: dto.data.attributes.phoneNumber,
      provider: dto.data.attributes.provider
    };

    if(dto.data.attributes.profilePhoto.data != null)
    {
      aboutUserModel.profilePhotoUrl = dto.data.attributes.profilePhoto.data.attributes.url;
    }

    return Result.ok(aboutUserModel);
  } catch {
    return Result.fail(
      new MapperException(new UnknownCause(MAPPER.ABOUT_USER))
    );
  }
  }

  mapToDTO(domain: AboutUserModel): Result<AboutUserModel> {
    try {
    return Result.ok(domain);
  } catch {
    return Result.fail(
      new MapperException(new UnknownCause(MAPPER.ABOUT_USER))
    );
  }
  }
}
