import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalTransferService from "../service/AnimalTransferService";
import { IAnimalTransferRepository } from "@/domain/animal/animalTransfer/repository/IAnimalTransferRepository";
import { AnimalTransferRequestDto } from "@/domain/animal/animalTransfer/dto/AnimalTransferRequestDto";

const animalTransferService = new AnimalTransferService();

export class AnimalTransferRepository
  implements IAnimalTransferRepository
{
  async animalTransfer(
    request: AnimalTransferRequestDto
  ): Promise<Result<void>> {
    try {
      return await animalTransferService
        .transfer(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<void>(response.getValue());
          } else {
            return Result.fail<void>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<void>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<void>(
        new RepositoryException(
          new UnknownCause(REPOSITORY.ANIMAL_TRANSFER)
        )
      );
    }
  }
}
