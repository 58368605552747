import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalVaccineMultipleAddRequestDto } from "@/domain/animal/animalVaccine/animalVaccineMultipleAdd/dto/AnimalVaccineMultipleAddRequestDto";
import { AnimalVaccineMultipleAddResponseDto } from "@/domain/animal/animalVaccine/animalVaccineMultipleAdd/dto/AnimalVaccineMultipleAddResponseDto";
import { AnimalVaccineMultipleAddModel } from "@/domain/animal/animalVaccine/animalVaccineMultipleAdd/model/AnimalVaccineMultipleAddModel";

export class AnimalVaccineMultipleAddMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AnimalVaccineMultipleAddResponseDto): Result<AnimalVaccineMultipleAddModel[]> {
    try {
      const addAnimalVaccineMultipleModel: AnimalVaccineMultipleAddModel[] = dto.data.map((item) => ({
        amount: item.attributes.amount,
        description: item.attributes.description,
        completedDate: item.attributes.completedDate,
      }));
      return Result.ok(addAnimalVaccineMultipleModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_VACCINE_MULTIPLE_ADD))
      );
    }
  }

  mapToDTO(domain: AnimalVaccineMultipleAddModel): Result<AnimalVaccineMultipleAddRequestDto> {
    try {
      const addAnimalVaccineRequest = new AnimalVaccineMultipleAddRequestDto(
        domain.animals,
        domain.barnyard,
        domain.vaccine,
        domain.amount,
        domain.description,
        domain.completedDate
      );

      return Result.ok(addAnimalVaccineRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_VACCINE_MULTIPLE_ADD))
      );
    }
  }
}
