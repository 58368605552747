import { AnimalVaccineListModel } from "@/domain/animal/animalVaccine/animalVaccineList/model/AnimalVaccineListModel";
import { AnimalVaccineListMapper } from "../mapper/AnimalVaccineListMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalVaccineListUseCase } from "@/domain/animal/animalVaccine/animalVaccineList/usecase/IAnimalVaccineListUsecase";
import { AnimalVaccineListRepository } from "../repository/AnimalVaccineListRepository";

export class AnimalVaccineListUsecase implements IAnimalVaccineListUseCase {
  animalVaccineListRepository: AnimalVaccineListRepository;
  animalVaccineListMapper = new AnimalVaccineListMapper();

  constructor(_animalVaccineListRepository: AnimalVaccineListRepository) {
    this.animalVaccineListRepository = _animalVaccineListRepository;
  }

  async execute(
    model: AnimalVaccineListModel
  ): Promise<Result<AnimalVaccineListModel>> {
    try {
      const dto = this.animalVaccineListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalVaccineListModel>(dto.getError());
      } else {
        const response = await this.animalVaccineListRepository.animalVaccineList(
          dto
        );

        if (response.isSuccess) {
          const domain = this.animalVaccineListMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<AnimalVaccineListModel>(domain.getValue());
        } else {
          return Result.fail<AnimalVaccineListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AnimalVaccineListModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_VACCINE_LIST))
      );
    }
  }
}
