import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalVaccineAddRequestDto } from "@/domain/animal/animalVaccine/animalVaccineAdd/dto/AnimalVaccineAddRequestDto";
import { AnimalVaccineAddResponseDto } from "@/domain/animal/animalVaccine/animalVaccineAdd/dto/AnimalVaccineAddResponseDto";
import { AnimalVaccineAddModel } from "@/domain/animal/animalVaccine/animalVaccineAdd/model/AnimalVaccineAddModel";

export class AnimalVaccineAddMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AnimalVaccineAddResponseDto): Result<AnimalVaccineAddModel> {
    try {
      const addAnimalVaccineModel: AnimalVaccineAddModel = {
        amount: dto.data.attributes.amount,
        description: dto.data.attributes.description,
        completedDate: dto.data.attributes.completedDate,
      };
      return Result.ok(addAnimalVaccineModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_VACCINE_ADD))
      );
    }
  }

  mapToDTO(domain: AnimalVaccineAddModel): Result<AnimalVaccineAddRequestDto> {
    try {
      const addAnimalVaccineRequest = new AnimalVaccineAddRequestDto(
        domain.animal,
        domain.vaccine,
        domain.vaccineScheduleItem,
        domain.amount,
        domain.description,
        domain.completedDate
      );

      return Result.ok(addAnimalVaccineRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_VACCINE_ADD))
      );
    }
  }
}
