import { AnimalDiseaseUpdateRepository } from "../repository/AnimalDiseaseUpdateRepository";
import { AnimalDiseaseUpdateMapper } from "../mapper/AnimalDiseaseUpdateMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalDiseaseUpdateUseCase } from "@/domain/animal/animalDisease/animalDiseaseUpdate/usecase/IAnimalDiseaseUpdateUsecase";
import { AnimalDiseaseUpdateModel } from "@/domain/animal/animalDisease/animalDiseaseUpdate/model/AnimalDiseaseUpdateModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../../environment";

export class AnimalDiseaseUpdateUsecase implements IAnimalDiseaseUpdateUseCase {
  animalDiseaseAddRepository: AnimalDiseaseUpdateRepository;
  animalDiseaseAddMapper = new AnimalDiseaseUpdateMapper();

  constructor(_animalDiseaseAddRepository: AnimalDiseaseUpdateRepository) {
    this.animalDiseaseAddRepository = _animalDiseaseAddRepository;
  }

  async execute(
    model: AnimalDiseaseUpdateModel
  ): Promise<Result<AnimalDiseaseUpdateModel>> {
    try {
      const dto = this.animalDiseaseAddMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalDiseaseUpdateModel>(dto.getError());
      } else {
        const response =
          await this.animalDiseaseAddRepository.updateAnimalDisease(
            dto,
            model.id
          );

        if (response.isSuccess) {
          const domain = this.animalDiseaseAddMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
            const analytics = getAnalytics();
            logEvent(analytics, "update_animal_disease", {
              animal_disease_id: model.id,
            });
          }

          return Result.ok<AnimalDiseaseUpdateModel>(domain.getValue());
        } else {
          return Result.fail<AnimalDiseaseUpdateModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AnimalDiseaseUpdateModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_DISEASE_UPDATE))
      );
    }
  }
}
