// Storage
export const LOCAL_STORAGE = {
  PERMISSIONS: "permissions",
  JWT: "jwt",
  ACTIVE_TENANT: "activeTenant",
  USER_TENANTS: "userTenants",
  LANG: "lang",
  CONFIG: "config",
  BUILDER_TAB: "builderTab",
  ENCRYPTION_KEY: "key",
  ENCRYPTION_IV: "iv",
};

export const COMPONENT_NAME = {
  APP: "app",
  BUILDER: "builder",
  LAYOUT: "Layout",
  TOPBAR: "topbar",
  MIXED: "widgets-mixed",
  STATISTICS: "widgets-statistics",
  TABLES: "widgets-tables",
  SEARCH: "search",
  UPGRADE_TO_PRO: "upgrade-to-pro",
  LOADER: "Loader",
  MODAL_CARD: "modal-card",
  INVOICES: "invoices",
  KT_EXPLORE: "kt-explore",
  KT_SCROLL_TOP: "KTScrollTop",
  KT_STICKY_TOOLBAR: "KTStickyToolbar",
  KT_FOOTER: "KTFooter",
  KT_HEADER: "KTHeader",
  KT_TOOLBAR: "KToolbar",
  KT_ASIDE: "KTAside",
  KT_ASIDE_MENU: "kt-menu",
  KT_MENU: "KTMenu",
  KT_ACTIVITY_DRAWER: "kt-activity-drawer",
  NOTIFICATIONS_MENU: "notifications-menu",
  KT_QUICK_LINKS_MENU: "kt-quick-links-menu",
  KT_USERS_MENU: "kt-user-menu",
  ANIMAL_LIST: "animalList",
  ANIMAL_INFO: "animalInfo",
  MOST_VALUABLE_ANIMAL: "mostValuableAnimal",
  CREATE_ANIMAL: "createAnimal",
  SELECT_BARNYARD: "selectBarnyard",
  BARNYARD_PANELS: "barnyardPanel",
  BARNYARD_ANIMALS: "barnyardAnimals",
  SELECT_ANIMAL_VACCINATION: "selectVaccine",
  ANIMAL_VACCINATION: "animalsVaccine",
};

export const CONSOLE = {
  ERROR: "Error",
};

export const STORE = {
  CONTROLLERS: "initializeControllers",
};

// Paths
export const ROUTE_PAGES = {
  SIGN_IN: "sign-in",
  DASHBOARD: "dashboard",
  FORGOT_PASSWORD: "forgot-password",
  RESET_PASSWORD: "password-reset",
  ANIMAL_INFO: "animal-info",
  BARNYARD_ANIMALS: "barnyard-animals",
  CREATE_ANIMAL: "createAnimal",
  ERROR: "/404",
  FORBIDDEN: "/500",
  PRIVACY: "privacy",
};

export const API_ROUTE = {
  AUTH_LOGIN: "auth/login",
  RESET_PASSWORD: "auth/reset-password",
  FORGOT_PASSWORD: "auth/forgot-password",
  ABOUT_USER: "users/me",
  CHANGE_TENANT_ESTATE: "auth/change-tenant-estate",
  ANIMALS: "animals/",
  ANIMAL_TRANSFER: "animal-transfers/",
  ANIMAL_WEIGHT: "animal-weighings/",
  ANIMAL_TYPE: "animal-types/",
  ANIMAL_VACCINE_ADD: "animal-vaccines",
  ANIMAL_DISEASES: "/animal-diseases/",
  ANIMAL_FEED: "/animal-feeds",
  ANIMAL_VACCINE_MULTIPLE_ADD: "animals-vaccines",
  ANIMAL_TAG: "animal-tags/",
  TENANT_ESTATE_STATISTIC: "statistics/tenant-estates",
  BREED: "animal-breeds/",
  UNIT_PRICES: "tenant-estate-animal-unit-prices/",
  DISEASE_LIST: "diseases",
  BARNYARDS: "barnyards/",
  VACCINE_LIST: "/vaccines",
  FEED_LIST: "products/animal-feeds",
  PRODUCT_BUY: "tenant-estate/purchase-order",
};

export const PAGE_TITLE_PATH = {
  DASHBOARD: "menuPages.dashboard.pageName",
  ANIMAL_INFO: "menuPages.animalInfo.pageName",
  ANIMAL_LIST: "menuPages.animalList.pageName",
  ANIMAL_CREATE: "menuPages.createAnimal.pageName",
  FEED: "menuPages.food.pageName",
  MY_WEIGHT: "menuPages.myWeight.pageName",
  VACCINE_SELECT: "menuPages.vaccineSelect.pageName",
  VACCINATION: "menuPages.vaccination.pageName",
  BARNYARD: "menuPages.barnyardPanel.pageName",
  BREED: "menuPages.breed.pageName",
  DISEASE: "menuPages.disease.pageName",
  FOOD: "menuPages.food.pageName",
  BUY_FEED: "menuPages.buyFeed.pageName",
  ANIMAL_TAGS: "menuPages.animalTags.pageName",
  ANIMAL_TYPES: "menuPages.animalTypes.pageName",
};

// Values
export const VALUE = {
  NULL_STRING: "", // EMPTY
  NULL: null,
  UNDEFINED: undefined,
  SHORT: "short",
};

export const BOOLEAN = {
  TRUE_STRING: "true",
  FALSE_STRING: "false",
  TRUE: true,
  FALSE: false,
};

// Structure
export const REPOSITORY = {
  LOGIN: "LoginRepository",
  RESET_PASSWORD: "ResetPasswordRepository",
  FORGOT_PASSWORD: "ForgotPasswordRepository",
  ABOUT_USER: "AboutUserRepository",
  CHANGE_TENANT_ESTATE: "ChangeTenantEstateRepository",
  ANIMAL_LIST: "AnimalListRepository",
  ANIMAL_ADD: "CreateAnimalRepository",
  ANIMAL_UPDATE: "UpdateAnimalRepository",
  ANIMAL_DELETE: "DeleteAnimalRepository",
  ANIMAL_GET: "GetAnimalDetailRepository",
  ANIMAL_TRANSFER: "AnimalTransferRepository",
  ANIMAL_MOST_VALUABLE: "MostValuableAnimalRepository",
  ANIMAL_WEIGHT_AVERAGE: "AnimalWeightAverageRepository",
  ANIMAL_WEIGHT_UPDATE: "AnimalWeightUpdateRepository",
  ANIMAL_WEIGHT_LIST: "AnimalWeightListRepository",
  ANIMAL_FEED_ADD: "AnimalFeedAddRepository",
  ANIMAL_FEED_LIST: "AnimalFeedListRepository",
  ANIMAL_DISEASE_ADD: "AnimalDiseaseAddRepository",
  ANIMAL_DISEASE_LIST: "AnimalDiseaseListRepository",
  ANIMAL_DISEASE_UPDATE: "AnimalDiseaseUpdateRepository",
  ANIMAL_TYPE_LIST: "AnimalTypeListRepository",
  ANIMAL_VACCINE_ADD: "AnimalVaccineAddRepository",
  ANIMAL_VACCINE_MULTIPLE_ADD: "AnimalVaccineMultipleAddRepository",
  ANIMAL_VACCINE_LIST: "AnimalVaccineListRepository",
  ANIMAL_TAG_ADD: "AnimalTagAddRepository",
  ANIMAL_TAG_UPDATE: "AnimalTagUpdateRepository",
  ANIMAL_TAG_LIST: "AnimalTagListRepository",
  ANIMAL_TAG_REMOVE: "AnimalTagRemoveRepository",
  TENANT_STATISTIC: "TenantStatisticRepository",
  VACCINATION_ADD: "VaccinationAddRepository",
  VACCINATION_LIST: "VaccinationListRepository",
  DISEASE_LIST: "DiseaseListRepository",
  BREED_LIST: "BreedListRepository",
  BREED_ADD: "BreedAddRepository",
  BREED_UPDATE: "BreedUpdateRepository",
  BREED_REMOVE: "BreedRemoveRepository",
  BARNYARD_LIST: "BarnyardListRepository",
  BARNYARD_DETAIL: "BarnyardDetailRepository",
  FEED_LIST: "FeedListRepository",
  FEED_BUY: "FeedBuyRepository",
  PRICE_ADD: "PriceAddRepository",
};

export const USECASE = {
  LOGIN: "LoginUsecase",
  RESET_PASSWORD: "ResetPasswordUsecase",
  FORGOT_PASSWORD: "ForgotPasswordUsecase",
  ABOUT_USER: "AboutUserUsecase",
  CHANGE_TENANT_ESTATE: "ChangeTenantEstateUsecase",
  ANIMAL_LIST: "AnimalListUsecase",
  ANIMAL_ADD: "CreateAnimalUsecase",
  ANIMAL_UPDATE: "UpdateAnimalUsecase",
  ANIMAL_DELETE: "DeleteAnimalUsecase",
  ANIMAL_GET: "GetAnimalDetailUsecase",
  ANIMAL_TRANSFER: "AnimalTransferUsecase",
  ANIMAL_MOST_VALUABLE: "MostValuableAnimalUsecase",
  ANIMAL_WEIGHT_AVERAGE: "AnimalWeightAverageUsecase",
  ANIMAL_WEIGHT_UPDATE: "AnimalWeightUpdateUsecase",
  ANIMAL_WEIGHT_LIST: "AnimalWeightListUsecase",
  ANIMAL_FEED_ADD: "AnimalFeedAddUsecase",
  ANIMAL_FEED_LIST: "AnimalFeedListUsecase",
  ANIMAL_DISEASE_ADD: "AnimalDiseaseAddUsecase",
  ANIMAL_DISEASE_LIST: "AnimalDiseaseListUsecase",
  ANIMAL_DISEASE_UPDATE: "AnimalDiseaseUpdateUsecase",
  ANIMAL_TYPE_LIST: "AnimalTypeListUsecaser",
  ANIMAL_VACCINE_ADD: "AnimalVaccineAddUsecase",
  ANIMAL_VACCINE_MULTIPLE_ADD: "AnimalVaccineMultipleAddUsecase",
  ANIMAL_VACCINE_LIST: "AnimalVaccineListUsecase",
  ANIMAL_TAG_ADD: "AnimalTagAddUsecase",
  ANIMAL_TAG_UPDATE: "AnimalTagUpdateUsecase",
  ANIMAL_TAG_LIST: "AnimalTagListUsecase",
  ANIMAL_TAG_REMOVE: "AnimalTagRemoveUsecase",
  TENANT_STATISTIC: "TenantStatisticUsecase",
  VACCINATION_ADD: "VaccinationAddUsecase",
  VACCINATION_LIST: "VaccinationListUsecase",
  DISEASE_LIST: "DiseaseListUsecase",
  BREED_LIST: "BreedListUsecase",
  BREED_ADD: "BreedAddUsecase",
  BREED_UPDATE: "BreedUpdateUsecase",
  BREED_REMOVE: "BreedRemoveUsecase",  
  BARNYARD_LIST: "BarnyardListUsecase",
  BARNYARD_DETAIL: "BarnyardDetailUsecase",
  FEED_LIST: "FeedListUsecase",
  FEED_BUY: "FeedBuyUsecase",
  PRICE_ADD: "PriceAddUsecase",
};

export const SERVICE = {
  LOGIN: "LoginService",
  RESET_PASSWORD: "ResetPasswordService",
  FORGOT_PASSWORD: "ForgotPasswordService",
  ABOUT_USER: "AboutUserService",
  CHANGE_TENANT_ESTATE: "ChangeTenantEstateService",
  ANIMAL_LIST: "AnimalListService",
  ANIMAL_ADD: "CreateAnimalService",
  ANIMAL_UPDATE: "UpdateAnimalService",
  ANIMAL_DELETE: "DeleteAnimalService",
  ANIMAL_GET: "GetAnimalDetailService",
  ANIMAL_TRANSFER: "AnimalTransferService",
  ANIMAL_MOST_VALUABLE: "MostValuableAnimalService",
  ANIMAL_WEIGHT_AVERAGE: "AnimalWeightAverageService",
  ANIMAL_WEIGHT_UPDATE: "AnimalWeightUpdateService",
  ANIMAL_WEIGHT_LIST: "AnimalWeightListService",
  ANIMAL_FEED_ADD: "AnimalFeedAddService",
  ANIMAL_FEED_LIST: "AnimalFeedListService",
  ANIMAL_DISEASE_ADD: "AnimalDiseaseAddService",
  ANIMAL_DISEASE_LIST: "AnimalDiseaseListService",
  ANIMAL_DISEASE_UPDATE: "AnimalDiseaseUpdateService",
  ANIMAL_TYPE_LIST: "AnimalTypeListService",
  ANIMAL_VACCINE_ADD: "AnimalVaccineAddService",
  ANIMAL_VACCINE_MULTIPLE_ADD: "AnimalVaccineMultipleAddService",
  ANIMAL_VACCINE_LIST: "AnimalVaccineListService",
  ANIMAL_TAG_ADD: "AnimalTagAddService",
  ANIMAL_TAG_UPDATE: "AnimalTagUpdateService",
  ANIMAL_TAG_LIST: "AnimalTagListService",
  ANIMAL_TAG_REMOVE: "AnimalTagRemoveService",
  TENANT_STATISTIC: "TenantStatisticService",
  VACCINATION_ADD: "VaccinationAddService",
  VACCINATION_LIST: "VaccinationListService",
  DISEASE_LIST: "DiseaseListService",
  BREED_LIST: "BreedListService",
  BREED_ADD: "BreedAddService",
  BREED_UPDATE: "BreedUpdateService",
  BREED_REMOVE: "BreedRemoveService",
  BARNYARD_LIST: "BarnyardListService",
  BARNYARD_DETAIL: "BarnyardDetailService",
  FEED_LIST: "FeedListService",
  FEED_BUY: "FeedBuyService",
  PRICE_ADD: "PriceAddService",
};

export const MAPPER = {
  LOGIN: "LoginMapper",
  RESET_PASSWORD: "ResetPasswordMapper",
  FORGOT_PASSWORD: "ForgotPasswordMapper",
  ABOUT_USER: "AboutUserMapper",
  CHANGE_TENANT_ESTATE: "ChangeTenantEstateMapper",
  ANIMAL_LIST: "AnimalListMapper",
  ANIMAL_ADD: "CreateAnimalMapper",
  ANIMAL_UPDATE: "UpdateAnimalMapper",
  ANIMAL_DELETE: "DeleteAnimalMapper",
  ANIMAL_GET: "GetAnimalDetailMapper",
  ANIMAL_TRANSFER: "AnimalTransferMapper",
  ANIMAL_MOST_VALUABLE: "MostValuableAnimalMapper",
  ANIMAL_WEIGHT_AVERAGE: "AnimalWeightAverageMapper",
  ANIMAL_WEIGHT_UPDATE: "AnimalWeightUpdateMapper",
  ANIMAL_WEIGHT_LIST: "AnimalWeightListMapper",
  ANIMAL_FEED_ADD: "AnimalFeedAddMapper",
  ANIMAL_FEED_LIST: "AnimalFeedListMapper",
  ANIMAL_DISEASE_ADD: "AnimalDiseaseAddMapper",
  ANIMAL_DISEASE_LIST: "AnimalDiseaseListMapper",
  ANIMAL_DISEASE_UPDATE: "AnimalDiseaseUpdateMapper",
  ANIMAL_TYPE_LIST: "AnimalTypeListMapper",
  ANIMAL_VACCINE_ADD: "AnimalVaccineAddMapper",
  ANIMAL_VACCINE_MULTIPLE_ADD: "AnimalVaccineMultipleAddMapper",
  ANIMAL_VACCINE_LIST: "AnimalVaccineListMapper",
  ANIMAL_TAG_ADD: "AnimalTagAddMapper",
  ANIMAL_TAG_UPDATE: "AnimalTagUpdateMapper",
  ANIMAL_TAG_LIST: "AnimalTagListMapper",
  ANIMAL_TAG_REMOVE: "AnimalTagRemoveMapper",
  TENANT_STATISTIC: "TenantStatisticMapper",
  VACCINATION_ADD: "VaccinationAddMapper",
  VACCINATION_LIST: "VaccinationListMapper",
  DISEASE_LIST: "DiseaseListMapper",
  BREED_LIST: "BreedListMapper",
  BREED_ADD: "BreedAddMapper",
  BREED_UPDATE: "BreedUpdateMapper",
  BREED_REMOVE: "BreedRemoveMapper",
  BARNYARD_LIST: "BarnyardListMapper",
  BARNYARD_DETAIL: "BarnyardDetailMapper",
  FEED_LIST: "FeedListMapper",
  FEED_BUY: "FeedBuyMapper",
  PRICE_ADD: "PriceAddMapper",
};

// Messages
export const SWAL_MESSAGES = {
  SESSION_TIME_OUT: "swalMessages.ERROR.SESSION_TIME_OUT",

  SUCCESS: "swalMessages.SUCCESS.SUCCESS",
  LOGIN_SUCCESS_MESSAGE: "swalMessages.SUCCESS.LOGIN_SUCCESS_MESSAGE",
  SUCCESS_FORGOT_PASSWORD: "swalMessages.SUCCESS.SUCCESS_FORGOT_PASSWORD",
  SUCCESS_RESET_PASSWORD: "swalMessages.SUCCESS.SUCCESS_RESET_PASSWORD",

  LOGIN_ERROR_MESSAGE: "swalMessages.ERROR.LOGIN_ERROR_MESSAGE",
  ERROR_FORGOT_PASSWORD: "swalMessages.ERROR.ERROR_FORGOT_PASSWORD",
  ERROR_RESET_PASSWORD: "swalMessages.ERROR.ERROR_RESET_PASSWORD",
  ERROR_CHANGE_TENANT_USER: "swalMessages.ERROR.ERROR_CHANGE_TENANT_USER",
  ERROR_ABOUT_USER: "swalMessages.ERROR.ERROR_ABOUT_USER",

  CONFIRM_BUTTON_TEXT: "swalMessages.CONFIRM_BUTTON_TEXT",
  API_ERROR_MESSAGE: "swalMessages.API_ERROR_MESSAGE",
  ERROR_CODE: "swalMessages.ERROR_CODE",
  UNKNOWN_ERROR_MESSAGE: "swalMessages.UNKNOWN_ERROR_MESSAGE",
  SURE_DELETE_ANIMAL: "swalMessages.SURE_DELETE_ANIMAL",
  SURE_TREAT_ANIMAL: "swalMessages.SURE_TREAT_ANIMAL",
  ARE_YOU_SURE: "swalMessages.ARE_YOU_SURE",

  SUCCESS_CREATE_ANIMAL: "swalMessages.SUCCESS.SUCCESS_CREATE_ANIMAL",
  SUCCESS_UPDATE_ANIMAL: "swalMessages.SUCCESS.SUCCESS_UPDATE_ANIMAL",
  SUCCESS_DELETE_ANIMAL: "swalMessages.SUCCESS.SUCCESS_DELETE_ANIMAL",
  SUCCESS_TRANSFER_ANIMAL: "swalMessages.SUCCESS.SUCCESS_TRANSFER_ANIMAL",

  SUCCESS_UPDATE_ANIMAL_WEIGHT: "swalMessages.SUCCESS.SUCCESS_UPDATE_ANIMAL_WEIGHT",
  SUCCESS_ADD_ANIMAL_VACCINE: "swalMessages.SUCCESS.SUCCESS_ADD_ANIMAL_VACCINE",
  SUCCESS_ADD_ANIMAL_TAG: "swalMessages.SUCCESS.SUCCESS_ADD_ANIMAL_TAG",
  SUCCESS_UPDATE_ANIMAL_TAG: "swalMessages.SUCCESS.SUCCESS_UPDATE_ANIMAL_TAG",
  SUCCESS_REMOVE_ANIMAL_TAG: "swalMessages.SUCCESS.SUCCESS_REMOVE_ANIMAL_TAG",
  SUCCESS_BUY_FOOD: "swalMessages.SUCCESS.SUCCESS_BUY_FOOD",
  SUCCESS_ADD_BREED: "swalMessages.SUCCESS.SUCCESS_ADD_BREED",
  SUCCESS_UPDATE_BREED: "swalMessages.SUCCESS.SUCCESS_UPDATE_BREED",
  SUCCESS_REMOVE_BREED: "swalMessages.SUCCESS.SUCCESS_REMOVE_BREED",
  SUCCESS_UPDATE_PRICE: "swalMessages.SUCCESS.SUCCESS_UPDATE_PRICE",
  SUCCESS_ADD_ANIMAL_FEED: "swalMessages.SUCCESS.SUCCESS_ADD_ANIMAL_FEED",
  SUCCESS_ADD_ANIMAL_DISEASE: "swalMessages.SUCCESS.SUCCESS_ADD_ANIMAL_DISEASE",
  SUCCESS_UPDATE_ANIMAL_DISEASE: "swalMessages.SUCCESS.SUCCESS_UPDATE_ANIMAL_DISEASE",

  ERROR: "swalMessages.ERROR.ERROR",

  ERROR_CREATE_ANIMAL: "swalMessages.ERROR.ERROR_CREATE_ANIMAL",
  ERROR_UPDATE_ANIMAL: "swalMessages.ERROR.ERROR_UPDATE_ANIMAL",
  ERROR_DELETE_ANIMAL: "swalMessages.ERROR.ERROR_DELETE_ANIMAL",
  ERROR_TRANSFER_ANIMAL: "swalMessages.ERROR.ERROR_TRANSFER_ANIMAL",

  ERROR_UPDATE_ANIMAL_WEIGHT: "swalMessages.ERROR.ERROR_UPDATE_ANIMAL_WEIGHT",
  ERROR_ADD_ANIMAL_VACCINE: "swalMessages.ERROR.ERROR_ADD_ANIMAL_VACCINE",
  ERROR_ADD_ANIMAL_TAG: "swalMessages.ERROR.ERROR_ADD_ANIMAL_TAG",
  ERROR_UPDATE_ANIMAL_TAG: "swalMessages.ERROR.ERROR_UPDATE_ANIMAL_TAG",
  ERROR_REMOVE_ANIMAL_TAG: "swalMessages.ERROR.ERROR_REMOVE_ANIMAL_TAG",
  ERROR_BUY_FOOD: "swalMessages.ERROR.ERROR_BUY_FOOD",
  ERROR_ADD_BREED: "swalMessages.ERROR.ERROR_ADD_BREED",
  ERROR_UPDATE_BREED: "swalMessages.ERROR.ERROR_UPDATE_BREED",
  ERROR_REMOVE_BREED: "swalMessages.ERROR.ERROR_REMOVE_BREED",
  ERROR_UPDATE_PRICE: "swalMessages.ERROR.ERROR_UPDATE_PRICE",
  ERROR_ADD_ANIMAL_FEED: "swalMessages.ERROR.ERROR_ADD_ANIMAL_FEED",
  ERROR_ADD_ANIMAL_DISEASE: "swalMessages.ERROR.ERROR_ADD_ANIMAL_DISEASE",
  ERROR_UPDATE_ANIMAL_DISEASE: "swalMessages.ERROR.ERROR_UPDATE_ANIMAL_DISEASE",

  ERROR_ANIMAL_LIST: "swalMessages.ERROR.ERROR_ANIMAL_LIST",
  ERROR_ANIMAL_COUNT: "swalMessages.ERROR.ERROR_ANIMAL_COUNT",
  ERROR_MOST_VALUABLE_ANIMAL: "swalMessages.ERROR.ERROR_MOST_VALUABLE_ANIMAL",
  ERROR_ANIMAL_WEIGHT_AVERAGE: "swalMessages.ERROR.ERROR_ANIMAL_WEIGHT_AVERAGE",
  ERROR_TOTAL_COST: "swalMessages.ERROR.ERROR_TOTAL_COST",
  ERROR_TOTAL_VALUE_ANIMALS: "swalMessages.ERROR.ERROR_TOTAL_VALUE_ANIMALS",
  ERROR_ANIMAL_TAG_LIST: "swalMessages.ERROR.ERROR_ANIMAL_TAG_LIST",
  ERROR_ANIMAL_TYPE_LIST: "swalMessages.ERROR.ERROR_ANIMAL_TYPE_LIST",
  ERROR_ANIMAL_WEIGHT_LIST: "swalMessages.ERROR.ERROR_ANIMAL_WEIGHT_LIST",
  ERROR_GET_ANIMAL_DETAIL: "swalMessages.ERROR.ERROR_GET_ANIMAL_DETAIL",
  ERROR_BARNYARD_LIST: "swalMessages.ERROR.ERROR_BARNYARD_LIST",
  ERROR_BARNYARD_DETAIL: "swalMessages.ERROR.ERROR_BARNYARD_DETAIL",
  ERROR_BREED_LIST: "swalMessages.ERROR.ERROR_BREED_LIST",
  ERROR_FOOD_LIST: "swalMessages.ERROR.ERROR_FOOD_LIST",
  ERROR_VACCINE_LIST: "swalMessages.ERROR.ERROR_VACCINE_LIST",
  ERROR_ANIMAL_VACCINE_LIST: "swalMessages.ERROR.ERROR_ANIMAL_VACCINE_LIST",
  ERROR_DISEASE_LIST: "swalMessages.ERROR.ERROR_DISEASE_LIST",
  ERROR_ANIMAL_DISEASE_LIST: "swalMessages.ERROR.ERROR_ANIMAL_DISEASE_LIST",
  ERROR_ANIMAL_FEED_LIST: "swalMessages.ERROR.ERROR_ANIMAL_FEED_LIST",
};

// Error Messages
export const CUSTOM_ERROR_MESSAGES = {
  usecase: "customErrorMessages.usecase.error",
  mapper: "customErrorMessages.mapper.error",
  controller: "customErrorMessages.controller.error",
  repository: "customErrorMessages.repository.error",
  aggregateRoot: "customErrorMessages.aggregateRoot.error",
  domainEvent: "customErrorMessages.domainEvent.error",
  email_required: "customErrorMessages.email.required",
  email_invalid: "customErrorMessages.email.invalid",
  password_required: "customErrorMessages.password.required",
  password_min: "customErrorMessages.password.min",
  password_match: "customErrorMessages.password.match",
  identity_required: "customErrorMessages.identity.required",
  identity_length: "customErrorMessages.identity.length",
  credential_required: "customErrorMessages.credential.required",
  credential_credential: "customErrorMessages.credential.credential",
  required: "customErrorMessages.required",
};

export const VALIDATOR_ERROR = {
  required: "validators_error.required",
  typeError: "validators_error.typeError",
  email: "validators_error.email",
  minlength: "validators_error.minlength",
  maxlength: "validators_error.maxlength",
  zeroValue: "validators_error.zeroValue",
};
