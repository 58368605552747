import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IPriceAddService } from "@/domain/breed/priceAdd/service/IPriceAddService";
import { PriceAddRequestDto } from "@/domain/breed/priceAdd/dto/PriceAddRequestDto";

export default class PriceAddService implements IPriceAddService {
  async add(
    request: PriceAddRequestDto
  ): Promise<Result<boolean>> {
    try {
      return await instance
        .post(API_ROUTE.UNIT_PRICES, request)
        .then((response) => {
          return Result.ok<boolean>(response.data.ok);
        })
        .catch(() => {
          return Result.fail<boolean>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_UPDATE_PRICE)))
          );
        });
    } catch (error) {
      return Result.fail<boolean>(
        new ServiceException(new UnknownCause(SERVICE.PRICE_ADD))
      );
    }
  }
}
