import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalListService } from "@/domain/animal/animalList/service/IAnimalListService";
import { AnimalListResponseDto } from "@/domain/animal/animalList/dto/AnimalListResponseDto";
import { AnimalListRequestDto } from "@/domain/animal/animalList/dto/AnimalListRequestDto";
import { BadRequestException } from "@/core/exception/BadRequestException";
import i18n from "@/presentation/language/i18n";
import { BadRequestCause } from "@/core/cause/BadRequestCause";

export default class AnimalListService implements IAnimalListService {
  async getList(
    request: AnimalListRequestDto
  ): Promise<Result<AnimalListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.ANIMALS + request.toQueryString())
        .then((response) => {
          return Result.ok<AnimalListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<AnimalListResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_ANIMAL_LIST)))
          );
        });
    } catch (error) {
      return Result.fail<AnimalListResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_LIST))
      );
    }
  }
}
