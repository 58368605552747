import { AnimalWeightUpdateRepository } from "../repository/AnimalWeightUpdateRepository";
import { AnimalWeightUpdateMapper } from "../mapper/AnimalWeightUpdateMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalWeightUpdateUseCase } from "@/domain/animal/animalWeight/animalWeightUpdate/usecase/IAnimalWeightUpdateUsecase";
import { AnimalWeightUpdateModel } from "@/domain/animal/animalWeight/animalWeightUpdate/model/AnimalWeightUpdateModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../../environment";

export class AnimalWeightUpdateUsecase implements IAnimalWeightUpdateUseCase {
  animalWeightUpdateRepository: AnimalWeightUpdateRepository;
  animalWeightUpdateMapper = new AnimalWeightUpdateMapper();

  constructor(_animalWeightUpdateRepository: AnimalWeightUpdateRepository) {
    this.animalWeightUpdateRepository = _animalWeightUpdateRepository;
  }

  async execute(model: AnimalWeightUpdateModel): Promise<Result<AnimalWeightUpdateModel>> {
    try {
      const dto = this.animalWeightUpdateMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalWeightUpdateModel>(dto.getError());
      } else {
        const response = await this.animalWeightUpdateRepository.updateAnimalWeight(dto);

        if (response.isSuccess) {
          const domain = this.animalWeightUpdateMapper.mapToDomain(response.getValue());
          if (environment.env == "prod") {
          const analytics = getAnalytics();
          logEvent(analytics, 'animal_weight_update', {
            animal_id: model.animalId
          });
        }
          return Result.ok<AnimalWeightUpdateModel>(domain.getValue());
        } else {
          return Result.fail<AnimalWeightUpdateModel>(response.getError())
        }
      }
    } catch (error) {
      return Result.fail<AnimalWeightUpdateModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_WEIGHT_UPDATE))
      );
    }
  }
}
