import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalVaccineListRequestDto } from "@/domain/animal/animalVaccine/animalVaccineList/dto/AnimalVaccineListRequestDto";
import { AnimalVaccineItemResponseDto } from "@/domain/animal/animalVaccine/animalVaccineList/dto/AnimalVaccineItemResponseDto";
import { AnimalVaccineListModel } from "@/domain/animal/animalVaccine/animalVaccineList/model/AnimalVaccineListModel";

export class AnimalVaccineListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AnimalVaccineItemResponseDto): Result<AnimalVaccineListModel[]> {
    try {
      const animalVaccineList: AnimalVaccineListModel[] = [];

      for (const item of dto.data) {
        const animalVaccineListModel: AnimalVaccineListModel = {
          id: item.id,
          name: item.attributes.name,
          description: item.attributes.description,
          vaccineSchedule: item.attributes.vaccineSchedule.data,
          animalVaccineItems: item.attributes.animalVaccineItems.data,
        };
        animalVaccineList.push(animalVaccineListModel);
      }

      return Result.ok(animalVaccineList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_VACCINE_LIST))
      );
    }
  }

  mapToDTO(domain: AnimalVaccineListModel): Result<AnimalVaccineListRequestDto> {
    try {
      const animalVaccineListRequest: AnimalVaccineListRequestDto = {
        id: domain.id,
      };
      return Result.ok(animalVaccineListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_VACCINE_LIST))
      );
    }
  }
}
