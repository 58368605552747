import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store";
import { Actions, Mutations } from "@/presentation/store/enums/StoreEnums";

import { LocalStorage } from "@/core/storage/LocalStorage";
import { AES256EncryptionService } from "@/infrastructure/encryption/AES256EncryptionService";
import {
  API_ROUTE,
  LOCAL_STORAGE,
  ROUTE_PAGES,
  VALUE,
} from "@/domain/Constant";
import { JwtTokenManager } from "@/infrastructure/storage/JwtTokenManager";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/presentation/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: { title: "Anasayfa | Maldavar Çiftlik Otomasyonu" },
        component: () => import("@/presentation/views/Dashboard.vue"),
      },
      {
        path: "/animalList",
        name: "animalList",
        component: () => import("@/presentation/views/animal/AnimalList.vue"),
        meta: { requiresPermission: "ANIMAL" },
      },
      {
        path: "/createAnimal",
        name: "createAnimal",
        component: () => import("@/presentation/views/animal/CreateAnimal.vue"),
        meta: { title: "Hayvan Ekle | Maldavar Çiftlik Otomasyonu", requiresPermission: "ANIMAL" },
      },
      {
        path: "/barnyards",
        name: "barnyards",
        component: () =>
          import("@/presentation/views/barnyard/BarnyardList.vue"),
        meta: { title: "Padoklar | Maldavar Çiftlik Otomasyonu", requiresPermission: "ANIMAL" },
      },
      {
        path: "/animalTypes",
        name: "animalTypes",
        component: () =>
          import("@/presentation/views/basicKnowledge/AnimalTypes.vue"),
        meta: { title: "Hayvan Tipleri | Maldavar Çiftlik Otomasyonu", requiresPermission: "BASIC" },
      },
      {
        path: "/barnyard/:id",
        name: "barnyard",
        component: () => import("@/presentation/views/barnyard/Barnyard.vue"),
        meta: { requiresPermission: "ANIMAL" },
        children: [
          {
            path: "animals",
            name: "barnyard-animals",
            meta: { title: "Padok Hayvanları | Maldavar Çiftlik Otomasyonu" },
            component: () =>
              import("@/presentation/components/barnyard/Animals.vue"),
          },
          {
            path: "vaccinations",
            name: "barnyard-vaccinations",
            meta: { title: "Padok Aşıları | Maldavar Çiftlik Otomasyonu" },
            component: () =>
              import("@/presentation/components/barnyard/Vaccinations.vue"),
          },
          {
            path: "weights",
            name: "barnyard-weights",
            meta: { title: "Padok Tartımları | Maldavar Çiftlik Otomasyonu" },
            component: () =>
              import("@/presentation/components/barnyard/Weights.vue"),
          },
          {
            path: "feeds",
            name: "barnyard-feeds",
            meta: { title: "Padok Yemleri | Maldavar Çiftlik Otomasyonu" },
            component: () =>
              import("@/presentation/components/barnyard/Feeds.vue"),
          },
        ],
      },
      {
        path: "/animal/:id",
        name: "animal",
        component: () => import("@/presentation/views/animal/Animal.vue"),
        meta: { requiresPermission: "ANIMAL" },
        children: [
          {
            path: "info",
            name: "animal-info",
            meta: { title: "Hayvan Profili | Maldavar Çiftlik Otomasyonu" },
            component: () =>
              import("@/presentation/components/animal/Info.vue"),
          },
          {
            path: "weights",
            name: "animal-weights",
            meta: { title: "Hayvan Tartımları | Maldavar Çiftlik Otomasyonu" },
            component: () =>
              import("@/presentation/components/animal/Weights.vue"),
          },
          {
            path: "vaccines",
            name: "animal-vaccines",
            meta: { title: "Hayvan Aşıları | Maldavar Çiftlik Otomasyonu" },
            component: () =>
              import("@/presentation/components/animal/Vaccines.vue"),
          },
          {
            path: "diseases",
            name: "animal-diseases",
            meta: { title: "Hayvan Hastalıkları | Maldavar Çiftlik Otomasyonu" },
            component: () =>
              import("@/presentation/components/animal/Diseases.vue"),
          },
          {
            path: "feeds",
            name: "animal-feeds",
            meta: { title: "Hayvan Yem Kayıtları | Maldavar Çiftlik Otomasyonu" },
            component: () =>
              import("@/presentation/components/animal/Foods.vue"),
          },
        ],
      },
      {
        path: "/breeds",
        name: "breeds",
        component: () =>
          import("@/presentation/views/basicKnowledge/Breeds.vue"),
        meta: { title: "Hayvan Cinsleri | Maldavar Çiftlik Otomasyonu", requiresPermission: "BASIC" },
      },
      {
        path: "/animalTags",
        name: "animalTags",
        component: () =>
          import("@/presentation/views/basicKnowledge/AnimalTags.vue"),
        meta: { title: "Hayvan Etiketleri | Maldavar Çiftlik Otomasyonu", requiresPermission: "BASIC" },
      },
      {
        path: "/vaccines",
        name: "vaccines",
        component: () =>
          import("@/presentation/views/basicKnowledge/Vaccines.vue"),
        meta: { title: "Aşılar | Maldavar Çiftlik Otomasyonu", requiresPermission: "BASIC" },
      },
      {
        path: "/foods",
        name: "foods",
        component: () =>
          import("@/presentation/views/basicKnowledge/Foods.vue"),
        meta: { title: "Yemler | Maldavar Çiftlik Otomasyonu", requiresPermission: "BASIC" },
      },
      {
        path: "/diseases",
        name: "diseases",
        component: () =>
          import("@/presentation/views/basicKnowledge/Diseases.vue"),
        meta: { title: "Hastalıklar | Maldavar Çiftlik Otomasyonu", requiresPermission: "BASIC" },
      },
      {
        path: "/buyFeed",
        name: "buyFeed",
        component: () => import("@/presentation/views/buy/Food.vue"),
        meta: { title: "Yem Satın Al | Maldavar Çiftlik Otomasyonu", requiresPermission: "PURCHASE_ORDER" },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/presentation/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        meta: { title: "Giriş Yap | Maldavar Çiftlik Otomasyonu" },
        component: () =>
          import("@/presentation/views/authorization/SignIn.vue"),
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        meta: { title: "Şifremi Unuttum | Maldavar Çiftlik Otomasyonu" },
        component: () =>
          import("@/presentation/views/authorization/ForgotPassword.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        meta: { title: "Şifre Sıfırla | Maldavar Çiftlik Otomasyonu" },
        component: () =>
          import("@/presentation/views/authorization/PasswordReset.vue"),
      },
      {
        path: "/privacy",
        name: "privacy",
        meta: { title: "Gizlilik Sözleşmesi | Maldavar Çiftlik Otomasyonu" },
        component: () =>
          import("@/presentation/views/Privacy.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    meta: { title: "400 - Sayfa Bulunamadı" },
    component: () => import("@/presentation/views/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    meta: { title: "500 - Bulunamadı" },
    component: () => import("@/presentation/views/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: ROUTE_PAGES.ERROR,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const localStorage = new LocalStorage();
const jwtTokenManager = new JwtTokenManager();
const swalNotification = new SwalNotification();

router.beforeEach((to, from, next) => {
  document.title = (to.meta.title as string) || "Maldavar";

  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (
    to.name === ROUTE_PAGES.FORGOT_PASSWORD ||
    to.name === ROUTE_PAGES.RESET_PASSWORD || to.name === ROUTE_PAGES.SIGN_IN
  ) {
    store.dispatch(Actions.VERIFY_AUTH);
    if (!store.getters.isUserAuthenticated) {
      next();
      return;
    }
  } 
  else if(to.name === ROUTE_PAGES.PRIVACY){
    next();
  }else {
    store.dispatch(Actions.VERIFY_AUTH);
    if (!store.getters.isUserAuthenticated) {
      next({ name: ROUTE_PAGES.SIGN_IN });
      return;
    }

    if (checkTokenValidity() == true) {
      const requiresPermission = to.meta.requiresPermission; // menüdeki route permission kodu
      if (requiresPermission) {
        let permissionMatched = false;
        const permissionsEncrypt = localStorage.getItem(
          LOCAL_STORAGE.PERMISSIONS
        );
        const decryptPermission =
          AES256EncryptionService.decrypt(permissionsEncrypt); // kullanıcının permissionları
        if (
          decryptPermission != null &&
          decryptPermission != VALUE.NULL_STRING
        ) {
          try {
            const userPermissions: string[] = JSON.parse(decryptPermission); // liste halinde izni olan yerler
            userPermissions.forEach((permission) => {
              if (permission === requiresPermission) {
                permissionMatched = true;
              }
            });
            if (permissionMatched) {
              next();
            } else {
              console.error("Kullanıcı izne sahip değil.");
              next(ROUTE_PAGES.FORBIDDEN);
            }
          } catch (error) {
            console.error("error", error);
            next(ROUTE_PAGES.ERROR);
          }
        } else {
          jwtTokenManager.destroyToken();
          next(ROUTE_PAGES.SIGN_IN);
        }
      } else {
        next();
      }
    } else {
      window.location.href = "/sign-in";
      swalNotification.error(
        "401 Hatası: Kullanıcı girişi yapmanız gerekiyor",
        "Tamam"
      );
    }
  }
});

import instance from "@/infrastructure/network/axios/AxiosService";
import { AboutUserResponseDto } from "@/domain/authentication/aboutUser/dto/AboutUserResponseDto";
import SwalNotification from "../plugins/SwalNotification";

const checkTokenValidity = (): boolean => {
  instance
    .get<AboutUserResponseDto>(API_ROUTE.ABOUT_USER)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
  return true;
};

export default router;
