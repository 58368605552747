import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalFeedAddService from "../service/AnimalFeedAddService";
import { IAnimalFeedAddRepository } from "@/domain/animal/animalFeed/animalFeedAdd/repository/IAnimalFeedAddRepository";
import { AnimalFeedAddRequestDto } from "@/domain/animal/animalFeed/animalFeedAdd/dto/AnimalFeedAddRequestDto";

const addAnimalFeedService = new AnimalFeedAddService();

export class AnimalFeedAddRepository implements IAnimalFeedAddRepository {
  async addAnimalFeed(
    request: AnimalFeedAddRequestDto
  ): Promise<Result<any>> {
    try {
      return await addAnimalFeedService
        .add(request)
        .then((response) => {

          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<any>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_FEED_ADD))
      );
    }
  }
}
