import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalTypeListRequestDto } from "@/domain/animal/animalTypeList/dto/AnimalTypeListRequestDto";
import { AnimalTypeListResponseDto } from "@/domain/animal/animalTypeList/dto/AnimalTypeListResponseDto";
import { AnimalTypeListModel } from "@/domain/animal/animalTypeList/model/AnimalTypeListModel";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";

const paginationMapper = new PaginationMapper();

export class AnimalTypeListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AnimalTypeListResponseDto): Result<AnimalTypeListModel[]> {
    try {
      const animalTypeList: AnimalTypeListModel[] = [];

      for (const item of dto.data) {
        const animalTypeListModel: AnimalTypeListModel = {
          animalType: {
            id: item.id,
            name: item.attributes.name,
            shortCode: item.attributes.shortCode
          },
          page: 0,
          pageSize: 0,
          pagination: dto.meta.pagination
        };
        animalTypeList.push(animalTypeListModel);
      }

      return Result.ok(animalTypeList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_TYPE_LIST))
      );
    }
  }

  mapToDTO(domain: AnimalTypeListModel): Result<AnimalTypeListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const animalTypeListRequest = new AnimalTypeListRequestDto(
        pagiRequest,
      );

      return Result.ok(animalTypeListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_TYPE_LIST))
      );
    }
  }
}
