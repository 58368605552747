import { BaseResponseDataDTO } from "@/domain/base/BaseResponseDataDto";
import { BaseResponseDTO } from "@/domain/base/BaseResponseDto";
import { LoginResponseAttributes } from "./LoginResponseAttributes";
import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseResponseErrorDto } from "@/domain/base/BaseErrorResponseDto";

export class LoginResponseDto
  extends BaseDTO
  implements BaseResponseDTO<LoginResponseAttributes>
{
  jwt: string;
  data: BaseResponseDataDTO<LoginResponseAttributes>;
  error: BaseResponseErrorDto
  
  constructor(jwt: string, data: LoginResponseAttributes, error: BaseResponseErrorDto) {
    super();
    this.jwt = jwt;
    this.data = { attributes: data };
    this.error = error;
  }
}
