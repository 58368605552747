export class CauseCode {
  static readonly badRequest = 2000;
    static readonly unknown = 2001;
    static readonly nullValue = 2002;
    static readonly emptyValue = 2003;
    static readonly minLength = 2004;
    static readonly maxLength = 2005;
    static readonly notValidEmail = 2006;
    static readonly tokenExpired = 2007;
  }
  