import { BaseDTO } from "@/core/base/BaseDTO";

export class FeedBuyRequestDto extends BaseDTO {
  data: {
    name?: string;
    description?: string;
    unitPrice?: number;
    quantity?: number;
    purchaseDate?: Date;
    productGroupShortCode?: string;
  }
  constructor(
    name?: string,
    description?: string,
    unitPrice?: number,
    quantity?: number,
    purchaseDate?: Date,
    productGroupShortCode?: string
  ) {
    super();
    this.data = {
      name,
      description,
      unitPrice,
      quantity,
      purchaseDate,
      productGroupShortCode
    }
  }
}
