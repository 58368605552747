import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { GetAnimalDetailResponseDto } from "@/domain/animal/animalDetail/dto/GetAnimalDetailResponseDto";
import { GetAnimalDetailRequestDto } from "@/domain/animal/animalDetail/dto/GetAnimalDetailRequestDto";
import { AnimalDetailModel } from "@/domain/animal/animalDetail/model/AnimalDetailModel";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/domain/Constant";

export class GetAnimalDetailMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: GetAnimalDetailResponseDto): Result<AnimalDetailModel> {
    try {
      if (dto.data) {
        const animalModel: AnimalDetailModel = {
          id: dto.data.id,
          earTag: dto.data.attributes.earTag,
          motherEarTag: dto.data.attributes.motherEarTag,
          fatherEarTag: dto.data.attributes.fatherEarTag,
          name: dto.data.attributes.name,
          animalBreedId: dto.data.attributes.animalBreed.data.id,
          animalBreedName: dto.data.attributes.animalBreed.data.attributes.name,
          barnyard: dto.data.attributes.barnyard.data.attributes.name,
          gender: dto.data.attributes.gender,
          acquisitionDate: new Date(dto.data.attributes.acquisitionDate),
          acquisitionPrice: dto.data.attributes.acquisitionPrice,
          acquisitionReason: dto.data.attributes.acquisitionReason,
          liveWeight: dto.data.attributes.liveWeight,
          color: dto.data.attributes.color,
          status: dto.data.attributes.status,
          birthDate: new Date(dto.data.attributes.birthDate),
          carcassWeight: dto.data.attributes.carcassWeight,
          documents: dto.data.attributes.documents,
          leaveDate: dto.data.attributes.leaveDate,
          leavePrice: dto.data.attributes.leavePrice,
          leaveReason: dto.data.attributes.leaveReason,
        };

        if (dto.data.attributes.animalTags.data) {
          animalModel.animalTags = dto.data.attributes.animalTags.data.map(
            (tag) => tag.id
          );

        }

        if (dto.data.attributes.profilePhoto.data) {
          animalModel.profilePhotoUrl =
            dto.data.attributes.profilePhoto.data.attributes.url;
        } else {
          if (animalModel.gender == "DISI") {
            animalModel.profilePhotoUrl = "/media/animal/female.svg";
          } else if (animalModel.gender == "ERKEK") {
            animalModel.profilePhotoUrl = "/media/animal/male.svg";
          } else {
            animalModel.profilePhotoUrl = "/media/animal/animal.png";
          }
        }

        return Result.ok(animalModel);
      } else {
        const animalModel: AnimalDetailModel = {
          id: undefined,
          earTag: undefined,
          motherEarTag: undefined,
          fatherEarTag: undefined,
          name: undefined,
          barnyard: undefined,
          gender: undefined,
          acquisitionDate: new Date(),
          acquisitionPrice: undefined,
          acquisitionReason: undefined,
          liveWeight: undefined,
          color: undefined,
          status: undefined,
          birthDate: new Date(),
          carcassWeight: undefined,
          documents: undefined,
          leaveDate: undefined,
          leavePrice: undefined,
          leaveReason: undefined,
          animalTags: undefined,
        };
        return Result.ok(animalModel);
      }
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_GET))
      );
    }
  }

  mapToDTO(domain: AnimalDetailModel): Result<GetAnimalDetailRequestDto> {
    try {
      const animalRequest: GetAnimalDetailRequestDto = {
        id: domain.id,
      };

      return Result.ok(animalRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_GET))
      );
    }
  }
}
