export default {
  project: "Maldavar",
  year: "2024",
  version: "1.32",
  dashboard: "Anasayfa",
  settings: "Ayarlar",
  authentication: "Kimlik Doğrulama",
  signIn: "Giriş Yap",
  signInTitle: "Maldavar Giriş Ekranı",
  privacy: "Gizlilik Sözleşmesi",
  signUp: "Kayıt Ol",
  passwordReset: "Şifre Sıfırla",
  error404: "Hata 404",
  error500: "Hata 500",
  createAccount: "Hesap Oluştur",
  signin: "Giriş Yap",
  adminPassword: "Hoş geldiniz, lütfen giriş bilgilerinizi giriniz.",
  email: "E-posta",
  password: "Şifre",
  forgotPassword: "Şifremi unuttum",
  login: "Giriş Yap",
  pleaseWait: "Lütfen bekleyin...",
  animal: "Hayvan",
  barnyards: "Padoklar",
  allAnimals: "Hayvanlar",
  createAnimal: "Hayvan Ekle",
  weight: "Canlı Kilo",
  animalTags: "Hayvan Etiketleri",
  vaccine: "Aşı",
  vaccineList: "Aşı Listesi",
  food: "Yem",
  treatment: "Tedavi",
  overheads: "Genel Gider",
  current: "Cari",
  sales: "Satış",
  order: "Sipariş",
  shipment: "Sevkiyat",
  bill: "Fatura",
  waybill: "İrsaliye",
  buy: "Satın Alma",
  buyAnimal: "Hayvan Satın Al",
  buyFeed: "Yem Satın Al",
  supplies: "Sarf Malzemeleri",
  machine: "Makine/Teçhizat",
  basicKnowledge: "Temel Bilgiler",
  vehicles: "Araçlar",
  breeds: "Cinsler",
  animalTypes: "Hayvan Tipleri",
  vaccines: "Aşı Bilgileri",
  diseases: "Hastalıklar",
  foods: "Yem Türleri",
  treatments: "Tedaviler",
  tags: "Etiketler",
  staff: "Personel",
  staffList: "Personel Listesi",
  authorization: "Yetkilendirme",
  common: {
    layout: {
      about: "Hakkımızda",
      contact: "İletişim",
      contactUs: "Bizimle İletişime Geç",
      privacy: "Gizlilik Sözleşmesi"
    },
    button: {
      info: "Bilgi",
      edit: "Düzenle",
      detail: "Detay",
      save: "Kaydet",
      cancel: "İptal",
      return: "Geri Dön",
      add: "Ekle",
      update: "Güncelle",
      send: "Gönder",
      loader: "Lütfen bekleyiniz...",
      yes: "Evet",
      no: "Hayır",
    },
    menu: {
      myProfile: "Profilim",
      changeTenant: "Çiftlik Değiştir",
      language: "Dil",
      accountSettings: "Hesap Ayarları",
      signOut: "Çıkış",
    },
    table: {
      process: "İşlem",
      notProcess: "Varsayılan olduğu için işlem yapılamaz",
      pagination: {
        desc: " sonuç arasından ",
        desc1: "sonuç gösteriliyor.",
        desc2: "sayfa başı öğe sayısı",
      },
    },
  },
  swalMessages: {
    CONFIRM_BUTTON_TEXT: "Tamam",
    API_ERROR_MESSAGE: "Teknik hata.",
    ERROR_CODE: " Hata kodu: ",
    UNKNOWN_ERROR_MESSAGE: "Bilinmeyen bir hata oluştu.",
    ARE_YOU_SURE: "Emin misiniz?",
    SURE_DELETE_ANIMAL: "Bu hayvanı silmek istediğinize emin misiniz?",
    SURE_TREAT_ANIMAL: "İyileşti olarak güncellemek istediğinize emin misiniz?",
    
    SUCCESS: {
      SUCCESS: "İşlem başarılı!",
      LOGIN_SUCCESS_MESSAGE: "Giriş başarılı.",
      SUCCESS_FORGOT_PASSWORD: "Şifre sıfırlama maili başarıyla iletildi.",
      SUCCESS_RESET_PASSWORD: "Şifre başarıyla değiştirildi.",

      SUCCESS_CREATE_ANIMAL: "Tebrikler, hayvan başarıyla eklendi.",
      SUCCESS_UPDATE_ANIMAL: "Tebrikler, hayvan başarıyla güncellendi.",
      SUCCESS_DELETE_ANIMAL: "Tebrikler, hayvan başarıyla silindi.",
      SUCCESS_TRANSFER_ANIMAL: "Tebrikler, hayvan başarıyla transfer edildi.",

      SUCCESS_UPDATE_ANIMAL_WEIGHT: "Tebrikler, hayvan tartımı güncellendi.",
      SUCCESS_ADD_ANIMAL_VACCINE: "Tebrikler, hayvan aşısı başarıyla yapıldı.",
      SUCCESS_ADD_ANIMAL_TAG:
        "Tebrikler, hayvan etiketi başarıyla oluşturuldu.",
      SUCCESS_UPDATE_ANIMAL_TAG:
        "Tebrikler, hayvan etiketi başarıyla güncellendi.",
      SUCCESS_REMOVE_ANIMAL_TAG: "Tebrikler, hayvan etiketi başarıyla silindi.",
      SUCCESS_BUY_FOOD: "Tebrikler, yem satın alımı başarıyla eklendi!",
      SUCCESS_ADD_BREED: "Tebrikler, hayvan cinsi başarıyla eklendi!",
      SUCCESS_UPDATE_BREED: "Tebrikler, hayvan cinsi başarıyla güncellendi!",
      SUCCESS_REMOVE_BREED: "Tebrikler, hayvan cinsi başarıyla silindi!",
      SUCCESS_UPDATE_PRICE:
        "Tebrikler, hayvan cins fiyatı başarıyla güncellendi!",
      SUCCESS_ADD_ANIMAL_FEED: "Tebrikler, hayvan yem kaydı başarıyla eklendi!",
      SUCCESS_ADD_ANIMAL_DISEASE:
        "Tebrikler, hayvan hastalık kaydı başarıyla eklendi!",
      SUCCESS_UPDATE_ANIMAL_DISEASE:
        "Tebrikler, hayvan hastalığı başarıyla güncellendi!",
    },

    ERROR: {
      ERROR: "İşlem başarısız!",
      SESSION_TIME_OUT:
        "Oturum süresi doldu. Lütfen kullanıcı bilgilerinizle tekrar giriş yapınız.",
      LOGIN_ERROR_MESSAGE: "Giriş bilgileriniz yanlış, lütfen tekrar deneyin.",
      ERROR_FORGOT_PASSWORD: "Teknik hata. Şifre sıfırlama maili iletilemedi.",
      ERROR_RESET_PASSWORD:
        "Teknik hata. Şifre değiştirme işlemi başarısız. Lütfen tekrar deneyin.",
      ERROR_CHANGE_TENANT_USER:
        "Teknik hata! Çiftlik değiştirme işlemi başarısız.",
      ERROR_ABOUT_USER: "Teknik hata! Kişisel bilgi yüklemesi başarısız.",

      ERROR_CREATE_ANIMAL: "Teknik hata! Hayvan eklemesi başarısız.",
      ERROR_UPDATE_ANIMAL: "Teknik hata! Hayvan güncellemesi başarısız.",
      ERROR_DELETE_ANIMAL: "Teknik hata! Hayvan silme işlemi başarısız.",
      ERROR_TRANSFER_ANIMAL: "Teknik hata! Hayvan transfer işlemi başarısız.",

      ERROR_UPDATE_ANIMAL_WEIGHT: "Teknik hata! Tartım güncellemesi başarısız.",
      ERROR_ADD_ANIMAL_VACCINE: "Teknik hata! Hayvan aşısı başarısız.",
      ERROR_ADD_ANIMAL_TAG: "Teknik hata! Hayvan etiketi eklemesi başarısız.",
      ERROR_UPDATE_ANIMAL_TAG:
        "Teknik hata! Hayvan etiketi güncellemesi başarısız.",
      ERROR_REMOVE_ANIMAL_TAG:
        "Teknik hata! Hayvan etiketi silme işlemi başarısız.",
      ERROR_BUY_FOOD: "Teknik hata! Yem satın alım kaydı başarısız.",
      ERROR_ADD_BREED: "Teknik hata! Hayvan cinsi ekleme işlemi başarısız.",
      ERROR_UPDATE_BREED:
        "Teknik hata! Hayvan cinsi güncelleme işlemi başarısız.",
      ERROR_REMOVE_BREED:
        "Teknik hata! Hayvan cinsi kaldırma işlemi başarısız.",
      ERROR_UPDATE_PRICE:
        "Teknik hata! Hayvan cins fiyatı güncelleme işlemi başarısız.",
      ERROR_ADD_ANIMAL_FEED: "Teknik hata! Hayvan yemleme işlemi başarısız.",
      ERROR_ADD_ANIMAL_DISEASE:
        "Teknik hata! Hayvan hastalığı ekleme işlemi başarısız.",
      ERROR_UPDATE_ANIMAL_DISEASE:
        "Teknik hata! Hayvan hastalığı güncelleme işlemi başarısız.",

      ERROR_ANIMAL_LIST: "Teknik hata! Hayvan listelemesi başarısız.",
      ERROR_ANIMAL_COUNT: "Teknik hata! Hayvan sayısı bilgisine ulaşılamadı..",
      ERROR_MOST_VALUABLE_ANIMAL:
        "Teknik hata! En değerli hayvan bilgisine ulaşılamadı..",
      ERROR_ANIMAL_WEIGHT_AVERAGE:
        "Teknik hata! Hayvan kilo artış ortalaması bilgisine ulaşılamadı..",
      ERROR_TOTAL_COST: "Teknik hata! Toplam tutar bilgisine ulaşılamadı..",
      ERROR_TOTAL_VALUE_ANIMALS:
        "Teknik hata! Hayvan toplam değer bilgisine ulaşılamadı..",
      ERROR_ANIMAL_TAG_LIST:
        "Teknik hata! Hayvan etiket listelemesi başarısız.",
      ERROR_ANIMAL_TYPE_LIST: "Teknik hata! Hayvan tipi listelemesi başarısız.",
      ERROR_ANIMAL_WEIGHT_LIST:
        "Teknik hata! Hayvan tartım listelemesi başarısız.",
      ERROR_GET_ANIMAL_DETAIL: "Teknik hata! Hayvan bilgilerine ulaşılamadı..",
      ERROR_BARNYARD_LIST: "Teknik hata! Padok listelemesi başarısız.",
      ERROR_BARNYARD_DETAIL: "Teknik hata! Padok detayına ulaşılamadı.",
      ERROR_BREED_LIST: "Teknik hata! Hayvan türü listelemesi başarısız.",
      ERROR_FOOD_LIST: "Teknik hata! Yem listelemesi başarısız.",
      ERROR_VACCINE_LIST: "Teknik hata! Aşı listelemesi başarısız.",
      ERROR_ANIMAL_VACCINE_LIST:
        "Teknik hata! Hayvan aşıları listelemesi başarısız.",
      ERROR_DISEASE_LIST: "Teknik hata! Hastalık türü listelemesi başarısız.",
      ERROR_ANIMAL_DISEASE_LIST:
        "Teknik hata! Hayvan hastalıkları listelemesi başarısız.",
      ERROR_ANIMAL_FEED_LIST: "Teknik hata! Hayvan yem listelemesi başarısız.",
    },
  },
  customErrorMessages: {
    useCase: {
      error: "Usecase error.",
    },
    mapper: {
      error: "Mapper error.",
    },
    controller: {
      error: "Controller error.",
    },
    repository: {
      error: "Repository error.",
    },
    aggregateRoot: {
      error: "Aggregate root error.",
    },
    domainEvent: {
      error: "Domain Event error.",
    },
    email: {
      required: "Lütfen e-posta adresinizi giriniz.",
      invalid: "Geçerli bir e-posta adresi giriniz.",
    },
    password: {
      required: "Lütfen şifrenizi giriniz.",
      min: "Şifre en az 6 karakter olmalıdır.",
      match: "Şifreleriniz birbiriyle aynı olmalıdır.",
    },
    identity: {
      required: "Lütfen tc kimlik numaranızı giriniz.",
      length: "Tc kimlik numarası 11 haneden oluşmalıdır.",
    },
    credential: {
      required: "Lütfen e-posta adresinizi ve şifrenizi giriniz.",
      credential: "Geçersiz bir e-posta adresi veya şifre girdiniz.",
    },
    required:"Lütfen bu alanı boş bırakmayınız.",
  },
  validators_error: {
    required: "Lütfen boş bırakmayınız.",
    typeError: "Lütfen uygun tipte veri giriniz.",
    email: "Geçerli bir e-posta adresi giriniz.",
    minlength: " karakterden az olamaz.",
    maxlength: " karakterden fazla olamaz.",
    zeroValue: "Değer 0'dan az olamaz.",
  },
  menuPages: {
    name: "Sayfalar",
    dashboard: {
      pageName: "Anasayfa",
      statistics: {
        header: "Hızlı Menü",
        barnyardList: "Padok Listesi",
        animalList: "Hayvan Listesi",
        addAnimal: "Yeni Hayvan Ekle",
        accounting: "Muhasebe",
        vaccines: "Aşı Türleri",
        animalTag: "Hayvan Etiketleri",
        breeds: "Hayvan Cinsleri",
      },
      tenantInfo: {
        header: "Çiftlik Bilgileri",
        desc: "Çiftlik ile alakalı detaylı bilgiler...",
        tenant: "Çiftlik",
        barnyardCount: "Padok Sayısı",
        animalCount: "Hayvan Sayısı",
        occupancy: "Doluluk",
        totalAnimalValue: "Toplam Hayvan Değeri",
        totalAmount: "Toplam Değer",
        unit: "Adet",
        kilo: "Kilo",
      },
      animalWeight: {
        header: "Hayvan Kilo Artışı",
        desc: "Hayvan kilo artışları istatistikleri..",
      },
      mostValuableAnimal: {
        header: "En Değerli Hayvan",
        desc: "Çiftlikteki en değerli hayvanın detaylı bilgileri...",
        animal: {
          name: "Hayvan Adı",
          eartag: "Küpe Numarası",
          gender: "Cinsiyet",
          weight: "Kilo",
          color: "Renk",
          value: "Değer",
        },
      },
      accounting: {
        header: "Muhasebe",
        desc: "Muhasebe bilgileri...",
      },
    },
    error404: {
      nothing: "Burada hiçbir şey yok gibi görünüyor",
      return: "Anasayfaya dön",
    },
    error500: {
      error: "Sistem Hatası",
      description: "Bir şeyler ters gitti! Lütfen daha sonra tekrar deneyin!",
      return: "Anasayfaya dön",
    },
    animalList: {
      pageName: "Hayvanlar",
      animalList: "Hayvan listesi",
      animal: "Hayvan",
      earTag: "Küpe numarası",
      gender: "Cinsiyet",
      color: "Renk",
      barnyard: "Padok",
      lastWeight: "Son kilo",
      status: "Durum",
      pregnant: "Hamile",
      search: "Hayvan ara",
      tag: "Etiket",
      zeroCount: "Hayvan bulunamadı.",
      seeAll: "Tümünü gör",
    },
    createAnimal: {
      pageName: "Hayvan Ekle",
      photo: "Görsel",
      photoDesc: "İzin verilen dosya türleri: png, jpg, jpeg..",
      race: "Irk",
      selectRace: "Irk seçiniz...",
      gender: "Cinsiyet",
      selectGender: "Cinsiyet seçiniz...",
      earringNumber: "Küpe numarası",
      nickname: "Takma adı",
      lastWeight: "Son kilo",
      motherEarNumber: "Anne küpe numarası",
      fatherEarNumber: "Baba küpe numarası",
      price: "Alış Fiyatı",
      companyInfo: "İşletme bilgileri",
      companyFirstDate: "İşletmeye giriş tarihi",
      companyEnterDesc: "İşletmeye giriş sebebi",
      healthInfo: "Sağlık bilgileri",
      barnyard: "Padok",
      selectBarnyard: "Padok seçiniz...",
      status: "Durum",
      selectStatus: "Durum seçiniz...",
      tags: "Etiketler",
      tagsValid: "En fazla 3 etiket seçilebilir.",
      selectTags: "Seçiniz...",
      color: "Renk",
      birthDate: "Doğum Tarihi",
    },
    weight: {
      pageName: "Ağırlıklar",
      animals: "Hayvanlar",
      myWeight: "Tartım",
      animal: "Hayvan",
      barnyard: "Padok",
      lastWeight: "Son kilo",
      liveWeight: "Canlı kilo",
      status: "Durum",
      description: "Açıklama",
      addWeight: "Tartım Ekle",
    },
    addWeight: {
      pageName: "Tartım Ekle",
      pageDescription:
        "Hayvana tartı girişi eklemek için lütfen gerekli bilgileri giriniz. ",
      liveWeight: "Canlı kilo",
      enterWeight: "Kilo giriniz...",
      date: "Tarih",
      enterDate: "Tarih seçiniz",
      description: "Açıklama",
      enterDescription: "Açıklama yazınız...",
    },
    myWeight: {
      pageName: "Tartım",
      animals: "Hayvanlar",
      myWeight: "Tartım",
      animal: "Hayvan",
      barnyard: "Padok",
      date: "Tarih",
      lastWeight: "Son kilo",
      liveWeight: "Canlı kilo",
      weightResult: "Tartım Sonucu",
      zeroCount: "Tartım bilgisi bulunamadı.",
    },
    animalWeight: {
      pageName: "Hayvan Tartımları",
      graphic: "Grafik",
      list: "Liste",
    },
    vaccineSelect: {
      pageName: "Aşı",
      animalSelection: "Hayvan Seçimi",
      vaccination: "Aşılama",
      animal: "Hayvan",
      barnyard: "Padok",
    },
    vaccination: {
      pageName: "Aşı",
      vaccinationList: "Aşı Listesi",
      vaccinationInfos: "Aşı Bilgileri",
      animalSelection: "Hayvan Seçimi",
      vaccination: "Aşılama",
      animal: "Hayvan",
      id: "No",
      barnyard: "Padok",
      vaccine: "Aşı",
      mililiter: "Ml",
      price: "Fiyat",
      date: "Tarih",
      description: "Açıklama",
      active: "Aktif",
      status: "Durum",
      createVaccination: "Aşı Oluştur",
      addVaccination: "Aşı Yap",
      addAllVaccination: "Tümüne Aşı Yap",
      zeroCount: "Aşı bilgisi bulunamadı.",
      past: " gün geçti",
      future: " gün kaldı",
    },
    vaccineList: {
      pageName: "Aşı Listesi",
      id: "No",
      barnyard: "Padok",
      vaccine: "Aşı",
      animalName: "Hayvan Adı",
      earTag: "Küpe Numarası",
      date: "Tarih",
      status: "Durum",
      search: "Aşı Ara",
      zeroCount: "Aşı bulunamadı.",
    },
    vaccinate: {
      pageName: "Aşı Yap",
      pageDescription: "Aşı kayıtlarını eksiksiz giriniz.",
      vaccine: "Aşı",
      selectVaccine: "Aşı seçiniz...",
      amount: "Miktar (ml)",
      totalAmount: "Toplam Miktar (ml)",
      enterAmount: "Miktar giriniz...",
      date: "Tarih",
      enterDate: "Tarih seçiniz",
      description: "Açıklama",
      enterDescription: "Açıklama yazınız...",
    },
    addPrice: {
      pageName: "Fiyatı Güncelle",
      pageDescription: "Lütfen fiyat bilgisini giriniz.",
      breed: "Hayvan Cinsi",
      selectBreed: "Cins seçiniz...",
      price: "Fiyat",
      enterPrice: "Fiyat giriniz...",
      currency: "Birim",
      enterCurrency: "Birim giriniz...",
      description: "Açıklama",
      enterDescription: "Açıklama yazınız...",
    },
    food: {
      pageName: "Yem",
      feedAnimal: "Yem Ver",
      selectFood: "Yem seçiniz...",
      foodList: "Yem Listesi",
      pageDescription:
        "Vermek istediğiniz yem türünü ve gerekli diğer bilgileri giriniz.",
      barnyard: "Padok",
      selectBarnyard: "Padok seçiniz...",
      numberOfAnimals: "Beslenen Hayvan Sayısı",
      foodName: "Yem Adı",
      date: "Tarih",
      enterDate: "Tarih seçiniz...",
      amount: "Miktar (kg)",
      totalAmount: "Toplam Miktar (kg)",
      unitType: "Birim",
      enterAmount: "Miktar giriniz...",
      description: "Açıklama",
      enterDescription: "Açıklama yazınız...",
      button: {
        prepareFood: "Yem Karışımı Hazırla",
        addFood: "Yem Oluştur",
        feedAnimal: "Yem Ver",
        feedAllAnimal: "Tümüne Yem Ver",
      },
      zeroCount: "Yem kaydı bulunamadı.",
    },
    animalFeeds:{
      pageName: "Yem Listesi",
      foodName: "Yem Adı",
      productDescription: "Ürün Açıklaması",
      amount: "Miktar",
      description: "Açıklama",
      completedDate: "Tarih",
      zeroCount: "Yem kaydı bulunamadı.",
    },
    barnyards: {
      pageName: "Padoklar",
      barnyard: "Padok",
      withoutBarnyard: "Padokta Olmayanlar",
      outBarnyard: "Padok Dışı",
      show: "Göster",
      zeroBarnyard: "Padok bulunamadı.",
    },
    barnyardPanel: {
      pageName: "Padok Paneli",
      tabMenu: {
        padokInfo: "Padok Bilgileri",
        animals: "Hayvanlar",
        vaccination: "Aşılama",
        feed: "Yem",
        weight: "Tartım",
        treatment: "Tedavi",
        overheads: "Genel gider",
      },
      barnyardName: "Padok Adı",
      capacity: "Kapasite",
      count: "Hayvan Sayısı",
      barnyardLocation: "Padok Konumu",
      companyName: "Şirket Adı",
    },
    animalInfo: {
      pageName: "Hayvan Bilgileri",
      info: "Profil",
      edit: "Düzenle",
      tabMenu: {
        weight: "Tartımlar",
        vaccination: "Aşılama",
        feed: "Yem",
        treatment: "Tedavi",
        overheads: "Masraflar",
        diseases: "Hastalıklar",
      },
      photo: "Görsel",
      photoDesc: "İzin verilen dosya türleri: png, jpg, jpeg..",
      race: "Irk",
      selectRace: "Irk seçiniz...",
      gender: "Cinsiyet",
      selectGender: "Cinsiyet seçiniz...",
      earringNumber: "Küpe numarası",
      nickname: "Takma adı",
      lastWeight: "Son kilo",
      motherEarNumber: "Anne küpe numarası",
      fatherEarNumber: "Baba küpe numarası",
      price: "Fiyatı",
      companyInfo: "İşletme bilgileri",
      companyFirstDate: "İşletmeye giriş tarihi",
      companyEnterDesc: "İşletmeye giriş sebebi",
      healthInfo: "Sağlık bilgileri",
      vaccinations: "Aşılar",
      diseases: "Hastalıklar",
      barnyard: "Padok",
      selectBarnyard: "Padok seçiniz...",
      status: "Durum",
      selectStatus: "Durum seçiniz...",
      color: "Renk",
      description: "Açıklama",
    },
    breed: {
      pageName: "Hayvan Cinsleri",
      name: "Cins Adı",
      description: "Açıklama",
      animalType: "Hayvan Tipi",
      isDefault: "Varsayılan",
      price: "Fiyat",
      button: {
        addBreed: "Cins Ekle",
      },
      zeroCount: "Hayvan cinsi bulunamadı.",
    },
    disease: {
      pageName: "Hastalıklar",
      diseaseList: "Hastalık Listesi",
      name: "Hastalık Adı",
      description: "Açıklama",
      createDate: "Oluşturulma Tarihi",
      updateDate: "Değiştirilme Tarihi",
      button: {
        addDisease: "Hastalık Ekle",
      },
      zeroCount: "Hastalık kaydı bulunamadı.",
    },
    addDisease: {
      pageName: "Hastalık Ekle",
      pageDescription:
        "Hayvana hastalık kaydı eklemek için lütfen gerekli bilgileri giriniz.",
      disease: "Hastalık",
      selectDisease: "Hastalık seçiniz...",
      startDate: "Başlangıç Tarihi",
      enterStartDate: "Tarih seçiniz...",
      endDate: "Bitiş Tarihi",
      enterEndDate: "Tarih seçiniz...",
      end: "İyileşti",
    },
    buyFeed: {
      pageName: "Yem Satın Al",
      feed: "Yem Adı",
      description: "Açıklama",
      grammage: "Kilo",
      unitPrice: "Birim miktar",
      quantity: "Miktar (kg)",
      purchaseDate: "Tarih",
      button: {
        buy: "Satın Al",
      },
    },
    animalTags: {
      pageName: "Hayvan Etiketleri",
      id: "Id",
      shortCode: "Kodu",
      name: "Adı",
      description: "Açıklama",
      isDefault: "Varsayılan",
      button: {
        remove: "Kaldır",
        add: "Hayvan Etiketi Oluştur",
      },
      zeroCount: "Hayvan etiketi bulunamadı.",
    },
    animalTypes: {
      pageName: "Hayvan Tipleri",
      id: "Id",
      shortCode: "Kodu",
      name: "Adı",
      description: "Açıklama",
      button: {
        remove: "Kaldır",
        add: "Hayvan Tipi Oluştur",
      },
      zeroCount: "Hayvan etiketi bulunamadı.",
    },
    addAnimalTag: {
      pageName: "Hayvan Etiketi Ekle",
      pageDescription:
        "Hayvan etiketi eklemek için lütfen aşağıdaki bilgileri giriniz. ",
      animalTag: "Etiket Adı",
      enterAnimalTag: "Hayvan etiketini giriniz...",
      description: "Açıklama",
      enterDescription: "Açıklama yazınız...",
    },
    addBreed: {
      pageName: "Hayvan Cinsi Ekle",
      updateBreed: "Hayvan Cinsi Güncelle",
      pageDescription:
        "Hayvan cinsi eklemek için lütfen aşağıdaki bilgileri giriniz.",
      breedName: "Cins Adı",
      enterBreedName: "Hayvan cinsi adını giriniz...",
      animalType: "Hayvan Tipi",
      selectAnimalType: "Hayvan tipi seçiniz...",
      description: "Açıklama",
      enterDescription: "Açıklama yazınız...",
    },
    animalTransfer: {
      pageName: "Hayvan Padok Transferi",
      pageDescription:
        "Hayvanın transfer olmasını istediğiniz padoğu ve gerekli bilgileri eksiksiz giriniz.",
      transfer: "Transfer",
        animal: "Hayvan",
      selectAnimal: "Hayvanı seçiniz...",
      barnyard: "Padok",
      selectBarnyard: "Padok seçiniz...",
      date: "Transfer Tarihi",
      selectDate: "Tarih seçiniz...",
      description: "Açıklama",
      enterDescription: "Açıklama yazınız...",
    },
    forgotPassword: {
      pageName: "Şifremi Unuttum",
      pageDescription: "Lütfen e-posta adresinizi giriniz.",
      email: "E-posta",
    },
    resetPassword: {
      pageName: "Şifre Sıfırla",
      pageDescription: "Lütfen yeni şifrenizi giriniz.",
      newPassword: "Yeni Şifre",
      newPasswordAgain: "Yeni Şifre Tekrar",
    },
  },
};
