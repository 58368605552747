import { IDiseaseListUseCase } from "@/domain/disease/diseaseList/usecase/IDiseaseListUseCase";
import { DiseaseListRepository } from "../repository/DiseaseListRepository";
import { DiseaseListModel } from "@/domain/disease/diseaseList/model/DiseaseListModel";
import { Result } from "@/core/utility/ResultType";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/domain/Constant";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { DiseaseListMapper } from "../mapper/DiseaseListMapper";

export class DiseaseListUseCase implements IDiseaseListUseCase {
  diseaseListRepository: DiseaseListRepository;
  diseaseListMapper = new DiseaseListMapper();

  constructor(_diseaseListRepository: DiseaseListRepository) {
    this.diseaseListRepository = _diseaseListRepository;
  }

  async execute(model: DiseaseListModel): Promise<Result<DiseaseListModel>> {
    try {
      const dto = this.diseaseListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<DiseaseListModel>(dto.getError());
      } else {
        const response = await this.diseaseListRepository.diseaseList(dto);

        if (response.isSuccess) {
          const domain = this.diseaseListMapper.mapToDomain(response.getValue());
          return Result.ok<DiseaseListModel>(domain.getValue());
        } else {
          return Result.fail<DiseaseListModel>(response.getError())
        }
      }
    } catch (error) {
      return Result.fail<DiseaseListModel>(
        new UseCaseException(new UnknownCause(USECASE.DISEASE_LIST))
      );
    }
  }
}
