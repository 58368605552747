import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalVaccineListService } from "@/domain/animal/animalVaccine/animalVaccineList/service/IAnimalVaccineListService";
import { AnimalVaccineItemResponseDto } from "@/domain/animal/animalVaccine/animalVaccineList/dto/AnimalVaccineItemResponseDto";
import { AnimalVaccineListRequestDto } from "@/domain/animal/animalVaccine/animalVaccineList/dto/AnimalVaccineListRequestDto";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class AnimalVaccineListService
  implements IAnimalVaccineListService
{
  async getList(
    request: AnimalVaccineListRequestDto
  ): Promise<Result<AnimalVaccineItemResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.ANIMALS + request.id + API_ROUTE.VACCINE_LIST)
        .then((response) => {
          return Result.ok<AnimalVaccineItemResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<AnimalVaccineItemResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_ANIMAL_VACCINE_LIST)))
          );
        });
    } catch (error) {
      return Result.fail<AnimalVaccineItemResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_VACCINE_LIST))
      );
    }
  }
}
