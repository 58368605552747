import { createAnimalController } from "@/presentation/controllers/AnimalController";
import { createBreedController } from "@/presentation/controllers/BreedController";
import { createDiseaseController } from "@/presentation/controllers/DiseaseController";
import { createUserController } from "@/presentation/controllers/UserController";
import { createBarnyardController } from "@/presentation/controllers/BarnyardController";
import { createVaccinationController } from "@/presentation/controllers/VaccinationController";
import { createDashboardController } from "@/presentation/controllers/DashboardController";
import { createAnimalTagController } from "@/presentation/controllers/AnimalTagController";
import { createProductController } from "@/presentation/controllers/ProductController";
import { createFeedController } from "@/presentation/controllers/FeedController";

export default {
  state: {
    userController: null,
    animalController: null,
    vaccinationController: null,
    diseaseController: null,
    breedController: null,
    barnyardController: null,
    dashboardController: null,
    animalTagController: null,
    productController: null,
    feedController: null,
  },
  mutations: {
    setController(state, { name, controller }) {
      state[name] = controller;
    },
  },
  actions: {
    initializeControllers({ commit }) {
      const controllers = [
        { name: "userController", controller: createUserController() },
        { name: "animalController", controller: createAnimalController() },
        { name: "vaccinationController", controller: createVaccinationController() },
        { name: "diseaseController", controller: createDiseaseController() },
        { name: "breedController", controller: createBreedController() },
        { name: "barnyardController", controller: createBarnyardController() },
        { name: "dashboardController", controller: createDashboardController() },
        { name: "animalTagController", controller: createAnimalTagController() },
        { name: "productController", controller: createProductController() },
        { name: "feedController", controller: createFeedController() },
      ];

      controllers.forEach(({ name, controller }) => {
        commit("setController", { name, controller });
      });
    },
  },
};