import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import ResetPasswordService from "../service/ResetPasswordService";
import { IResetPasswordRepository } from "@/domain/authentication/resetPassword/repository/IResetPasswordRepository";
import { ResetPasswordRequestDto } from "@/domain/authentication/resetPassword/dto/ResetPasswordRequestDto";
import { ResetPasswordResponseDto } from "@/domain/authentication/resetPassword/dto/ResetPasswordResponseDto";

const resetPasswordService = new ResetPasswordService();

export class ResetPasswordRepository implements IResetPasswordRepository {
  async resetPassword(
    request: ResetPasswordRequestDto
  ): Promise<Result<ResetPasswordResponseDto>> {
    try {
      return await resetPasswordService
        .reset(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<ResetPasswordResponseDto>(response.getValue());
          } else {
            return Result.fail<ResetPasswordResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<ResetPasswordResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<ResetPasswordResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.RESET_PASSWORD))
      );
    }
  }
}
