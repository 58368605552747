import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { IFeedListService } from "@/domain/feed/feedList/service/IFeedListService";
import { FeedListResponseDto } from "@/domain/feed/feedList/dto/FeedListResponseDto";
import { FeedListRequestDto } from "@/domain/feed/feedList/dto/FeedListRequestDto";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class FeedListService implements IFeedListService {
  async getList(
    request: FeedListRequestDto
  ): Promise<Result<FeedListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.FEED_LIST + request.toQueryString())
        .then((response) => {
          return Result.ok<FeedListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<FeedListResponseDto>(
            new BadRequestException(
              new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_FOOD_LIST))
            )
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.FEED_LIST))
      );
    }
  }
}
