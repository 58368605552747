import { IDomainEvent } from "@/domain/events/IDomainEvent";
import { User } from "../entity/User";
import { UniqueEntityID } from "@/domain/validation/UniqueEntityID";
import { LoginUseCase } from "@/application/authentication/userLogin/usecase/LoginUseCase";

export class LoginStarted implements IDomainEvent {
  private readonly user: User;
  private readonly loginTime: Date;
  private loginUseCase: LoginUseCase;

  constructor(user: User, loginUseCase: LoginUseCase) {
    this.user = user;
    this.loginUseCase = loginUseCase;
    this.loginTime = new Date();
  }
  dateTimeOccurred: Date = new Date();
  getAggregateId(): UniqueEntityID {
    throw new Error("Method not implemented.");
  }

  getUser(): User {
    return this.user;
  }

  getLoginTime(): Date {
    return this.loginTime;
  }
}
