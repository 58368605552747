import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalTagRemoveService from "../service/AnimalTagRemoveService";
import { IAnimalTagRemoveRepository } from "@/domain/animalTag/animalTagRemove/repository/IAnimalTagRemoveRepository";
import { AnimalTagRemoveRequestDto } from "@/domain/animalTag/animalTagRemove/dto/AnimalTagRemoveRequestDto";
import { AnimalTagRemoveResponseDto } from "@/domain/animalTag/animalTagRemove/dto/AnimalTagRemoveResponseDto";

const removeAnimalTagService = new AnimalTagRemoveService();

export class AnimalTagRemoveRepository implements IAnimalTagRemoveRepository {
  async removeAnimalTag(
    request: AnimalTagRemoveRequestDto
  ): Promise<Result<AnimalTagRemoveResponseDto>> {
    try {
      return await removeAnimalTagService
        .remove(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<AnimalTagRemoveResponseDto>(response.getValue());
          } else {
            return Result.fail<AnimalTagRemoveResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<AnimalTagRemoveResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<AnimalTagRemoveResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_TAG_REMOVE))
      );
    }
  }
}
