import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalFeedListService } from "@/domain/animal/animalFeed/animalFeedList/service/IAnimalFeedListService";
import { AnimalFeedListResponseDto } from "@/domain/animal/animalFeed/animalFeedList/dto/AnimalFeedListResponseDto";
import { AnimalFeedListRequestDto } from "@/domain/animal/animalFeed/animalFeedList/dto/AnimalFeedListRequestDto";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class AnimalFeedListService
  implements IAnimalFeedListService
{
  async getList(
    request: AnimalFeedListRequestDto,
    id: number
  ): Promise<Result<AnimalFeedListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.ANIMALS + id + API_ROUTE.ANIMAL_FEED + request.toQueryString())
        .then((response) => {
          return Result.ok<AnimalFeedListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<AnimalFeedListResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_ANIMAL_FEED_LIST)))
          );
        });
    } catch (error) {
      return Result.fail<AnimalFeedListResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_FEED_LIST))
      );
    }
  }
}
