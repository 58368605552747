import { BaseDomain } from "@/core/base/BaseDomain";
import { BaseDTO } from "@/core/base/BaseDTO";
import { Result } from "@/core/utility/ResultType";
import { IMapper } from "@/core/base/IMapper";
import { TenantUserModel } from "@/domain/tenantUser/model/TenantUserModel";
import { TenantUserResponseDto } from "@/domain/tenantUser/dto/TenantUserResponseDto";
import { TenantUserRequestDto } from "@/domain/tenantUser/dto/TenantUserRequestDto";
import { ActiveTenantUserResponse } from "@/domain/tenantUser/ActiveTenantUserResponse";

export class TenantUserMapper implements IMapper<BaseDomain, BaseDTO> {
  public mapToDomain(
    dto: TenantUserResponseDto | ActiveTenantUserResponse
  ): TenantUserModel | TenantUserModel[] {
    if (dto.data && Array.isArray(dto.data)) {
      const tenantUserModel: TenantUserModel[] = [];
      dto.data.forEach((item) => {
        const { attributes } = item;
        const { isActive, tenantRole, tenantEstate } = attributes;
        const tenantUser = new TenantUserModel(
          isActive,
          tenantRole,
          tenantEstate
        );
        tenantUserModel.push(tenantUser);
      });
      return tenantUserModel;
    } else {
      const tenantUserModel: TenantUserModel = {
        isActive: dto.data.attributes.isActive,
        tenantEstate: dto.data.attributes.tenantEstate,
        tenantRole: dto.data.attributes.tenantRole,
      };
      return tenantUserModel;
    }
  }

  public mapToDTO(domain: TenantUserModel): Result<TenantUserRequestDto> {
    return Result.ok(domain);
  }
}
