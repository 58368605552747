import { ICreateAnimalUseCase } from "@/domain/animal/animalAdd/usecase/ICreateAnimalUsecase";
import { AnimalDetailModel } from "@/domain/animal/animalDetail/model/AnimalDetailModel";
import { CreateAnimalRepository } from "../repository/CreateAnimalRepository";
import { CreateAnimalMapper } from "../mapper/CreateAnimalMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";

export class CreateAnimalUseCase implements ICreateAnimalUseCase {
  createAnimalRepository: CreateAnimalRepository;
  createAnimalMapper = new CreateAnimalMapper();

  constructor(_createAnimalRepository: CreateAnimalRepository) {
    this.createAnimalRepository = _createAnimalRepository;
  }

  async execute(model: AnimalDetailModel): Promise<Result<AnimalDetailModel>> {
    try {
      const dto = this.createAnimalMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalDetailModel>(dto.getError());
      } else {
        const response = await this.createAnimalRepository.createAnimal(dto);

        if (response.isSuccess) {
          const domain = this.createAnimalMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
            const analytics = getAnalytics();
            logEvent(analytics, "animal_create", {
              created_animal_id: domain.getValue().id,
            });
          }
          return Result.ok<AnimalDetailModel>(domain.getValue());
        } else {
          return Result.fail<AnimalDetailModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AnimalDetailModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_ADD))
      );
    }
  }
}
