import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalFeedListRequestDto } from "@/domain/animal/animalFeed/animalFeedList/dto/AnimalFeedListRequestDto";
import { AnimalFeedListResponseDto } from "@/domain/animal/animalFeed/animalFeedList/dto/AnimalFeedListResponseDto";
import { AnimalFeedListModel } from "@/domain/animal/animalFeed/animalFeedList/model/AnimalFeedListModel";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";

const paginationMapper = new PaginationMapper();

export class AnimalFeedListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AnimalFeedListResponseDto): Result<AnimalFeedListModel[]> {
    try {
      const animalFeedList: AnimalFeedListModel[] = [];

      for (const item of dto.data) {
        const animalFeedListModel: AnimalFeedListModel = {
          feed: {
            id: item.id,
            amount: item.attributes.amount,
            completedDate: item.attributes.completedDate,
            description: item.attributes.description,
            productName: item.attributes.product.data.attributes.name,
            productDesc: item.attributes.product.data.attributes.description,
            unitTypeName: item.attributes.product.data.attributes.unitType.data.attributes.name,
          },
          page: 0,
          pageSize: 0,
          pagination: dto.meta.pagination
        };
        animalFeedList.push(animalFeedListModel);
      }

      return Result.ok(animalFeedList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_FEED_LIST))
      );
    }
  }

  mapToDTO(domain: AnimalFeedListModel): Result<AnimalFeedListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const animalRequest = new AnimalFeedListRequestDto(
        pagiRequest,
      );

      return Result.ok(animalRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_FEED_LIST))
      );
    }
  }
}
