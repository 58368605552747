import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalDiseaseListRequestDto } from "@/domain/animal/animalDisease/animalDiseaseList/dto/AnimalDiseaseListRequestDto";
import { AnimalDiseaseListResponseDto } from "@/domain/animal/animalDisease/animalDiseaseList/dto/AnimalDiseaseListResponseDto";
import { AnimalDiseaseListModel } from "@/domain/animal/animalDisease/animalDiseaseList/model/AnimalDiseaseListModel";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";

const paginationMapper = new PaginationMapper();

export class AnimalDiseaseListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AnimalDiseaseListResponseDto): Result<AnimalDiseaseListModel[]> {
    try {
      const animalDiseaseList: AnimalDiseaseListModel[] = [];

      for (const item of dto.data) {
        const animalDiseaseListModel: AnimalDiseaseListModel = {
          disease:{
            id: item.id,
            startDate: item.attributes.startDate,
            endDate: item.attributes.endDate,
            diseaseName: item.attributes.disease.data.attributes.name,
            diseaseDesc: item.attributes.disease.data.attributes.description,
          },
          page: 0,
          pageSize: 0,
          pagination: dto.meta.pagination
        };
        animalDiseaseList.push(animalDiseaseListModel);
      }

      return Result.ok(animalDiseaseList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_DISEASE_LIST))
      );
    }
  }

  mapToDTO(domain: AnimalDiseaseListModel): Result<AnimalDiseaseListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const animalDiseaseListRequest = new AnimalDiseaseListRequestDto(
        pagiRequest,
        domain.disease.animalId,
      );

      return Result.ok(animalDiseaseListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_DISEASE_LIST))
      );
    }
  }
}
