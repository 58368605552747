import { BreedListModel } from "@/domain/breed/breedList/model/BreedListModel";
import { BreedListMapper } from "../mapper/BreedListMapper";
import { BreedListRepository } from "../repository/BreedListRepository";
import { IBreedListUseCase } from "@/domain/breed/breedList/usecase/IBreedListUseCase";
import { Result } from "@/core/utility/ResultType";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { USECASE } from "@/domain/Constant";

export class BreedListUseCase implements IBreedListUseCase {
  breedListRepository: BreedListRepository;
  breedListMapper = new BreedListMapper();

  constructor(_breedListRepository: BreedListRepository) {
    this.breedListRepository = _breedListRepository;
  }

  async execute(model: BreedListModel): Promise<Result<BreedListModel>> {
    try {
      const dto = this.breedListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<BreedListModel>(dto.getError());
      } else {
        const response = await this.breedListRepository.breedList(dto);

        if (response.isSuccess) {
          const domain = this.breedListMapper.mapToDomain(response.getValue());
          return Result.ok<BreedListModel>(domain.getValue());
        } else {
          return Result.fail<BreedListModel>(response.getError())
        }
      }
    } catch (error) {
      return Result.fail<BreedListModel>(
        new UseCaseException(new UnknownCause(USECASE.BREED_LIST))
      );
    }
  }
}
