import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { BreedUpdateRequestDto } from "@/domain/breed/breedUpdate/dto/BreedUpdateRequestDto";
import { BreedUpdateResponseDto } from "@/domain/breed/breedUpdate/dto/BreedUpdateResponseDto";
import { BreedUpdateModel } from "@/domain/breed/breedUpdate/model/BreedUpdateModel";

export class BreedUpdateMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: BreedUpdateResponseDto): Result<BreedUpdateModel> {
    try {
      const breedAdd: BreedUpdateModel = {
        id: dto.data.id,
        name: dto.data.attributes.name,
        description: dto.data.attributes.description,
        shortCode: dto.data.attributes.shortCode,
        animalType: dto.data.attributes.animalType,
      };

      return Result.ok(breedAdd);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.BREED_UPDATE))
      );
    }
  }

  mapToDTO(domain: BreedUpdateModel): Result<BreedUpdateRequestDto> {
    try {
      const breedRequest = new BreedUpdateRequestDto(domain.name);

      return Result.ok(breedRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.BREED_UPDATE))
      );
    }
  }
}
