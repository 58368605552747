import { AnimalVaccineAddRepository } from "../repository/AnimalVaccineAddRepository";
import { AnimalVaccineAddMapper } from "../mapper/AnimalVaccineAddMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalVaccineAddUseCase } from "@/domain/animal/animalVaccine/animalVaccineAdd/usecase/IAnimalVaccineAddUsecase";
import { AnimalVaccineAddModel } from "@/domain/animal/animalVaccine/animalVaccineAdd/model/AnimalVaccineAddModel"
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../../environment";

export class AnimalVaccineAddUsecase implements IAnimalVaccineAddUseCase {
  addAnimalVaccineRepository: AnimalVaccineAddRepository;
  addAnimalVaccineMapper = new AnimalVaccineAddMapper();

  constructor(_addAnimalVaccineRepository: AnimalVaccineAddRepository) {
    this.addAnimalVaccineRepository = _addAnimalVaccineRepository;
  }

  async execute(
    model: AnimalVaccineAddModel
  ): Promise<Result<AnimalVaccineAddModel>> {
    try {
      const dto = this.addAnimalVaccineMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalVaccineAddModel>(dto.getError());
      } else {
        const response = await this.addAnimalVaccineRepository.addAnimalVaccine(
          dto
        );

        if (response.isSuccess) {
          const domain = this.addAnimalVaccineMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
            const analytics = getAnalytics();
            if (model.vaccine) {
              logEvent(analytics, "animal_vaccine", {
                animal_id: model.animal,
                vaccine_id: model.vaccine,
              });
            } else if (model.vaccineScheduleItem) {
              logEvent(analytics, "animal_vaccine", {
                animal_id: model.animal,
                vaccineScheduleItem_id: model.vaccineScheduleItem,
              });
            }
          }

          return Result.ok<AnimalVaccineAddModel>(domain.getValue());
        } else {
          return Result.fail<AnimalVaccineAddModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AnimalVaccineAddModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_VACCINE_ADD))
      );
    }
  }
}
