import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalVaccineAddService from "../service/AnimalVaccineAddService";
import { IAnimalVaccineAddRepository } from "@/domain/animal/animalVaccine/animalVaccineAdd/repository/IAnimalVaccineAddRepository";
import { AnimalVaccineAddRequestDto } from "@/domain/animal/animalVaccine/animalVaccineAdd/dto/AnimalVaccineAddRequestDto";
import { AnimalVaccineAddResponseDto } from "@/domain/animal/animalVaccine/animalVaccineAdd/dto/AnimalVaccineAddResponseDto";

const addAnimalVaccineService = new AnimalVaccineAddService();

export class AnimalVaccineAddRepository implements IAnimalVaccineAddRepository {
  async addAnimalVaccine(
    request: AnimalVaccineAddRequestDto
  ): Promise<Result<AnimalVaccineAddResponseDto>> {
    try {
      return await addAnimalVaccineService
        .add(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<AnimalVaccineAddResponseDto>(response.getValue());
          } else {
            return Result.fail<AnimalVaccineAddResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<AnimalVaccineAddResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<AnimalVaccineAddResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_VACCINE_ADD))
      );
    }
  }
}
