import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IBarnyardListService } from "@/domain/barnyard/barnyardList/service/IBarnyardListService";
import { BarnyardListRequestDto } from "@/domain/barnyard/barnyardList/dto/BarnyardListRequestDto";
import { BarnyardListResponseDto } from "@/domain/barnyard/barnyardList/dto/BarnyardListResponseDto";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class BarnyardListService implements IBarnyardListService {
  async getList(request: BarnyardListRequestDto): Promise<Result<BarnyardListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.BARNYARDS + request.toQueryString())
        .then((response) => {
          return Result.ok<BarnyardListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<BarnyardListResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_BARNYARD_LIST)))
          );
        });
    } catch (error) {
      return Result.fail<BarnyardListResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.BARNYARD_LIST))
      );
    }
  }
}
