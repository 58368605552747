import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { BreedListRepository } from "@/application/breed/breedList/repository/BreedListRepository";
import { BreedListUseCase } from "@/application/breed/breedList/usecase/BreedListUseCase";
import { BreedListModel } from "@/domain/breed/breedList/model/BreedListModel";
import { BreedAddRepository } from "@/application/breed/breedAdd/repository/BreedAddRepository";
import { BreedRemoveRepository } from "@/application/breed/breedRemove/repository/BreedRemoveRepository";
import { BreedUpdateRepository } from "@/application/breed/breedUpdate/repository/BreedUpdateRepository";
import { BreedAddUsecase } from "@/application/breed/breedAdd/usecase/BreedAddUseCase";
import { BreedRemoveUsecase } from "@/application/breed/breedRemove/usecase/BreedRemoveUseCase";
import { BreedUpdateUsecase } from "@/application/breed/breedUpdate/usecase/BreedUpdateUseCase";
import { BreedAddModel } from "@/domain/breed/breedAdd/model/BreedAddModel";
import { BreedRemoveModel } from "@/domain/breed/breedRemove/model/BreedRemoveModel";
import { BreedUpdateModel } from "@/domain/breed/breedUpdate/model/BreedUpdateModel";
import { PriceAddRepository } from "@/application/breed/priceAdd/repository/PriceAddRepository";
import { PriceAddUsecase } from "@/application/breed/priceAdd/usecase/PriceAddUseCase";
import { PriceAddModel } from "@/domain/breed/priceAdd/model/PriceAddModel";

export function createBreedController() {
  const breedListRepository = new BreedListRepository();
  const breedAddRepository = new BreedAddRepository();
  const breedRemoveRepository = new BreedRemoveRepository();
  const breedUpdateRepository = new BreedUpdateRepository();
  const priceAddRepository = new PriceAddRepository();

  const breedListUseCase = new BreedListUseCase(breedListRepository);
  const breedAddUseCase = new BreedAddUsecase(breedAddRepository);
  const breedRemoveUseCase = new BreedRemoveUsecase(breedRemoveRepository);
  const breedUpdateUseCase = new BreedUpdateUsecase(breedUpdateRepository);
  const priceAddUsecase = new PriceAddUsecase(priceAddRepository);

  return new BreedController(
    breedAddUseCase,
    breedListUseCase,
    breedRemoveUseCase,
    breedUpdateUseCase,
    priceAddUsecase
  );
}

export class BreedController implements IController {
  constructor(
    private breedAddUseCase: BreedAddUsecase,
    private breedListUseCase: BreedListUseCase,
    private breedRemoveUseCase: BreedRemoveUsecase,
    private breedUpdateUseCase: BreedUpdateUsecase,
    private priceAddUsecase: PriceAddUsecase
  ) {}

  async breedList(
    breedListModel: BreedListModel
  ): Promise<Result<BreedListModel>> {
    const response = await this.breedListUseCase.execute(breedListModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<BreedListModel>(response.getError());
    }
  }

  async addBreed(breedAddModel: BreedAddModel): Promise<Result<BreedAddModel>> {
    const response = await this.breedAddUseCase.execute(breedAddModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<BreedAddModel>(response.getError());
    }
  }

  async removeBreed(
    breedRemoveModel: BreedRemoveModel
  ): Promise<Result<BreedRemoveModel>> {
    const response = await this.breedRemoveUseCase.execute(breedRemoveModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<BreedRemoveModel>(response.getError());
    }
  }

  async updateBreed(
    breedUpdateModel: BreedUpdateModel
  ): Promise<Result<BreedUpdateModel>> {
    const response = await this.breedUpdateUseCase.execute(breedUpdateModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<BreedUpdateModel>(response.getError());
    }
  }

  async updatePrice(
    priceAddModel: PriceAddModel
  ): Promise<Result<PriceAddModel>> {
    const response = await this.priceAddUsecase.execute(priceAddModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<PriceAddModel>(response.getError());
    }
  }
}
