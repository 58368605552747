import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { AnimalTagAddResponseDto } from "@/domain/animalTag/animalTagAdd/dto/AnimalTagAddResponseDto";
import { IAnimalTagAddService } from "@/domain/animalTag/animalTagAdd/service/IAnimalTagAddService";
import { AnimalTagAddRequestDto } from "@/domain/animalTag/animalTagAdd/dto/AnimalTagAddRequestDto";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class AnimalTagAddService implements IAnimalTagAddService {
  async add(
    request: AnimalTagAddRequestDto
  ): Promise<Result<AnimalTagAddResponseDto>> {
    try {
      return await instance
        .post(API_ROUTE.ANIMAL_TAG, request)
        .then((response) => {
          return Result.ok<AnimalTagAddResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<AnimalTagAddResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_ADD_ANIMAL_TAG)))
          );
        });
    } catch (error) {
      return Result.fail<AnimalTagAddResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_TAG_ADD))
      );
    }
  }
}
