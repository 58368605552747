import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalFeedListService from "../service/AnimalFeedListService";
import { IAnimalFeedListRepository } from "@/domain/animal/animalFeed/animalFeedList/repository/IAnimalFeedListRepository";
import { AnimalFeedListResponseDto } from "@/domain/animal/animalFeed/animalFeedList/dto/AnimalFeedListResponseDto";
import { AnimalFeedListRequestDto } from "@/domain/animal/animalFeed/animalFeedList/dto/AnimalFeedListRequestDto";

const animalFeedListService = new AnimalFeedListService();

export class AnimalFeedListRepository implements IAnimalFeedListRepository {
  async animalFeedList(
    request: AnimalFeedListRequestDto, id: number
  ): Promise<Result<AnimalFeedListResponseDto>> {
    try {
      return await animalFeedListService
        .getList(request, id)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<AnimalFeedListResponseDto>(response.getValue());
          } else {
            return Result.fail<AnimalFeedListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<AnimalFeedListResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<AnimalFeedListResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_FEED_LIST))
      );
    }
  }
}
