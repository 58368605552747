import { createI18n } from "vue-i18n";
import tr from "./tr";
import en from "./en";
import es from "./es";
import de from "./de";
import fr from "./fr";

const messages = {
  tr: tr,
  en: en,
  es: es,
  de: de,
  fr: fr,
};

const i18n = createI18n({
  legacy: false,
  locale: "tr",
  globalInjection: true,
  messages,
});

export default i18n;
