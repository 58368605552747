import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalListService from "../service/AnimalListService";
import { IAnimalListRepository } from "@/domain/animal/animalList/repository/IAnimalListRepository";
import { AnimalListResponseDto } from "@/domain/animal/animalList/dto/AnimalListResponseDto";
import { AnimalListRequestDto } from "@/domain/animal/animalList/dto/AnimalListRequestDto";

const animalListService = new AnimalListService();

export class AnimalListRepository implements IAnimalListRepository {
  async animalList(
    request: AnimalListRequestDto
  ): Promise<Result<AnimalListResponseDto>> {
    try {
      return await animalListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<AnimalListResponseDto>(response.getValue());
          } else {
            return Result.fail<AnimalListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<AnimalListResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<AnimalListResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_LIST))
      );
    }
  }
}
