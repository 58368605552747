import { LocalStorage } from "@/core/storage/LocalStorage";
import { LOCAL_STORAGE } from "@/domain/Constant";

const localStorage = new LocalStorage();

export class CryptoKeyService {
  static setKeyAndIvToStorage(key: Buffer, iv: Buffer) {
    /*
    const keyJson = JSON.stringify(Array.from(key));
    const ivJson = JSON.stringify(Array.from(iv));

    if (localStorage.getItem(LOCAL_STORAGE.ENCRYPTION_KEY) && localStorage.getItem(LOCAL_STORAGE.ENCRYPTION_IV)) {
      console.log(localStorage.getItem(LOCAL_STORAGE.ENCRYPTION_KEY))
      console.log(localStorage.getItem(LOCAL_STORAGE.ENCRYPTION_IV))
      return;
    } 
    */
    /*
    else {
      localStorage.setItem(LOCAL_STORAGE.ENCRYPTION_KEY, keyJson); 
      localStorage.setItem(LOCAL_STORAGE.ENCRYPTION_IV, ivJson);
    }
    */
  }

  static getKeyFromStorage(): Buffer | null {
    const keyJson = "[52,238,247,192,122,26,100,131,32,174,254,89,26,52,9,130,145,3,183,59,107,180,147,230,176,86,0,224,118,62,135,84]"
    return Buffer.from(JSON.parse(keyJson));
  }

  static getIvFromStorage(): Buffer {
    const ivJson = "[1,159,204,54,70,170,64,37,89,212,181,139,6,130,160,26]"
    return Buffer.from(JSON.parse(ivJson));
  }
}


/*
encryptionKey	[167,195,97,92,173,237,190,180,212,69,212,153,0,245,26,30,203,102,166,169,121,79,144,73,217,30,90,165,184,166,168,9]	
encryptionIV	[191,243,10,123,2,233,41,168,78,187,132,209,27,50,180,211]
*/

// localStorage'a kaydedemiyoruz bu sebeple keyi manuel tutmak durumundayız