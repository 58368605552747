import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { FeedListRequestDto } from "@/domain/feed/feedList/dto/FeedListRequestDto";
import { FeedListResponseDto } from "@/domain/feed/feedList/dto/FeedListResponseDto";
import { FeedListModel } from "@/domain/feed/feedList/model/FeedListModel";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";

const paginationMapper = new PaginationMapper();

export class FeedListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: FeedListResponseDto): Result<FeedListModel[]> {
    try {
      const feedList: FeedListModel[] = [];

      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const feedListModel: FeedListModel = {
            feed: {
              id: Number(item.id),
              name: item.attributes.name,
              shortCode: item.attributes.shortCode,
              description: item.attributes.description,
              unitType: item.attributes.unitType,
            },
            page: 0,
            pageSize: 0,
            pagination: dto.meta.pagination,
          };

          feedList.push(feedListModel);
        }
        return Result.ok(feedList);
      }
      return Result.ok(feedList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.FEED_LIST))
      );
    }
  }

  mapToDTO(domain: FeedListModel): Result<FeedListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const feedListRequest = new FeedListRequestDto(pagiRequest);

      return Result.ok(feedListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.FEED_LIST))
      );
    }
  }
}
