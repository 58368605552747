import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ITenantStatisticRepository } from "@/domain/dashboard/tenantStatistic/repository/ITenantStatisticRepository";
import TenantStatisticService from "../service/TenantStatisticService";
import { TenantStatisticResponseDto } from "@/domain/dashboard/tenantStatistic/dto/TenantStatisticResponseDto";

const getTenantStatisticService = new TenantStatisticService();

export class TenantStatisticRepository implements ITenantStatisticRepository {
  async getTenantStatistic(): Promise<Result<TenantStatisticResponseDto>> {
    try {
      return await getTenantStatisticService
        .get()
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<TenantStatisticResponseDto>(response.getValue());
          } else {
            return Result.fail<TenantStatisticResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_MOST_VALUABLE)));
    }
  }
}
