import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { TenantStatisticResponseDto } from "@/domain/dashboard/tenantStatistic/dto/TenantStatisticResponseDto";
import { TenantStatisticModel } from "@/domain/dashboard/tenantStatistic/model/TenantStatisticModel";

export class TenantStatisticMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: TenantStatisticResponseDto): Result<TenantStatisticModel> {
    try {
      if (dto.data) {
        const tenantStatisticModel: TenantStatisticModel = {
          barnyardCount: dto.data.attributes.barnyardCount,
          animalCount: dto.data.attributes.animalCount,
          occupancyRate: dto.data.attributes.occupancyRate,
          tenantEstateName:
            dto.data.attributes.tenantEstate.data.attributes.name,
        };

        if (dto.data.attributes.valuableAnimal.data) {
          tenantStatisticModel.valuableAnimalId =
          dto.data.attributes.valuableAnimal.data.id;
          tenantStatisticModel.valuableAnimalEarTag =
            dto.data.attributes.valuableAnimal.data.attributes.earTag;
          tenantStatisticModel.valuableAnimalName =
            dto.data.attributes.valuableAnimal.data.attributes.name;
          tenantStatisticModel.valuableAnimalLiveWeight =
            dto.data.attributes.valuableAnimal.data.attributes.liveWeight;
          tenantStatisticModel.valuableAnimalColor =
            dto.data.attributes.valuableAnimal.data.attributes.color;
          tenantStatisticModel.valuableAnimalGender =
            dto.data.attributes.valuableAnimal.data.attributes.gender;
          tenantStatisticModel.valuableAnimalValuePrice =
            dto.data.attributes.valuableAnimal.data.attributes.animalValue.data.attributes.price;
          tenantStatisticModel.valuableAnimalValueCurrency =
            dto.data.attributes.valuableAnimal.data.attributes.animalValue.data.attributes.currency;
        }

        if (dto.data.attributes.totalAnimalValue.data) {
          tenantStatisticModel.totalAnimalValuePrice =
            dto.data.attributes.totalAnimalValue.data.attributes.price;
          tenantStatisticModel.totalAnimalValueCurrency =
            dto.data.attributes.totalAnimalValue.data.attributes.currency;
        }

        return Result.ok(tenantStatisticModel);
      } else {
        const tenantStatisticModel: TenantStatisticModel = {
          barnyardCount: 0,
          animalCount: 0,
          occupancyRate: 0,
          valuableAnimalEarTag: "",
          valuableAnimalName: "",
          valuableAnimalLiveWeight: 0,
          valuableAnimalColor: "",
          valuableAnimalGender: "",
          valuableAnimalValuePrice: 0,
          valuableAnimalValueCurrency: "",
          totalAnimalValuePrice: 0,
          totalAnimalValueCurrency: "",
          tenantEstateName: "",
        };
        return Result.ok(tenantStatisticModel);
      }
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_GET))
      );
    }
  }

  mapToDTO(domain: TenantStatisticModel): Result<TenantStatisticModel> {
    return Result.ok(domain);
  }
}
