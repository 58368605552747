import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalWeightListService from "../service/AnimalWeightListService";
import { IAnimalWeightListRepository } from "@/domain/animal/animalWeight/animalWeightList/repository/IAnimalWeightListRepository";
import { AnimalWeightListResponseDto } from "@/domain/animal/animalWeight/animalWeightList/dto/AnimalWeightListResponseDto";
import { AnimalWeightListRequestDto } from "@/domain/animal/animalWeight/animalWeightList/dto/AnimalWeightListRequestDto";

const animalWeightListService = new AnimalWeightListService();

export class AnimalWeightListRepository implements IAnimalWeightListRepository {
  async animalWeightList(
    request: AnimalWeightListRequestDto, id: number
  ): Promise<Result<AnimalWeightListResponseDto>> {
    try {
      return await animalWeightListService
        .getList(request, id)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<AnimalWeightListResponseDto>(response.getValue());
          } else {
            return Result.fail<AnimalWeightListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<AnimalWeightListResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<AnimalWeightListResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_WEIGHT_LIST))
      );
    }
  }
}
