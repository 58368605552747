import { AnimalDiseaseAddRepository } from "../repository/AnimalDiseaseAddRepository";
import { AnimalDiseaseAddMapper } from "../mapper/AnimalDiseaseAddMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalDiseaseAddUseCase } from "@/domain/animal/animalDisease/animalDiseaseAdd/usecase/IAnimalDiseaseAddUsecase";
import { AnimalDiseaseAddModel } from "@/domain/animal/animalDisease/animalDiseaseAdd/model/AnimalDiseaseAddModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../../environment";

export class AnimalDiseaseAddUsecase implements IAnimalDiseaseAddUseCase {
  addAnimalDiseaseRepository: AnimalDiseaseAddRepository;
  addAnimalDiseaseMapper = new AnimalDiseaseAddMapper();

  constructor(_addAnimalDiseaseRepository: AnimalDiseaseAddRepository) {
    this.addAnimalDiseaseRepository = _addAnimalDiseaseRepository;
  }

  async execute(
    model: AnimalDiseaseAddModel
  ): Promise<Result<AnimalDiseaseAddModel>> {
    try {
      const dto = this.addAnimalDiseaseMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalDiseaseAddModel>(dto.getError());
      } else {
        const response = await this.addAnimalDiseaseRepository.addAnimalDisease(
          dto
        );

        if (response.isSuccess) {
          const domain = this.addAnimalDiseaseMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
            const analytics = getAnalytics();
            logEvent(analytics, "add_animal_disease", {
              animal_disease_id: domain.getValue().disease,
              animal_id: model.animal,
            });
          }

          return Result.ok<AnimalDiseaseAddModel>(domain.getValue());
        } else {
          return Result.fail<AnimalDiseaseAddModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AnimalDiseaseAddModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_DISEASE_ADD))
      );
    }
  }
}
