import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { VaccinationListRequestDto } from "@/domain/vaccination/vaccinationList/dto/VaccinationListRequestDto";
import { IVaccinationListService } from "@/domain/vaccination/vaccinationList/service/IVaccinationListService";
import { VaccinationListResponseDto } from "@/domain/vaccination/vaccinationList/dto/VaccinationListResponseDto";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class VaccinationListService implements IVaccinationListService {
  async getList(
    request: VaccinationListRequestDto
  ): Promise<Result<VaccinationListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.VACCINE_LIST + request.toQueryString())
        .then((response) => {
          return Result.ok<VaccinationListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<VaccinationListResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_VACCINE_LIST)))
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.VACCINATION_LIST))
      );
    }
  }
}
