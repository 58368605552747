import { BaseDTO } from "@/core/base/BaseDTO";

export class AnimalDiseaseUpdateRequestDto extends BaseDTO {
  data: {
    endDate?: Date;
  };

  constructor(endDate?: Date) {
    super();
    this.data = {
      endDate,
    };
  }
}
