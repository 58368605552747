import { AnimalVaccineMultipleAddRepository } from "../repository/AnimalVaccineMultipleAddRepository";
import { AnimalVaccineMultipleAddMapper } from "../mapper/AnimalVaccineMultipleAddMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalVaccineMultipleAddUseCase } from "@/domain/animal/animalVaccine/animalVaccineMultipleAdd/usecase/IAnimalVaccineMultipleAddUsecase";
import { AnimalVaccineMultipleAddModel } from "@/domain/animal/animalVaccine/animalVaccineMultipleAdd/model/AnimalVaccineMultipleAddModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../../environment";

export class AnimalVaccineMultipleAddUsecase
  implements IAnimalVaccineMultipleAddUseCase
{
  addAnimalVaccineRepository: AnimalVaccineMultipleAddRepository;
  addAnimalVaccineMapper = new AnimalVaccineMultipleAddMapper();

  constructor(_addAnimalVaccineRepository: AnimalVaccineMultipleAddRepository) {
    this.addAnimalVaccineRepository = _addAnimalVaccineRepository;
  }

  async execute(
    model: AnimalVaccineMultipleAddModel
  ): Promise<Result<AnimalVaccineMultipleAddModel>> {
    try {
      const dto = this.addAnimalVaccineMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalVaccineMultipleAddModel>(dto.getError());
      } else {
        const response = await this.addAnimalVaccineRepository.addAnimalVaccine(
          dto
        );

        if (response.isSuccess) {
          const domain = this.addAnimalVaccineMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
          const analytics = getAnalytics();

          if (model.barnyard) {
            logEvent(analytics, "animal_vaccine_multiple", {
              vaccine_id: model.vaccine,
              barnyard_id: model.barnyard,
            });
          } else if (model.animals) {
            logEvent(analytics, "animal_vaccine_multiple", {
              vaccine_id: model.vaccine,
              animals_id: model.animals,
            });
          }
        }

          return Result.ok<AnimalVaccineMultipleAddModel>(domain.getValue());
        } else {
          return Result.fail<AnimalVaccineMultipleAddModel>(
            response.getError()
          );
        }
      }
    } catch (error) {
      return Result.fail<AnimalVaccineMultipleAddModel>(
        new UseCaseException(
          new UnknownCause(USECASE.ANIMAL_VACCINE_MULTIPLE_ADD)
        )
      );
    }
  }
}
