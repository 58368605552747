import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IBreedUpdateService } from "@/domain/breed/breedUpdate/service/IBreedUpdateService";
import { BreedUpdateRequestDto } from "@/domain/breed/breedUpdate/dto/BreedUpdateRequestDto";
import { BreedUpdateResponseDto } from "@/domain/breed/breedUpdate/dto/BreedUpdateResponseDto";

export default class BreedUpdateService implements IBreedUpdateService {
  async update(
    request: BreedUpdateRequestDto, id?: number
  ): Promise<Result<BreedUpdateResponseDto>> {
    try {
      return await instance
        .put(API_ROUTE.BREED + id, request)
        .then((response) => {
          return Result.ok<BreedUpdateResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<BreedUpdateResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_UPDATE_BREED)))
          );
        });
    } catch (error) {
      return Result.fail<BreedUpdateResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.BREED_UPDATE))
      );
    }
  }
}
