import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { TenantStatisticRepository } from "../repository/TenantStatisticRepository";
import { TenantStatisticModel } from "@/domain/dashboard/tenantStatistic/model/TenantStatisticModel";
import { TenantStatisticMapper } from "../mapper/TenantStatisticMapper";
import { ITenantStatisticUseCase } from "@/domain/dashboard/tenantStatistic/usecase/ITenantStatisticUsecase";
import { UnknownCause } from "@/core/cause/UnknownCause";

export class TenantStatisticUsecase implements ITenantStatisticUseCase {
  getTenantStatisticRepository: TenantStatisticRepository;
  tenantStatisticMapper = new TenantStatisticMapper();

  constructor(_getTenantStatisticRepository: TenantStatisticRepository) {
    this.getTenantStatisticRepository = _getTenantStatisticRepository;
  }

  async execute(): Promise<Result<TenantStatisticModel>> {
    try {
      const response =
        await this.getTenantStatisticRepository.getTenantStatistic();

      if (response.isSuccess) {
        const domain = this.tenantStatisticMapper.mapToDomain(
          response.getValue()
        );

        return Result.ok<TenantStatisticModel>(domain.getValue());
      } else {
        return Result.fail<TenantStatisticModel>(response.getError());
      }
    } catch (error) {
      return Result.fail<TenantStatisticModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_MOST_VALUABLE))
      );
    }
  }
}
