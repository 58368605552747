import { FeedListRepository } from "../repository/FeedListRepository";
import { Result } from "@/core/utility/ResultType";
import { FeedListMapper } from "../mapper/FeedListMapper";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/domain/Constant";
import { IFeedListUseCase } from "@/domain/feed/feedList/usecase/IFeedListUsecase";
import { FeedListModel } from "@/domain/feed/feedList/model/FeedListModel";
import { UnknownCause } from "@/core/cause/UnknownCause";

export class FeedListUseCase implements IFeedListUseCase {
  feedListRepository: FeedListRepository;
  feedListMapper = new FeedListMapper();

  constructor(_feedListRepository: FeedListRepository) {
    this.feedListRepository = _feedListRepository;
  }

  async execute(model: FeedListModel): Promise<Result<FeedListModel>> {
    try {
      const dto = this.feedListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<FeedListModel>(dto.getError());
      } else {
        const response = await this.feedListRepository.feedList(dto);

        if (response.isSuccess) {
          const domain = this.feedListMapper.mapToDomain(response.getValue());
          return Result.ok<FeedListModel>(domain.getValue());
        } else {
          return Result.fail<FeedListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<FeedListModel>(
        new UseCaseException(new UnknownCause(USECASE.FEED_LIST))
      );
    }
  }
}
