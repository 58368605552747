import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { UpdateAnimalRequestDto } from "@/domain/animal/animalUpdate/dto/UpdateAnimalRequestDto";
import { UpdateAnimalResponseDto } from "@/domain/animal/animalUpdate/dto/UpdateAnimalResponseDto";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/domain/Constant";
import { UpdateAnimalModel } from "@/domain/animal/animalUpdate/model/UpdateAnimalModel";

export class UpdateAnimalMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: UpdateAnimalResponseDto): Result<UpdateAnimalModel> {
    try {
      const animalModel: UpdateAnimalModel = {
        id: Number(dto.data.id),
        earTag: dto.data.attributes.earTag,
        motherEarTag: dto.data.attributes.motherEarTag,
        fatherEarTag: dto.data.attributes.fatherEarTag,
        name: dto.data.attributes.name,
        gender: dto.data.attributes.gender,
        acquisitionDate: dto.data.attributes.acquisitionDate,
        birthDate: dto.data.attributes.birthDate,
        acquisitionPrice: dto.data.attributes.acquisitionPrice,
        acquisitionReason: dto.data.attributes.acquisitionReason,
        liveWeight: dto.data.attributes.liveWeight,
        color: dto.data.attributes.color,
        status: dto.data.attributes.status,
        leaveDate: dto.data.attributes.leaveDate,
        leavePrice: dto.data.attributes.leavePrice,
        leaveReason: dto.data.attributes.leaveReason,
        animalTags: dto.data.attributes.animalTags
      };

      if(dto.data.attributes.profilePhoto.data != null)
      {
        animalModel.profilePhotoUrl = dto.data.attributes.profilePhoto.data.attributes.url;
      }

      return Result.ok(animalModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_ADD))
      );
    }
  }

  mapToDTO(domain: UpdateAnimalModel): Result<UpdateAnimalRequestDto> {
    try {
      const animalRequest: UpdateAnimalRequestDto = {
        data: {
          id: domain.id,
          color: domain.color,
          motherEarTag: domain.motherEarTag,
          fatherEarTag: domain.fatherEarTag,
          gender: domain.gender,
          animalTags: domain.animalTags,
          status: domain.status,
        },
        profilePhoto: domain.profilePhoto
      };

      return Result.ok(animalRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_UPDATE))
      );
    }
  }
}
