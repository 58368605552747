import LoginRepository from "../repository/LoginRepository";
import { LoginMapper } from "../mapper/LoginMapper";
import { ILoginUsecase } from "@/domain/authentication/loginUser/usecase/ILoginUsecase";
import { Result } from "@/core/utility/ResultType";
import { LoginModel } from "@/domain/authentication/loginUser/model/LoginModel";
import { LoginResponseDto } from "@/domain/authentication/loginUser/dto/LoginResponseDto";
import { UserManager } from "@/infrastructure/helper/UserManager";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";

import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { environment } from "../../../../../environment";


export class LoginUseCase implements ILoginUsecase {
  loginRepository: LoginRepository;
//  aboutUserRepository: AboutUserRepository;
  loginMapper = new LoginMapper();
  userManager = new UserManager();
 // aboutUserUseCase = new AboutUserUseCase(aboutUserRepository);

  constructor(private _loginRepository: LoginRepository) {
    this.loginRepository = this._loginRepository;
 //   this.aboutUserRepository = this._aboutUserRepository;
  }
  async execute(model: LoginModel): Promise<Result<LoginModel>> {
     //model.password = new Password({ value: "pw" }); // mapper exception test
     //model.email = new EmailAddress({ value: "pw" }); // mapper exception test
    try {
      const dto = this.loginMapper.mapToDTO(model);
      if (dto.isFailure) {
        return Result.fail<LoginModel>(dto.getError());
      } else {
        const response = await this._loginRepository.login(dto.getValue());

        if (response.isSuccess) {
          const value: LoginResponseDto = response.getValue();
          this.userManager.saveToken(value.jwt);
          this.userManager.updateLoginDataInfo(value);
          this.userManager.setActiveTenantName(
            value.data.attributes.activeTenantUser.data.attributes.tenantEstate
          );

          if (environment.env == "prod") {
          const analytics = getAnalytics();
          /*
          setUserId(analytics, value.data.id ? value.data.id.toString() : '');
          setUserProperties(analytics, { active_tenant_estate: value.data.attributes.activeTenantUser.data.attributes.tenantEstate.data.id},  );          
  */
          logEvent(analytics, 'user_login', {
            method: 'email', 
            user_id: value.data.id,
            active_tenant_estate_id: value.data.attributes.activeTenantUser.data.attributes.tenantEstate.data.id
          });
        }

          /*
          const aboutUserResult = await this.aboutUserUseCase.execute(); 
          if (aboutUserResult.isSuccess) {
          } else {
          }
          */
          
          const loginModel = this.loginMapper.mapToDomain(value);
          return Result.ok<LoginModel>(loginModel.getValue());
        } else {
          return Result.fail<LoginModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<LoginResponseDto>(
        new UseCaseException(new UnknownCause(USECASE.LOGIN))
      );
    }
  }
}
