<template>
  <router-view />
</template>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~@yaireo/tagify/src/tagify.scss";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/lib/theme-chalk/index.css";

// Main demo style scss
@import "presentation/assets/sass/plugins";
@import "presentation/assets/sass/style";

// Dark mode demo style scss
//@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";

//RTL version styles
//@import "assets/css/style.rtl.css";
</style>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/presentation/store/enums/StoreEnums";

import { COMPONENT_NAME } from "./domain/Constant";
import { CryptoKeyService } from "./infrastructure/encryption/CryptoKeyService";
import * as crypto from "crypto";

export default defineComponent({
  name: COMPONENT_NAME.APP,
  setup() {
    const store = useStore();

    onMounted(() => {
      const key = crypto.randomBytes(32);
      const iv = crypto.randomBytes(16);
      CryptoKeyService.setKeyAndIvToStorage(key, iv);

      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/presentation/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });
  },
});
</script>
