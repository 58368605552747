import { BreedRemoveRepository } from "../repository/BreedRemoveRepository";
import { BreedRemoveMapper } from "../mapper/BreedRemoveMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IBreedRemoveUseCase } from "@/domain/breed/breedRemove/usecase/IBreedRemoveUsecase";
import { BreedRemoveModel } from "@/domain/breed/breedRemove/model/BreedRemoveModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";

export class BreedRemoveUsecase implements IBreedRemoveUseCase {
  breedRemoveRepository: BreedRemoveRepository;
  breedRemoveMapper = new BreedRemoveMapper();

  constructor(_breedRemoveRepository: BreedRemoveRepository) {
    this.breedRemoveRepository = _breedRemoveRepository;
  }

  async execute(
    model: BreedRemoveModel
  ): Promise<Result<BreedRemoveModel>> {
    try {
      const dto = this.breedRemoveMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<BreedRemoveModel>(dto.getError());
      } else {
        const response = await this.breedRemoveRepository.removeBreed(
          dto
        );

        if (response.isSuccess) {
          const domain = this.breedRemoveMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
          const analytics = getAnalytics();
          logEvent(analytics, 'remove_breed', {
            breed_id: model.id
          });
        }
          return Result.ok<BreedRemoveModel>(domain.getValue());
        } else {
          return Result.fail<BreedRemoveModel>(response.getError())
        }
      }
    } catch (error) {
      return Result.fail<BreedRemoveModel>(
        new UseCaseException(new UnknownCause(USECASE.BREED_REMOVE))
      );
    }
  }
}
