import { BarnyardListModel } from "@/domain/barnyard/barnyardList/model/BarnyardListModel";
import { BarnyardListRepository } from "../repository/BarnyardListRepository";
import { IBarnyardListUseCase } from "@/domain/barnyard/barnyardList/usecase/IBarnyardListUseCase";
import { Result } from "@/core/utility/ResultType";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/domain/Constant";
import { BarnyardListMapper } from "../mapper/BarnyardListMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";

export class BarnyardListUseCase implements IBarnyardListUseCase {
  barnyardRepository: BarnyardListRepository;
  barnyardListMapper = new BarnyardListMapper();

  constructor(_barnyardRepository: BarnyardListRepository) {
    this.barnyardRepository = _barnyardRepository;
  }

  async execute(model: BarnyardListModel): Promise<Result<BarnyardListModel>> {
    try {
      const dto = this.barnyardListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<BarnyardListModel>(dto.getError());
      } else {

      const response = await this.barnyardRepository.barnyardList(dto);

      if (response.isSuccess) {
        const domain = this.barnyardListMapper.mapToDomain(response.getValue());
        return Result.ok<BarnyardListModel>(domain.getValue());
      } else {
        return Result.fail<BarnyardListModel>(
          response.getError()
        );
      }
    }
    } catch (error) {
      return Result.fail<BarnyardListModel>(
        new UseCaseException(new UnknownCause(USECASE.BARNYARD_LIST))
      );
    }
  }
}
