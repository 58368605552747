import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalFeedAddRequestDto } from "@/domain/animal/animalFeed/animalFeedAdd/dto/AnimalFeedAddRequestDto";
import { AnimalFeedAddModel } from "@/domain/animal/animalFeed/animalFeedAdd/model/AnimalFeedAddModel";

export class AnimalFeedAddMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<AnimalFeedAddModel> {
    try {
      const addAnimalFeedModel: AnimalFeedAddModel = {
        ok: dto.ok,
      };
      return Result.ok(addAnimalFeedModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_FEED_ADD))
      );
    }
  }

  mapToDTO(domain: AnimalFeedAddModel): Result<AnimalFeedAddRequestDto> {
    try {
      const addAnimalFeedRequest = new AnimalFeedAddRequestDto(
        domain.animal,
        domain.animals,
        domain.barnyard,
        domain.product,
        domain.amount,
        domain.completedDate,
        domain.description
      );

      return Result.ok(addAnimalFeedRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_FEED_ADD))
      );
    }
  }
}
