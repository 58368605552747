import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalVaccineMultipleAddService from "../service/AnimalVaccineMultipleAddService";
import { IAnimalVaccineMultipleAddRepository } from "@/domain/animal/animalVaccine/animalVaccineMultipleAdd/repository/IAnimalVaccineMultipleAddRepository";
import { AnimalVaccineMultipleAddRequestDto } from "@/domain/animal/animalVaccine/animalVaccineMultipleAdd/dto/AnimalVaccineMultipleAddRequestDto";
import { AnimalVaccineMultipleAddResponseDto } from "@/domain/animal/animalVaccine/animalVaccineMultipleAdd/dto/AnimalVaccineMultipleAddResponseDto";

const addAnimalVaccineService = new AnimalVaccineMultipleAddService();

export class AnimalVaccineMultipleAddRepository implements IAnimalVaccineMultipleAddRepository {
  async addAnimalVaccine(
    request: AnimalVaccineMultipleAddRequestDto
  ): Promise<Result<AnimalVaccineMultipleAddResponseDto>> {
    try {
      return await addAnimalVaccineService
        .add(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<AnimalVaccineMultipleAddResponseDto>(response.getValue());
          } else {
            return Result.fail<AnimalVaccineMultipleAddResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<AnimalVaccineMultipleAddResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<AnimalVaccineMultipleAddResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_VACCINE_MULTIPLE_ADD))
      );
    }
  }
}
