import { BaseDomain } from "@/core/base/BaseDomain";
import { TenantEstateResponse } from "../../tenantEstate/dto/TenantEstateResponse";
import { TenantRoleResponse } from "../../tenantRole/dto/TenantRoleResponse";

export class TenantUserModel extends BaseDomain {
  isActive: boolean;
  tenantRole?: TenantRoleResponse;
  tenantEstate?: TenantEstateResponse;

  constructor(
    isActive: boolean,
    tenantRole?: TenantRoleResponse,
    tenantEstate?: TenantEstateResponse,
  ) {
    super();
    this.isActive = isActive;
    this.tenantRole = tenantRole;
    this.tenantEstate = tenantEstate;
  }
}
