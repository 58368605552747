import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { IGetAnimalDetailRepository } from "@/domain/animal/animalDetail/repository/IGetAnimalDetailRepository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import GetAnimalDetailService from "../service/GetAnimalDetailService";
import { GetAnimalDetailRequestDto } from "@/domain/animal/animalDetail/dto/GetAnimalDetailRequestDto";
import { GetAnimalDetailResponseDto } from "@/domain/animal/animalDetail/dto/GetAnimalDetailResponseDto";

const getAnimalService = new GetAnimalDetailService();

export class GetAnimalDetailRepository implements IGetAnimalDetailRepository {
  async getAnimal(request: GetAnimalDetailRequestDto): Promise<Result<GetAnimalDetailResponseDto>> {
    try {
      return await getAnimalService
        .get(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<GetAnimalDetailResponseDto>(response.getValue());
          } else {
            return Result.fail<GetAnimalDetailResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_GET)));
    }
  }
}
