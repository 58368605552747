import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import BreedAddService from "../service/BreedAddService";
import { IBreedAddRepository } from "@/domain/breed/breedAdd/repository/IBreedAddRepository";
import { BreedAddRequestDto } from "@/domain/breed/breedAdd/dto/BreedAddRequestDto";
import { BreedAddResponseDto } from "@/domain/breed/breedAdd/dto/BreedAddResponseDto";

const addBreedService = new BreedAddService();

export class BreedAddRepository implements IBreedAddRepository {
  async addBreed(
    request: BreedAddRequestDto
  ): Promise<Result<BreedAddResponseDto>> {
    try {
      return await addBreedService
        .add(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<BreedAddResponseDto>(response.getValue());
          } else {
            return Result.fail<BreedAddResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<BreedAddResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<BreedAddResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.BREED_ADD))
      );
    }
  }
}
