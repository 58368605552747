import ChangeTenantEstateRepository from "../repository/ChangeTenantEstateRepository";
import { Result } from "@/core/utility/ResultType";
import i18n from "@/presentation/language/i18n";
import { SWAL_MESSAGES, USECASE } from "@/domain/Constant";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { IChangeTenantEstateUseCase } from "@/domain/authentication/changeTenantEstate/usecase/IChangeTenantEstateUsecase";
import { ChangeTenantEstateModel } from "@/domain/authentication/changeTenantEstate/model/ChangeTenantEstateModel";
import { ChangeTenantEstateResponseDto } from "@/domain/authentication/changeTenantEstate/dto/ChangeTenantEstateResponseDto";
import { ChangeTenantEstateMapper } from "../mapper/ChangeTenantEstateMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { UserManager } from "@/infrastructure/helper/UserManager";

export class ChangeTenantEstateUseCase implements IChangeTenantEstateUseCase {
  changeTenantEstateRepository: ChangeTenantEstateRepository;
  changeTenantEstateMapper = new ChangeTenantEstateMapper();
  userManager = new UserManager();

  constructor(
    private _changeTenantEstateRepository: ChangeTenantEstateRepository
  ) {
    this.changeTenantEstateRepository = this._changeTenantEstateRepository;
  }

  async execute(
    model: ChangeTenantEstateModel
  ): Promise<Result<ChangeTenantEstateModel>> {
    try {
      const dto = this.changeTenantEstateMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<ChangeTenantEstateModel>(dto.getError());
      } else {
        const response =
          await this._changeTenantEstateRepository.changeTenantEstate(dto);

        if (response.isSuccess) {
          const value: ChangeTenantEstateResponseDto = response.getValue();

          this.userManager.saveToken(value.jwt);
          this.userManager.setActiveTenantName(
            value.data.attributes.activeTenantUser.data.attributes.tenantEstate
          );

          const changeTenantEstateModel =
            this.changeTenantEstateMapper.mapToDomain(value);
          return Result.ok<ChangeTenantEstateModel>(
            changeTenantEstateModel.getValue()
          );
        } else {
          return Result.fail<ChangeTenantEstateModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<ChangeTenantEstateModel>(
        new UseCaseException(new UnknownCause(USECASE.CHANGE_TENANT_ESTATE))
      );
    }
  }
}
