import { BaseDTO } from "@/core/base/BaseDTO";

export class AnimalTransferRequestDto extends BaseDTO {
  data: {
    animal?: number;
    barnyard?: number;
    transferDate?: Date;
    description?: string;
  };
  constructor(
    animal?: number,
    barnyard?: number,
    transferDate?: Date,
    description?: string,
  ) {
    super();
    this.data = {
      animal: animal,
      barnyard: Number(barnyard),
      transferDate: transferDate,
      description: description,
    };
  }
}
