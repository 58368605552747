import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalTagAddRequestDto } from "@/domain/animalTag/animalTagAdd/dto/AnimalTagAddRequestDto";
import { AnimalTagAddResponseDto } from "@/domain/animalTag/animalTagAdd/dto/AnimalTagAddResponseDto";
import { AnimalTagAddModel } from "@/domain/animalTag/animalTagAdd/model/AnimalTagAddModel";

export class AnimalTagAddMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: AnimalTagAddResponseDto): Result<AnimalTagAddModel> {
    try {
      const animalTagAdd: AnimalTagAddModel = {
        id: dto.data.id,
        name: dto.data.attributes.name,
        description: dto.data.attributes.description,
        shortCode: dto.data.attributes.shortCode,
        isDefault: dto.data.attributes.isDefault,
      };

      return Result.ok(animalTagAdd);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_TAG_ADD))
      );
    }
  }

  mapToDTO(domain: AnimalTagAddModel): Result<AnimalTagAddRequestDto> {
    try {
      const animalTagRequest = new AnimalTagAddRequestDto(domain.name, domain.description);

      return Result.ok(animalTagRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_TAG_ADD))
      );
    }
  }
}
