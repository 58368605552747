import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { AnimalVaccineAddResponseDto } from "@/domain/animal/animalVaccine/animalVaccineAdd/dto/AnimalVaccineAddResponseDto";
import { AnimalVaccineAddRequestDto } from "@/domain/animal/animalVaccine/animalVaccineAdd/dto/AnimalVaccineAddRequestDto";
import { IAnimalVaccineAddService } from "@/domain/animal/animalVaccine/animalVaccineAdd/service/IAnimalVaccineAddService";
import i18n from "@/presentation/language/i18n";
import { BadRequestCause } from "@/core/cause/BadRequestCause";

export default class AnimalVaccineAddService
  implements IAnimalVaccineAddService
{
  async add(
    request: AnimalVaccineAddRequestDto
  ): Promise<Result<AnimalVaccineAddResponseDto>> {
    try {
      return await instance
        .post(API_ROUTE.ANIMAL_VACCINE_ADD, request)
        .then((response) => {
          return Result.ok<AnimalVaccineAddResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<AnimalVaccineAddResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_ADD_ANIMAL_VACCINE)))
          );
        });
    } catch (error) {
      return Result.fail<AnimalVaccineAddResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_VACCINE_ADD))
      );
    }
  }
}
