import { BaseDTO } from "@/core/base/BaseDTO";

export class AnimalVaccineMultipleAddRequestDto extends BaseDTO {
  data: {
    animals?: number[];
    barnyard?: number;
    vaccine?: number;
    amount?: number;
    description?: string;
    completedDate?: Date;
  };

  constructor(
    animals?: number[],
    barnyard?: number,
    vaccine?: number,
    amount?: number,
    description?: string,
    completedDate?: Date
  ) {
    amount = Number(amount)
    super();
    this.data = {
      animals,
      barnyard,
      vaccine,
      amount,
      description,
      completedDate,
    };
  }
}
