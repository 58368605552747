import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { VaccinationListRequestDto } from "@/domain/vaccination/vaccinationList/dto/VaccinationListRequestDto";
import { VaccinationListResponseDto } from "@/domain/vaccination/vaccinationList/dto/VaccinationListResponseDto";
import { VaccinationListModel } from "@/domain/vaccination/vaccinationList/model/VaccinationListModel";

const paginationMapper = new PaginationMapper();

export class VaccinationListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: VaccinationListResponseDto): Result<VaccinationListModel[]> {
    try {
      const vaccinationList: VaccinationListModel[] = [];

      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const vaccinationListModel: VaccinationListModel = {
            vaccine: {
              id: item.id,
              shortCode: item.attributes.shortCode,
              name: item.attributes.name,
              serialNumber: item.attributes.name,
              description: item.attributes.description,
            },
            page: 0,
            pageSize: 0,
            pagination: dto.meta.pagination
          };

          vaccinationList.push(vaccinationListModel);
        }
      }
      return Result.ok(vaccinationList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.VACCINATION_LIST))
      );
    }
  }

  mapToDTO(domain: VaccinationListModel): Result<VaccinationListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const vaccinationRequest = new VaccinationListRequestDto(pagiRequest);

      return Result.ok(vaccinationRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.VACCINATION_LIST))
      );
    }
  }
}
