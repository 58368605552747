import { ILoginRepository } from "@/domain/authentication/loginUser/repository/ILoginRepository";
import { Result } from "@/core/utility/ResultType";
import { LoginRequestDto } from "@/domain/authentication/loginUser/dto/LoginRequestDto";
import { LoginResponseDto } from "@/domain/authentication/loginUser/dto/LoginResponseDto";
import AuthService from "../service/AuthService";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { REPOSITORY } from "@/domain/Constant";

const authService = new AuthService();

export default class LoginRepository implements ILoginRepository {
  async login(request: LoginRequestDto): Promise<Result<LoginResponseDto>> {
    try {
      return authService
        .login(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<LoginResponseDto>(response.getValue());
          } else {
            return Result.fail<LoginResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<LoginResponseDto>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<LoginResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.LOGIN))
      );
    }
  }
}
