import { GetAnimalDetailRepository } from "@/application/animal/animalDetail/repository/GetAnimalDetailRepository";
import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { CreateAnimalUseCase } from "@/application/animal/animalAdd/usecase/CreateAnimalUseCase";
import { AnimalDetailModel } from "@/domain/animal/animalDetail/model/AnimalDetailModel";
import { GetAnimalDetailUseCase } from "@/application/animal/animalDetail/usecase/GetAnimalDetailUseCase";
import { AnimalListUsecase } from "@/application/animal/animalList/usecase/AnimalListUseCase";
import { AnimalListRepository } from "@/application/animal/animalList/repository/AnimalListRepository";
import { CreateAnimalRepository } from "@/application/animal/animalAdd/repository/CreateAnimalRepository";
import { AnimalListModel } from "@/domain/animal/animalList/model/AnimalListModel";
import { AnimalWeightUpdateModel } from "@/domain/animal/animalWeight/animalWeightUpdate/model/AnimalWeightUpdateModel";
import { AnimalWeightUpdateUsecase } from "@/application/animal/animalWeight/animalWeightUpdate/usecase/AnimalWeightUpdateUseCase";
import { AnimalWeightUpdateRepository } from "@/application/animal/animalWeight/animalWeightUpdate/repository/AnimalWeightUpdateRepository";
import { AnimalWeightListRepository } from "@/application/animal/animalWeight/animalWeightList/repository/AnimalWeightListRepository";
import { AnimalWeightListUsecase } from "@/application/animal/animalWeight/animalWeightList/usecase/AnimalWeightListUseCase";
import { AnimalWeightListModel } from "@/domain/animal/animalWeight/animalWeightList/model/AnimalWeightListModel";
import { AnimalVaccineAddRepository } from "@/application/animal/animalVaccine/animalVaccineAdd/repository/AnimalVaccineAddRepository";
import { AnimalVaccineAddUsecase } from "@/application/animal/animalVaccine/animalVaccineAdd/usecase/AnimalVaccineAddUseCase";
import { AnimalVaccineAddModel } from "@/domain/animal/animalVaccine/animalVaccineAdd/model/AnimalVaccineAddModel";
import { AnimalTypeListRepository } from "@/application/animal/animalTypeList/repository/AnimalTypeListRepository";
import { AnimalTypeListUsecase } from "@/application/animal/animalTypeList/usecase/AnimalTypeListUseCase";
import { AnimalTypeListModel } from "@/domain/animal/animalTypeList/model/AnimalTypeListModel";
import { AnimalVaccineListRepository } from "@/application/animal/animalVaccine/animalVaccineList/repository/AnimalVaccineListRepository";
import { AnimalVaccineListUsecase } from "@/application/animal/animalVaccine/animalVaccineList/usecase/AnimalVaccineListUseCase";
import { AnimalVaccineListModel } from "@/domain/animal/animalVaccine/animalVaccineList/model/AnimalVaccineListModel";
import { AnimalVaccineMultipleAddModel } from "@/domain/animal/animalVaccine/animalVaccineMultipleAdd/model/AnimalVaccineMultipleAddModel";
import { AnimalVaccineMultipleAddUsecase } from "@/application/animal/animalVaccine/animalVaccineMultipleAdd/usecase/AnimalVaccineMultipleAddUseCase";
import { AnimalVaccineMultipleAddRepository } from "@/application/animal/animalVaccine/animalVaccineMultipleAdd/repository/AnimalVaccineMultipleAddRepository";
import { AnimalFeedAddUsecase } from "@/application/animal/animalFeed/animalFeedAdd/usecase/AnimalFeedAddUseCase";
import { AnimalFeedAddRepository } from "@/application/animal/animalFeed/animalFeedAdd/repository/AnimalFeedAddRepository";
import { AnimalFeedAddModel } from "@/domain/animal/animalFeed/animalFeedAdd/model/AnimalFeedAddModel";
import { DeleteAnimalModel } from "@/domain/animal/animalDelete/model/DeleteAnimalModel";
import { DeleteAnimalUseCase } from "@/application/animal/animalDelete/usecase/DeleteAnimalUseCase";
import { DeleteAnimalRepository } from "@/application/animal/animalDelete/repository/DeleteAnimalRepository";
import { AnimalDiseaseAddRepository } from "@/application/animal/animalDisease/animalDiseaseAdd/repository/AnimalDiseaseAddRepository";
import { AnimalDiseaseListRepository } from "@/application/animal/animalDisease/animalDiseaseList/repository/AnimalDiseaseListRepository";
import { AnimalDiseaseListUsecase } from "@/application/animal/animalDisease/animalDiseaseList/usecase/AnimalDiseaseListUseCase";
import { AnimalDiseaseAddUsecase } from "@/application/animal/animalDisease/animalDiseaseAdd/usecase/AnimalDiseaseAddUseCase";
import { AnimalDiseaseListModel } from "@/domain/animal/animalDisease/animalDiseaseList/model/AnimalDiseaseListModel";
import { AnimalDiseaseAddModel } from "@/domain/animal/animalDisease/animalDiseaseAdd/model/AnimalDiseaseAddModel";
import { AnimalFeedListModel } from "@/domain/animal/animalFeed/animalFeedList/model/AnimalFeedListModel";
import { AnimalFeedListUsecase } from "@/application/animal/animalFeed/animalFeedList/usecase/AnimalFeedListUseCase";
import { AnimalFeedListRepository } from "@/application/animal/animalFeed/animalFeedList/repository/AnimalFeedListRepository";
import { UpdateAnimalModel } from "@/domain/animal/animalUpdate/model/UpdateAnimalModel";
import { UpdateAnimalUseCase } from "@/application/animal/animalUpdate/usecase/UpdateAnimalUseCase";
import { UpdateAnimalRepository } from "@/application/animal/animalUpdate/repository/UpdateAnimalRepository";
import { AnimalTransferModel } from "@/domain/animal/animalTransfer/model/AnimalTransferModel";
import { AnimalTransferRepository } from "@/application/animal/animalTransfer/repository/AnimalTransferRepository";
import { AnimalTransferUsecase } from "@/application/animal/animalTransfer/usecase/AnimalTransferUseCase";
import { AnimalDiseaseUpdateModel } from "@/domain/animal/animalDisease/animalDiseaseUpdate/model/AnimalDiseaseUpdateModel";
import { AnimalDiseaseUpdateUsecase } from "@/application/animal/animalDisease/animalDiseaseUpdate/usecase/AnimalDiseaseUpdateUseCase";
import { AnimalDiseaseUpdateRepository } from "@/application/animal/animalDisease/animalDiseaseUpdate/repository/AnimalDiseaseUpdateRepository";

export function createAnimalController() {
  const animalRepository = new GetAnimalDetailRepository();
  const animalListRepository = new AnimalListRepository();
  const createAnimalRepository = new CreateAnimalRepository();
  const updateAnimalRepository = new UpdateAnimalRepository();
  const deleteAnimalRepository = new DeleteAnimalRepository();
  const animalTransferRepository = new AnimalTransferRepository();
  const animalWeightUpdateRepository = new AnimalWeightUpdateRepository();
  const animalWeightListRepository = new AnimalWeightListRepository();
  const animalVaccineAddRepository = new AnimalVaccineAddRepository();
  const animalVaccineMultipleAddRepository =
    new AnimalVaccineMultipleAddRepository();
  const animalTypeListRepository = new AnimalTypeListRepository();
  const animalVaccineListRepository = new AnimalVaccineListRepository();
  const animalFeedListRepository = new AnimalFeedListRepository();
  const animalFeedAddRepository = new AnimalFeedAddRepository();
  const animalDiseaseAddRepository = new AnimalDiseaseAddRepository();
  const animalDiseaseUpdateRepository = new AnimalDiseaseUpdateRepository();
  const animalDiseaseListRepository = new AnimalDiseaseListRepository();

  const animalListUseCase = new AnimalListUsecase(animalListRepository);
  const getAnimalDetailUseCase = new GetAnimalDetailUseCase(animalRepository);
  const createAnimalUseCase = new CreateAnimalUseCase(createAnimalRepository);
  const updateAnimalUseCase = new UpdateAnimalUseCase(updateAnimalRepository);
  const deleteAnimalUseCase = new DeleteAnimalUseCase(deleteAnimalRepository);
  const animalTransferUsecase = new AnimalTransferUsecase(animalTransferRepository);
  const animalWeightUpdateUseCase = new AnimalWeightUpdateUsecase(
    animalWeightUpdateRepository
  );
  const animalWeightListUseCase = new AnimalWeightListUsecase(
    animalWeightListRepository
  );
  const animalVaccineAddUseCase = new AnimalVaccineAddUsecase(
    animalVaccineAddRepository
  );
  const animalVaccineMultipleAddListUseCase =
    new AnimalVaccineMultipleAddUsecase(animalVaccineMultipleAddRepository);
  const animalTypeListUseCase = new AnimalTypeListUsecase(
    animalTypeListRepository
  );
  const animalVaccineListUseCase = new AnimalVaccineListUsecase(
    animalVaccineListRepository
  );
  const animalFeedListUseCase = new AnimalFeedListUsecase(
    animalFeedListRepository
  );
  const animalFeedAddUseCase = new AnimalFeedAddUsecase(
    animalFeedAddRepository
  );
  const animalDiseaseAddUseCase = new AnimalDiseaseAddUsecase(
    animalDiseaseAddRepository
  );
  const animalDiseaseUpdateUseCase = new AnimalDiseaseUpdateUsecase(
    animalDiseaseUpdateRepository
  );
  const animalDiseaseListUseCase = new AnimalDiseaseListUsecase(
    animalDiseaseListRepository
  );

  return new AnimalController(
    animalListUseCase,
    getAnimalDetailUseCase,
    createAnimalUseCase,
    updateAnimalUseCase,
    deleteAnimalUseCase,
    animalTransferUsecase,
    animalWeightUpdateUseCase,
    animalWeightListUseCase,
    animalVaccineAddUseCase,
    animalVaccineMultipleAddListUseCase,
    animalTypeListUseCase,
    animalVaccineListUseCase,
    animalFeedListUseCase,
    animalFeedAddUseCase,
    animalDiseaseAddUseCase,
    animalDiseaseUpdateUseCase,
    animalDiseaseListUseCase
  );
}

export class AnimalController implements IController {
  constructor(
    private animalListUseCase: AnimalListUsecase,
    private getAnimalDetailUseCase: GetAnimalDetailUseCase,
    private createAnimalUseCase: CreateAnimalUseCase,
    private updateAnimalUseCase: UpdateAnimalUseCase,
    private deleteAnimalUseCase: DeleteAnimalUseCase,
    private animalTransferUsecase: AnimalTransferUsecase,
    private animalWeightUpdateUseCase: AnimalWeightUpdateUsecase,
    private animalWeightListUseCase: AnimalWeightListUsecase,
    private animalVaccineAddListUseCase: AnimalVaccineAddUsecase,
    private animalVaccineMultipleAddListUseCase: AnimalVaccineMultipleAddUsecase,
    private animalTypeListUseCase: AnimalTypeListUsecase,
    private animalVaccineListUseCase: AnimalVaccineListUsecase,
    private animalFeedListUseCase: AnimalFeedListUsecase,
    private animalFeedAddUseCase: AnimalFeedAddUsecase,
    private animalDiseaseAddUseCase: AnimalDiseaseAddUsecase,
    private animalDiseaseUpdateUseCase: AnimalDiseaseUpdateUsecase,
    private animalDiseaseListUseCase: AnimalDiseaseListUsecase
  ) {}

  async animalList(
    animalListModel: AnimalListModel
  ): Promise<Result<AnimalListModel>> {
    const response = await this.animalListUseCase.execute(animalListModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalListModel>(response.getError());
    }
  }

  async getAnimal(
    animalModel: AnimalDetailModel
  ): Promise<Result<AnimalDetailModel>> {
    const response = await this.getAnimalDetailUseCase.execute(animalModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalDetailModel>(response.getError());
    }
  }

  async addAnimal(
    addAnimalModel: AnimalDetailModel
  ): Promise<Result<AnimalDetailModel>> {
    const response = await this.createAnimalUseCase.execute(addAnimalModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalDetailModel>(response.getError());
    }
  }

  async updateAnimal(
    updateAnimalModel: UpdateAnimalModel
  ): Promise<Result<UpdateAnimalModel>> {
    const response = await this.updateAnimalUseCase.execute(updateAnimalModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<UpdateAnimalModel>(response.getError());
    }
  }

  async deleteAnimal(
    deleteAnimalModel: DeleteAnimalModel
  ): Promise<Result<DeleteAnimalModel>> {
    const response = await this.deleteAnimalUseCase.execute(deleteAnimalModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<DeleteAnimalModel>(response.getError());
    }
  }

  async updateAnimalWeight(
    animalWeightUpdateModel: AnimalWeightUpdateModel
  ): Promise<Result<AnimalWeightUpdateModel>> {
    const response = await this.animalWeightUpdateUseCase.execute(
      animalWeightUpdateModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalWeightUpdateModel>(response.getError());
    }
  }

  async animalWeightList(
    animalWeightListModel: AnimalWeightListModel
  ): Promise<Result<AnimalWeightListModel>> {
    const response = await this.animalWeightListUseCase.execute(
      animalWeightListModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalWeightListModel>(response.getError());
    }
  }

  async addAnimalVaccine(
    animalVaccineAddModel: AnimalVaccineAddModel
  ): Promise<Result<AnimalVaccineAddModel>> {
    const response = await this.animalVaccineAddListUseCase.execute(
      animalVaccineAddModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalVaccineAddModel>(response.getError());
    }
  }

  async addAnimalsVaccine(
    animalVaccineMultipleAddModel: AnimalVaccineMultipleAddModel
  ): Promise<Result<AnimalVaccineMultipleAddModel>> {
    const response = await this.animalVaccineMultipleAddListUseCase.execute(
      animalVaccineMultipleAddModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalVaccineMultipleAddModel>(response.getError());
    }
  }

  async animalTypeList(
    animalTypeListModel: AnimalTypeListModel
  ): Promise<Result<AnimalTypeListModel>> {
    const response = await this.animalTypeListUseCase.execute(
      animalTypeListModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalTypeListModel>(response.getError());
    }
  }

  async animalVaccineList(
    animalVaccineListModel: AnimalVaccineListModel
  ): Promise<Result<AnimalVaccineListModel>> {
    const response = await this.animalVaccineListUseCase.execute(
      animalVaccineListModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalVaccineListModel>(response.getError());
    }
  }

  async animalFeedList(
    animalFeedListModel: AnimalFeedListModel
  ): Promise<Result<AnimalFeedListModel>> {
    const response = await this.animalFeedListUseCase.execute(
      animalFeedListModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalVaccineListModel>(response.getError());
    }
  }

  async addAnimalFeed(
    animalFeedAddModel: AnimalFeedAddModel
  ): Promise<Result<AnimalFeedAddModel>> {
    const response = await this.animalFeedAddUseCase.execute(
      animalFeedAddModel
    );
    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalFeedAddModel>(response.getError());
    }
  }

  async addAnimalDisease(
    animalDiseaseAddModel: AnimalDiseaseAddModel
  ): Promise<Result<AnimalDiseaseAddModel>> {
    const response = await this.animalDiseaseAddUseCase.execute(
      animalDiseaseAddModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalDiseaseAddModel>(response.getError());
    }
  }

  async updateAnimalDisease(
    animalDiseaseUpdateModel: AnimalDiseaseUpdateModel
  ): Promise<Result<AnimalDiseaseUpdateModel>> {
    const response = await this.animalDiseaseUpdateUseCase.execute(
      animalDiseaseUpdateModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalDiseaseUpdateModel>(response.getError());
    }
  }

  async animalDiseaseList(
    animalDiseaseListModel: AnimalDiseaseListModel
  ): Promise<Result<AnimalDiseaseListModel>> {
    const response = await this.animalDiseaseListUseCase.execute(
      animalDiseaseListModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalDiseaseListModel>(response.getError());
    }
  }

  async animalTransfer(
    animalTransferModel: AnimalTransferModel
  ): Promise<Result<AnimalTransferModel>> {
    const response = await this.animalTransferUsecase.execute(
      animalTransferModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AnimalTransferModel>(response.getError());
    }
  }
}
