import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/domain/Constant";
import { BarnyardDetailResponseDto } from "@/domain/barnyard/barnyardDetail/dto/BarnyardDetailResponseDto";
import { BarnyardDetailModel } from "@/domain/barnyard/barnyardDetail/model/BarnyardDetailModel";
import { BarnyardDetailRequestDto } from "@/domain/barnyard/barnyardDetail/dto/BarnyardDetailRequestDto";

export class BarnyardDetailMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: BarnyardDetailResponseDto): Result<BarnyardDetailModel> {
    try {
      if(dto.data)
      {
        const barnyardDetailModel: BarnyardDetailModel = {
          id: dto.data.id,
          name: dto.data.attributes.name,
          shortCode: dto.data.attributes.shortCode,
          description: dto.data.attributes.description,
          sortValue: dto.data.attributes.sortValue,
          capacity: dto.data.attributes.capacity,
          animalCount: dto.data.attributes.animalCount,
        };
    
          return Result.ok(barnyardDetailModel);

      }
      else{
        const barnyardDetailModel: BarnyardDetailModel = {
          id:undefined,
          name: undefined,
          shortCode: undefined,
          description: undefined,
          sortValue: undefined,
          capacity: undefined,
          animalCount: undefined,
      };
      return Result.ok(barnyardDetailModel);
    }

    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.BARNYARD_DETAIL))
      );
    }
  }

  mapToDTO(domain: BarnyardDetailModel): Result<BarnyardDetailRequestDto> {
    try {
      const animalRequest: BarnyardDetailRequestDto = {
        id: domain.id,
      };

      return Result.ok(animalRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.BARNYARD_DETAIL))
      );
    }
  }
}
