import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import ForgotPasswordService from "../service/ForgotPasswordService";
import { IForgotPasswordRepository } from "@/domain/authentication/forgotPassword/repository/IForgotPasswordRepository";
import { ForgotPasswordRequestDto } from "@/domain/authentication/forgotPassword/dto/ForgotPasswordRequestDto";

const forgotPasswordService = new ForgotPasswordService();

export class ForgotPasswordRepository implements IForgotPasswordRepository {
  async forgotPassword(
    request: ForgotPasswordRequestDto
  ): Promise<Result<void>> {
    try {
      return await forgotPasswordService
        .post(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<void>(response.getValue());
          } else {
            return Result.fail<void>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<void>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<void>(
        new RepositoryException(new UnknownCause(REPOSITORY.FORGOT_PASSWORD))
      );
    }
  }
}
