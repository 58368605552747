import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { DeleteAnimalRequestDto } from "@/domain/animal/animalDelete/dto/DeleteAnimalRequestDto";
import { DeleteAnimalModel } from "@/domain/animal/animalDelete/model/DeleteAnimalModel";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/domain/Constant";

export class DeleteAnimalMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<DeleteAnimalModel> {
    try {
        const animalModel: DeleteAnimalModel = {
          ok: dto.ok,
        };
        return Result.ok(animalModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_DELETE))
      );
    }
  }

  mapToDTO(domain: DeleteAnimalModel): Result<DeleteAnimalRequestDto> {
    try {
      const animalRequest: DeleteAnimalRequestDto = {
        id: domain.id,
      };

      return Result.ok(animalRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_DELETE))
      );
    }
  }
}
