import { IAnimalListUseCase } from "@/domain/animal/animalList/usecase/IAnimalListUseCase";
import { AnimalListRepository } from "../repository/AnimalListRepository";
import { AnimalListModel } from "@/domain/animal/animalList/model/AnimalListModel";
import { AnimalListMapper } from "../mapper/AnimalListMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";

export class AnimalListUsecase implements IAnimalListUseCase {
  animalRepository: AnimalListRepository;
  animalListMapper = new AnimalListMapper();

  constructor(_animalRepository: AnimalListRepository) {
    this.animalRepository = _animalRepository;
  }

  async execute(model: AnimalListModel): Promise<Result<AnimalListModel>> {
    try {
      const dto = this.animalListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalListModel>(dto.getError());
      } else {
        const response = await this.animalRepository.animalList(dto);

        if (response.isSuccess) {
          const domain = this.animalListMapper.mapToDomain(response.getValue());
          return Result.ok<AnimalListModel>(domain.getValue());
        } else {
          return Result.fail<AnimalListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AnimalListModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_LIST))
      );
    }
  }
}
