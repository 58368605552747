import { BaseDTO } from "@/core/base/BaseDTO";

export class AnimalDiseaseAddRequestDto extends BaseDTO {
  data: {
    animal?:  number;
    disease?: number;
    startDate?: Date;
    endDate?: Date;
  };

  constructor(
    animal?:  number,
    disease?: number,
    startDate?: Date,
    endDate?: Date
  ) {
    animal = Number(animal)
    disease = Number(disease)
    super();
    this.data = {
      animal,
      disease,
      startDate,
      endDate,
    };
  }
}
