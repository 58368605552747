import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { PriceAddRequestDto } from "@/domain/breed/priceAdd/dto/PriceAddRequestDto";
import { PriceAddModel } from "@/domain/breed/priceAdd/model/PriceAddModel";

export class PriceAddMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: boolean): Result<PriceAddModel> {
    try {
      const priceAdd: PriceAddModel = {
        ok: dto,
      };

      return Result.ok(priceAdd.ok);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.PRICE_ADD))
      );
    }
  }

  mapToDTO(domain: PriceAddModel): Result<PriceAddRequestDto> {
    try {
      const priceRequest = new PriceAddRequestDto(domain.unitPrice, domain.animalBreed);

      return Result.ok(priceRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.PRICE_ADD))
      );
    }
  }
}
