import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IDiseaseListService } from "@/domain/disease/diseaseList/service/IDiseaseListService";
import { DiseaseListRequestDto } from "@/domain/disease/diseaseList/dto/DiseaseListRequestDto";
import { DiseaseListResponseDto } from "@/domain/disease/diseaseList/dto/DiseaseListResponseDto";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class DiseaseService implements IDiseaseListService {
  async getList(
    request: DiseaseListRequestDto
  ): Promise<Result<DiseaseListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.DISEASE_LIST + request.toQueryString())
        .then((response) => {
          return Result.ok<DiseaseListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<DiseaseListResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_DISEASE_LIST)))
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.DISEASE_LIST))
      );
    }
  }
}
