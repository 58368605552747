import { Result } from "@/core/utility/ResultType";
import AboutUserService from "../service/AboutUserService";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { REPOSITORY } from "@/domain/Constant";
import { IAboutUserRepository } from "@/domain/authentication/aboutUser/repository/IAboutUserRepository";
import { AboutUserResponseDto } from "@/domain/authentication/aboutUser/dto/AboutUserResponseDto";

const aboutUserService = new AboutUserService();

export default class AboutUserRepository implements IAboutUserRepository {
  async getAboutUser(): Promise<Result<AboutUserResponseDto>> {
    try {
      return aboutUserService
        .getAboutUser()
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<AboutUserResponseDto>(response.getValue());
          } else {
            return Result.fail<AboutUserResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.ABOUT_USER))
      );
    }
  }
}
