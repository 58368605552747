import { AnimalTagUpdateRepository } from "../repository/AnimalTagUpdateRepository";
import { AnimalTagUpdateMapper } from "../mapper/AnimalTagUpdateMapper";
import { Result } from "@/core/utility/ResultType";
import { SWAL_MESSAGES, USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalTagUpdateUseCase } from "@/domain/animalTag/animalTagUpdate/usecase/IAnimalTagUpdateUsecase";
import { AnimalTagUpdateModel } from "@/domain/animalTag/animalTagUpdate/model/AnimalTagUpdateModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";


export class AnimalTagUpdateUsecase implements IAnimalTagUpdateUseCase {
  animalTagAddRepository: AnimalTagUpdateRepository;
  animalTagAddMapper = new AnimalTagUpdateMapper();

  constructor(_animalTagAddRepository: AnimalTagUpdateRepository) {
    this.animalTagAddRepository = _animalTagAddRepository;
  }

  async execute(model: AnimalTagUpdateModel): Promise<Result<AnimalTagUpdateModel>> {
    try {
      const dto = this.animalTagAddMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalTagUpdateModel>(dto.getError());
      } else {
        const response = await this.animalTagAddRepository.updateAnimalTag(dto, model.id);

        if (response.isSuccess) {
          const domain = this.animalTagAddMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
          const analytics = getAnalytics();
          logEvent(analytics, 'update_animal_tag', {
            animal_tag_id: domain.getValue().id
          });
        }


          return Result.ok<AnimalTagUpdateModel>(domain.getValue());
        } else {
          return Result.fail<AnimalTagUpdateModel>(response.getError())
        }
      }
    } catch (error) {
      return Result.fail<AnimalTagUpdateModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_TAG_UPDATE))
      );
    }
  }
}
