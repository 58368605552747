import { BaseDTO } from "@/core/base/BaseDTO";

export class AnimalFeedAddRequestDto extends BaseDTO {
  data: {
    animal?:  number;
    animals?:  number[];
    barnyard?: number;
    product?: number;
    amount?: number;
    completedDate?: Date;
    description?: string;
  };

  constructor(
    animal?:  number,
    animals?:  number[],
    barnyard?: number,
    product?: number,
    amount?: number,
    completedDate?: Date,
    description?: string,
  ) {
    amount = Number(amount)
    super();
    this.data = {
      animal,
      animals,
      barnyard,
      product,
      amount,
      completedDate,
      description,
    };
  }
}
