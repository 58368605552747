import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalDiseaseUpdateService from "../service/AnimalDiseaseUpdateService";
import { IAnimalDiseaseUpdateRepository } from "@/domain/animal/animalDisease/animalDiseaseUpdate/repository/IAnimalDiseaseUpdateRepository";
import { AnimalDiseaseUpdateRequestDto } from "@/domain/animal/animalDisease/animalDiseaseUpdate/dto/AnimalDiseaseUpdateRequestDto";
import { AnimalDiseaseUpdateResponseDto } from "@/domain/animal/animalDisease/animalDiseaseUpdate/dto/AnimalDiseaseUpdateResponseDto";

const updateAnimalTagService = new AnimalDiseaseUpdateService();

export class AnimalDiseaseUpdateRepository implements IAnimalDiseaseUpdateRepository {
  async updateAnimalDisease(
    request: AnimalDiseaseUpdateRequestDto, id?: number
  ): Promise<Result<AnimalDiseaseUpdateResponseDto>> {
    try {
      return await updateAnimalTagService
        .update(request, id)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<AnimalDiseaseUpdateResponseDto>(response.getValue());
          } else {
            return Result.fail<AnimalDiseaseUpdateResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<AnimalDiseaseUpdateResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<AnimalDiseaseUpdateResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_DISEASE_UPDATE))
      );
    }
  }
}
