import { AnimalTransferRepository } from "../repository/AnimalTransferRepository";
import { AnimalTransferMapper } from "../mapper/AnimalTransferMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalTransferUseCase } from "@/domain/animal/animalTransfer/usecase/IAnimalTransferUsecase";
import { AnimalTransferModel } from "@/domain/animal/animalTransfer/model/AnimalTransferModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";

export class AnimalTransferUsecase implements IAnimalTransferUseCase {
  animalTransferRepository: AnimalTransferRepository;
  animalTransferMapper = new AnimalTransferMapper();

  constructor(_animalTransferRepository: AnimalTransferRepository) {
    this.animalTransferRepository = _animalTransferRepository;
  }

  async execute(
    model: AnimalTransferModel
  ): Promise<Result<AnimalTransferModel>> {
    try {
      const dto = this.animalTransferMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalTransferModel>(dto.getError());
      } else {
        const response = await this.animalTransferRepository.animalTransfer(
          dto
        );

        if (response.isSuccess) {
          const domain = this.animalTransferMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
            const analytics = getAnalytics();
            logEvent(analytics, "animal_transfer", {
              transfered_animal_id: domain.getValue().animal,
              new_barnyard_id: domain.getValue().barnyard,
            });
          }

          return Result.ok<AnimalTransferModel>(domain.getValue());
        } else {
          return Result.fail<AnimalTransferModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AnimalTransferModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_TRANSFER))
      );
    }
  }
}
