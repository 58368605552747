import { AnimalTypeListRepository } from "../repository/AnimalTypeListRepository";
import { AnimalTypeListModel } from "@/domain/animal/animalTypeList/model/AnimalTypeListModel";
import { AnimalTypeListMapper } from "../mapper/AnimalTypeListMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalTypeListUseCase } from "@/domain/animal/animalTypeList/usecase/IAnimalTypeListUsecase";

export class AnimalTypeListUsecase implements IAnimalTypeListUseCase {
  animalTypeListRepository: AnimalTypeListRepository;
  animalTypeListMapper = new AnimalTypeListMapper();

  constructor(_animalTypeListRepository: AnimalTypeListRepository) {
    this.animalTypeListRepository = _animalTypeListRepository;
  }

  async execute(
    model: AnimalTypeListModel
  ): Promise<Result<AnimalTypeListModel>> {
    try {
      const dto = this.animalTypeListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalTypeListModel>(dto.getError());
      } else {
        const response = await this.animalTypeListRepository.animalTypeList(
          dto
        );

        if (response.isSuccess) {
          const domain = this.animalTypeListMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<AnimalTypeListModel>(domain.getValue());
        } else {
          return Result.fail<AnimalTypeListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AnimalTypeListModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_TYPE_LIST))
      );
    }
  }
}
