import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import FeedBuyService from "../service/FeedBuyService";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IFeedBuyRepository } from "@/domain/feed/feedBuy/repository/IFeedBuyRepository";
import { FeedBuyResponseDto } from "@/domain/feed/feedBuy/dto/FeedBuyResponseDto";
import { FeedBuyRequestDto } from "@/domain/feed/feedBuy/dto/FeedBuyRequestDto";

const feedBuyService = new FeedBuyService();

export class FeedBuyRepository implements IFeedBuyRepository {
  async buyFeed(
    request: FeedBuyRequestDto
  ): Promise<Result<FeedBuyResponseDto>> {
    try {
      return await feedBuyService
        .buy(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<FeedBuyResponseDto>(response.getValue());
          } else {
            return Result.fail<FeedBuyResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<FeedBuyResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.FEED_BUY))
      );
    }
  }
}
