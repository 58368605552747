export default {
  project: "Maldavar",
  year: "2024",
  version: "1.32",
  dashboard: "Homepage",
  settings: "Settings",
  authentication: "Authentication",
  signIn: "Sign In",
  signUp: "Sign Up",
  passwordReset: "Reset Password",
  error404: "Error 404",
  error500: "Error 500",
  createAccount: "Create Account",
  signin: "Sign In",
  adminPassword: "Welcome, please enter your login credentials.",
  email: "Email",
  password: "Password",
  forgotPassword: "Forgot Password",
  login: "Login",
  pleaseWait: "Please wait...",
  animal: "Animal",
  barnyards: "Barnyards",
  allAnimals: "All Animals",
  createAnimal: "Create Animal",
  weight: "Live Weight",
  animalTags: "Animal Tags",
  vaccine: "Vaccine",
  vaccineList: "Vaccine List",
  food: "Food",
  treatment: "Treatment",
  overheads: "Overheads",
  current: "Current",
  sales: "Sales",
  order: "Order",
  shipment: "Shipment",
  bill: "Bill",
  waybill: "Waybill",
  buy: "Buy",
  buyAnimal: "Buy Animal",
  buyFeed: "Buy Feed",
  supplies: "Supplies",
  machine: "Machine/Equipment",
  basicKnowledge: "Basic Knowledge",
  vehicles: "Vehicles",
  breeds: "Breeds",
  animalTypes: "Animal Types",
  vaccines: "Vaccine Information",
  diseases: "Diseases",
  foods: "Food Types",
  treatments: "Treatments",
  tags: "Tags",
  staff: "Staff",
  staffList: "Staff List",
  authorization: "Authorization",
  common: {
    layout: {
      about: "About Us",
      contact: "Contact",
      contactUs: "Contact Us",
    },
    button: {
      info: "Info",
      edit: "Edit",
      detail: "Detail",
      save: "Save",
      cancel: "Cancel",
      return: "Return",
      add: "Add",
      update: "Update",
      send: "Send",
      loader: "Please wait...",
      yes: "Yes",
      no: "No",
    },
    menu: {
      myProfile: "My Profile",
      changeTenant: "Change Farm",
      language: "Language",
      accountSettings: "Account Settings",
      signOut: "Sign Out",
    },
    table: {
      process: "Process",
      notProcess: "Process cannot be done because it is default",
      pagination: {
        desc: "Showing ",
        desc1: "results from ",
        desc2: "items per page",
      },
    },
  },
  swalMessages: {
    CONFIRM_BUTTON_TEXT: "OK",
    API_ERROR_MESSAGE: "Technical error.",
    ERROR_CODE: " Error code: ",
    UNKNOWN_ERROR_MESSAGE: "An unknown error occurred.",
    ARE_YOU_SURE: "Are you sure?",
    SURE_DELETE_ANIMAL: "Are you sure you want to delete this animal?",
    SURE_TREAT_ANIMAL: "Are you sure you want to update as treated?",

    SUCCESS: {
      SUCCESS: "Operation successful!",
      LOGIN_SUCCESS_MESSAGE: "Login successful.",
      SUCCESS_FORGOT_PASSWORD: "Password reset email sent successfully.",
      SUCCESS_RESET_PASSWORD: "Password successfully changed.",

      SUCCESS_CREATE_ANIMAL: "Congratulations, animal added successfully.",
      SUCCESS_UPDATE_ANIMAL: "Congratulations, animal updated successfully.",
      SUCCESS_DELETE_ANIMAL: "Congratulations, animal deleted successfully.",
      SUCCESS_TRANSFER_ANIMAL: "Congratulations, animal transferred successfully.",

      SUCCESS_UPDATE_ANIMAL_WEIGHT: "Congratulations, animal weight updated.",
      SUCCESS_ADD_ANIMAL_VACCINE: "Congratulations, animal vaccinated successfully.",
      SUCCESS_ADD_ANIMAL_TAG: "Congratulations, animal tag created successfully.",
      SUCCESS_UPDATE_ANIMAL_TAG: "Congratulations, animal tag updated successfully.",
      SUCCESS_REMOVE_ANIMAL_TAG: "Congratulations, animal tag deleted successfully.",
      SUCCESS_BUY_FOOD: "Congratulations, food purchase added successfully!",
      SUCCESS_ADD_BREED: "Congratulations, animal breed added successfully!",
      SUCCESS_UPDATE_BREED: "Congratulations, animal breed updated successfully!",
      SUCCESS_REMOVE_BREED: "Congratulations, animal breed deleted successfully!",
      SUCCESS_UPDATE_PRICE: "Congratulations, animal breed price updated successfully!",
      SUCCESS_ADD_ANIMAL_FEED: "Congratulations, animal feeding record added successfully!",
      SUCCESS_ADD_ANIMAL_DISEASE: "Congratulations, animal disease record added successfully!",
      SUCCESS_UPDATE_ANIMAL_DISEASE: "Congratulations, animal disease updated successfully!",
    },

    ERROR: {
      ERROR: "Operation failed!",
      SESSION_TIME_OUT: "Session expired. Please log in again with your user information.",
      LOGIN_ERROR_MESSAGE: "Incorrect login credentials, please try again.",
      ERROR_FORGOT_PASSWORD: "Technical error. Password reset email could not be sent.",
      ERROR_RESET_PASSWORD: "Technical error. Password change operation failed. Please try again.",
      ERROR_CHANGE_TENANT_USER: "Technical error! Farm change operation failed.",
      ERROR_ABOUT_USER: "Technical error! Personal information upload failed.",

      ERROR_CREATE_ANIMAL: "Technical error! Animal addition failed.",
      ERROR_UPDATE_ANIMAL: "Technical error! Animal update failed.",
      ERROR_DELETE_ANIMAL: "Technical error! Animal deletion failed.",
      ERROR_TRANSFER_ANIMAL: "Technical error! Animal transfer operation failed.",

      ERROR_UPDATE_ANIMAL_WEIGHT: "Technical error! Weight update failed.",
      ERROR_ADD_ANIMAL_VACCINE: "Technical error! Animal vaccination failed.",
      ERROR_ADD_ANIMAL_TAG: "Technical error! Animal tag addition failed.",
      ERROR_UPDATE_ANIMAL_TAG: "Technical error! Animal tag update failed.",
      ERROR_REMOVE_ANIMAL_TAG: "Technical error! Animal tag deletion failed.",
      ERROR_BUY_FOOD: "Technical error! Food purchase record failed.",
      ERROR_ADD_BREED: "Technical error! Animal breed addition failed.",
      ERROR_UPDATE_BREED: "Technical error! Animal breed update failed.",
      ERROR_REMOVE_BREED: "Technical error! Animal breed deletion failed.",
      ERROR_UPDATE_PRICE: "Technical error! Animal breed price update failed.",
      ERROR_ADD_ANIMAL_FEED: "Technical error! Animal feeding operation failed.",
      ERROR_ADD_ANIMAL_DISEASE: "Technical error! Animal disease addition failed.",
      ERROR_UPDATE_ANIMAL_DISEASE: "Technical error! Animal disease update failed.",

      ERROR_ANIMAL_LIST: "Technical error! Animal listing failed.",
      ERROR_ANIMAL_COUNT: "Technical error! Unable to retrieve animal count information.",
      ERROR_MOST_VALUABLE_ANIMAL: "Technical error! Unable to retrieve most valuable animal information.",
      ERROR_ANIMAL_WEIGHT_AVERAGE: "Technical error! Unable to retrieve animal weight gain average information.",
      ERROR_TOTAL_COST: "Technical error! Unable to retrieve total cost information.",
      ERROR_TOTAL_VALUE_ANIMALS: "Technical error! Unable to retrieve total value of animals information.",
      ERROR_ANIMAL_TAG_LIST: "Technical error! Animal tag listing failed.",
      ERROR_ANIMAL_TYPE_LIST: "Technical error! Animal type listing failed.",
      ERROR_ANIMAL_WEIGHT_LIST: "Technical error! Animal weight listing failed.",
      ERROR_GET_ANIMAL_DETAIL: "Technical error! Unable to retrieve animal details.",
      ERROR_BARNYARD_LIST: "Technical error! Barnyard listing failed.",
      ERROR_BARNYARD_DETAIL: "Technical error! Unable to retrieve barnyard details.",
      ERROR_BREED_LIST: "Technical error! Animal breed listing failed.",
      ERROR_FOOD_LIST: "Technical error! Food listing failed.",
      ERROR_VACCINE_LIST: "Technical error! Vaccine listing failed.",
      ERROR_ANIMAL_VACCINE_LIST: "Technical error! Animal vaccine listing failed.",
      ERROR_DISEASE_LIST: "Technical error! Disease type listing failed.",
      ERROR_ANIMAL_DISEASE_LIST: "Technical error! Animal disease listing failed.",
      ERROR_ANIMAL_FEED_LIST: "Technical error! Animal feed listing failed.",
    },
  },
  customErrorMessages: {
    useCase: {
      error: "Usecase error.",
    },
    mapper: {
      error: "Mapper error.",
    },
    controller: {
      error: "Controller error.",
    },
    repository: {
      error: "Repository error.",
    },
    aggregateRoot: {
      error: "Aggregate root error.",
    },
    domainEvent: {
      error: "Domain Event error.",
    },
    email: {
      required: "Please enter your email address.",
      invalid: "Please enter a valid email address.",
    },
    password: {
      required: "Please enter your password.",
      min: "Password must be at least 6 characters.",
      match: "Passwords must match.",
    },
    identity: {
      required: "Please enter your identity number.",
      length: "Identity number must be 11 digits.",
    },
    credential: {
      required: "Please enter your email address and password.",
      credential: "Invalid email address or password.",
    },
    required: "Please do not leave this field empty.",
  },
  
  validators_error:{
    required: "Please do not leave this field empty.",
    typeError: "Please enter data in the appropriate type.",
    email: "Please enter a valid email address.",
    minlength: " must be at least characters.",
    maxlength: " must be at most characters.",
    zeroValue: "Value cannot be less than 0.",
  },
  menuPages: {
    name: "Pages",
    dashboard: {
      pageName: "Dashboard",
      statistics: {
        header: "Quick Menu",
        barnyardList: "Barnyard List",
        animalList: "Animal List",
        addAnimal: "Add New Animal",
        accounting: "Accounting",
        weight: "Weight",
        animalTag: "Animal Tags",
        breeds: "Breeds",
      },
      tenantInfo: {
        header: "Farm Information",
        desc: "Detailed information about the farm...",
        tenant: "Farm",
        barnyardCount: "Barnyard Count",
        animalCount: "Animal Count",
        occupancy: "Occupancy",
        totalAnimalValue: "Total Animal Value",
        totalAmount: "Total Amount",
        unit: "Unit",
        kilo: "Kilo",
      },
      animalWeight: {
        header: "Animal Weight Gain",
        desc: "Statistics of animal weight gains...",
      },
      mostValuableAnimal: {
        header: "Most Valuable Animal",
        desc: "Detailed information about the most valuable animal on the farm...",
        animal: {
          name: "Animal Name",
          eartag: "Eartag Number",
          gender: "Gender",
          weight: "Weight",
          color: "Color",
          value: "Value",
        },
      },
      accounting: {
        header: "Accounting",
        desc: "Accounting information...",
      },
    },
    error404: {
      nothing: "Looks like there's nothing here",
      return: "Return to Dashboard",
    },
    error500: {
      error: "System Error",
      description: "Oops! Something went wrong. Please try again later!",
      return: "Return to Dashboard",
    },
    animalList: {
      pageName: "Animals",
      animalList: "Animal List",
      animal: "Animal",
      earTag: "Ear Tag",
      gender: "Gender",
      color: "Color",
      barnyard: "Barnyard",
      lastWeight: "Last Weight",
      status: "Status",
      pregnant: "Pregnant",
      search: "Search Animals",
      tag: "Tag",
      zeroCount: "No animals found.",
      seeAll: "See All",
    },
    createAnimal: {
      pageName: "Add Animal",
      photo: "Photo",
      photoDesc: "Allowed file types: png, jpg, jpeg..",
      race: "Race",
      selectRace: "Select Race...",
      gender: "Gender",
      selectGender: "Select Gender...",
      earringNumber: "Ear Tag",
      nickname: "Nickname",
      lastWeight: "Last Weight",
      motherEarNumber: "Mother's Ear Tag",
      fatherEarNumber: "Father's Ear Tag",
      price: "Purchase Price",
      companyInfo: "Company Information",
      companyFirstDate: "Company Entry Date",
      companyEnterDesc: "Reason for Company Entry",
      healthInfo: "Health Information",
      barnyard: "Barnyard",
      selectBarnyard: "Select Barnyard...",
      status: "Status",
      selectStatus: "Select Status...",
      tags: "Tags",
      tagsValid: "Maximum of 3 tags can be selected.",
      selectTags: "Select...",
      color: "Color",
      birthDate: "Birth Date",
    },
    weight: {
      pageName: "Weights",
      animals: "Animals",
      myWeight: "My Weight",
      animal: "Animal",
      barnyard: "Barnyard",
      lastWeight: "Last Weight",
      liveWeight: "Live Weight",
      status: "Status",
      description: "Description",
      addWeight: "Add Weight",
    },
    addWeight: {
      pageName: "Add Weight",
      pageDescription: "Please enter the necessary information to add weight to the animal.",
      liveWeight: "Live Weight",
      enterWeight: "Enter Weight...",
      date: "Date",
      enterDate: "Select Date",
      description: "Description",
      enterDescription: "Enter Description...",
    },
    myWeight: {
      pageName: "My Weight",
      animals: "Animals",
      myWeight: "My Weight",
      animal: "Animal",
      barnyard: "Barnyard",
      date: "Date",
      lastWeight: "Last Weight",
      liveWeight: "Live Weight",
      weightResult: "Weight Result",
      zeroCount: "No weight records found.",
    },
    animalWeight: {
      pageName: "Animal Weights",
      graphic: "Graphic",
      list: "List",
    },
    vaccineSelect: {
      pageName: "Vaccine",
      animalSelection: "Animal Selection",
      vaccination: "Vaccination",
      animal: "Animal",
      barnyard: "Barnyard",
    },
    vaccination: {
      pageName: "Vaccination",
      vaccinationList: "Vaccination List",
      vaccinationInfos: "Vaccination Information",
      animalSelection: "Animal Selection",
      vaccination: "Vaccination",
      id: "ID",
      barnyard: "Barnyard",
      vaccine: "Vaccine",
      mililiter: "Ml",
      price: "Price",
      date: "Date",
      description: "Description",
      active: "Active",
      status: "Status",
      createVaccination: "Create Vaccination",
      addVaccination: "Apply Vaccination",
      addAllVaccination: "Apply to All",
      zeroCount: "No vaccination information found.",
      past: " days ago",
      future: " days left",
    },
    vaccineList: {
      pageName: "Vaccine List",
      id: "ID",
      barnyard: "Barnyard",
      vaccine: "Vaccine",
      animalName: "Animal Name",
      earTag: "Ear Tag",
      date: "Date",
      status: "Status",
      search: "Search Vaccine",
      zeroCount: "No vaccine found.",
    },
    vaccinate: {
      pageName: "Vaccinate",
      pageDescription: "Please enter complete vaccination records.",
      vaccine: "Vaccine",
      selectVaccine: "Select Vaccine...",
      amount: "Amount (ml)",
      totalAmount: "Total Amount (ml)",
      enterAmount: "Enter Amount...",
      date: "Date",
      enterDate: "Select Date",
      description: "Description",
      enterDescription: "Enter Description...",
    },
    addPrice: {
      pageName: "Update Price",
      pageDescription: "Please enter the price information.",
      breed: "Animal Breed",
      selectBreed: "Select Breed...",
      price: "Price",
      enterPrice: "Enter Price...",
      currency: "Currency",
      enterCurrency: "Enter Currency...",
      description: "Description",
      enterDescription: "Enter Description...",
    },
    food: {
      pageName: "Food",
      feedAnimal: "Feed Animal",
      selectFood: "Select Food...",
      foodList: "Food List",
      pageDescription:
        "Please enter the type of food you want to give and other necessary information.",
      barnyard: "Barnyard",
      selectBarnyard: "Select Barnyard...",
      numberOfAnimals: "Number of Fed Animals",
      foodName: "Food Name",
      date: "Date",
      enterDate: "Select Date...",
      amount: "Amount (kg)",
      totalAmount: "Total Amount (kg)",
      unitType: "Unit",
      enterAmount: "Enter Amount...",
      description: "Description",
      enterDescription: "Enter Description...",
      button: {
        prepareFood: "Prepare Food Mix",
        addFood: "Create Food",
        feedAnimal: "Feed Animal",
        feedAllAnimal: "Feed All",
      },
      zeroCount: "No food records found.",
    },
    animalFeeds:{
      pageName: "Feed List",
      foodName: "Food Name",
      productDescription: "Product Description",
      amount: "Amount (kg)",
      unitTypeName: "Unit",
      description: "Description",
      completedDate: "Date",
      zeroCount: "No feed records found.",
    },
    barnyards: {
      pageName: "Barnyards",
      barnyard: "Barnyard",
      withoutBarnyard: "Without Barnyard",
      outBarnyard: "Out of Barnyard",
      show: "Show",
      zeroBarnyard: "No barnyard found.",
    },
    barnyardPanel: {
      pageName: "Barnyard Panel",
      tabMenu: {
        padokInfo: "Barnyard Info",
        animals: "Animals",
        vaccination: "Vaccination",
        feed: "Feed",
        weight: "Weight",
        treatment: "Treatment",
        overheads: "Overheads",
      },
      barnyardName: "Barnyard Name",
      capacity: "Capacity",
      count: "Animal Count",
      barnyardLocation: "Barnyard Location",
      companyName: "Company Name",
    },
    animalInfo: {
      pageName: "Animal Information",
      info: "Profile",
      edit: "Edit",
      tabMenu: {
        weight: "Weights",
        vaccination: "Vaccination",
        feed: "Feed",
        treatment: "Treatment",
        overheads: "Overheads",
        diseases: "Diseases",
      },
      photo: "Photo",
      photoDesc: "Allowed file types: png, jpg, jpeg..",
      race: "Race",
      selectRace: "Select Race...",
      gender: "Gender",
      selectGender: "Select Gender...",
      earringNumber: "Ear Tag Number",
      nickname: "Nickname",
      lastWeight: "Last Weight",
      motherEarNumber: "Mother Ear Tag Number",
      fatherEarNumber: "Father Ear Tag Number",
      price: "Price",
      companyInfo: "Company Information",
      companyFirstDate: "Company Entry Date",
      companyEnterDesc: "Reason for Company Entry",
      healthInfo: "Health Information",
      vaccinations: "Vaccinations",
      diseases: "Diseases",
      barnyard: "Barnyard",
      selectBarnyard: "Select Barnyard...",
      status: "Status",
      selectStatus: "Select Status...",
      color: "Color",
      description: "Description",
    },
    breed: {
      pageName: "Animal Breeds",
      name: "Breed Name",
      description: "Description",
      animalType: "Animal Type",
      isDefault: "Default",
      price: "Price",
      button: {
        addBreed: "Add Breed",
      },
      zeroCount: "No animal breeds found.",
    },
    disease: {
      pageName: "Diseases",
      diseaseList: "Disease List",
      name: "Disease Name",
      description: "Description",
      createDate: "Creation Date",
      updateDate: "Update Date",
      button: {
        addDisease: "Add Disease",
      },
      zeroCount: "No disease records found.",
    },
    addDisease: {
      pageName: "Add Disease",
      pageDescription: "Please enter the necessary information to add disease record for the animal.",
      disease: "Disease",
      selectDisease: "Select Disease...",
      startDate: "Start Date",
      enterStartDate: "Select Date...",
      endDate: "End Date",
      enterEndDate: "Select Date...",
      end: "Recovered",
    },
    buyFeed: {
      pageName: "Buy Feed",
      feed: "Feed Name",
      description: "Description",
      grammage: "Grammage",
      unitPrice: "Unit Price",
      quantity: "Quantity (kg)",
      purchaseDate: "Date",
      button: {
        buy: "Buy",
      },
    },
    animalTags: {
      pageName: "Animal Tags",
      id: "Id",
      shortCode: "Short Code",
      name: "Name",
      description: "Description",
      isDefault: "Default",
      button: {
        remove: "Remove",
        add: "Create Animal Tag",
      },
      zeroCount: "No animal tags found.",
    },
    animalTypes: {
      pageName: "Animal Types",
      id: "Id",
      shortCode: "Short Code",
      name: "Name",
      description: "Description",
      button: {
        remove: "Remove",
        add: "Create Animal Type",
      },
      zeroCount: "No animal types found.",
    },
    addAnimalTag: {
      pageName: "Add Animal Tag",
      pageDescription: "Please enter the following information to add animal tag.",
      animalTag: "Tag Name",
      enterAnimalTag: "Enter animal tag...",
      description: "Description",
      enterDescription: "Enter description...",
    },
    addBreed: {
      pageName: "Add Animal Breed",
      updateBreed: "Update Animal Breed",
      pageDescription: "Please enter the following information to add animal breed.",
      breedName: "Breed Name",
      enterBreedName: "Enter breed name...",
      animalType: "Animal Type",
      selectAnimalType: "Select animal type...",
      description: "Description",
      enterDescription: "Enter description...",
    },
    animalTransfer: {
      pageName: "Animal Barnyard Transfer",
      pageDescription: "Please enter the barnyard you want the animal to transfer to and necessary information.",
      transfer: "Transfer",
      animal: "Animal",
      selectAnimal: "Select Animal...",
      barnyard: "Barnyard",
      selectBarnyard: "Select Barnyard...",
      date: "Transfer Date",
      selectDate: "Select Date...",
      description: "Description",
      enterDescription: "Enter description...",
    },
    forgotPassword: {
      pageName: "Forgot Password",
      pageDescription: "Please enter your email address.",
      email: "Email",
    },
    resetPassword: {
      pageName: "Reset Password",
      pageDescription: "Please enter your new password.",
      newPassword: "New Password",
      newPasswordAgain: "New Password Again",
    },
  },
};