import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/domain/Constant";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { DiseaseListResponseDto } from "@/domain/disease/diseaseList/dto/DiseaseListResponseDto";
import { DiseaseListModel } from "@/domain/disease/diseaseList/model/DiseaseListModel";
import { DiseaseListRequestDto } from "@/domain/disease/diseaseList/dto/DiseaseListRequestDto";

const paginationMapper = new PaginationMapper();

export class DiseaseListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: DiseaseListResponseDto): Result<DiseaseListModel> {
    try {
      const diseaseList: DiseaseListModel[] = [];
      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const diseaseListModel: DiseaseListModel = {
            disease: {
              id: Number(item.id),
              name: item.attributes.name,
              shortCode: item.attributes.shortCode,
              description: item.attributes.description,
            },
            page: 0,
            pageSize: 0,
            pagination: dto.meta.pagination
          };
          diseaseList.push(diseaseListModel);
        }
      }
      return Result.ok(diseaseList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.BREED_LIST))
      );
    }
  }

  mapToDTO(domain: DiseaseListModel): Result<DiseaseListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const diseaseRequest = new DiseaseListRequestDto(pagiRequest);

      return Result.ok(diseaseRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.DISEASE_LIST))
      );
    }
  }
}
