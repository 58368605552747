import { BaseDTO } from "@/core/base/BaseDTO";

export class ChangeTenantEstateRequestDto extends BaseDTO {
  data: {
    tenantEstateId: number;
  };

  constructor(tenantEstateId: number) {
    super();
    this.data = {
      tenantEstateId,
    };
  }
}
