import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { BreedRemoveRequestDto } from "@/domain/breed/breedRemove/dto/BreedRemoveRequestDto";
import { BreedRemoveModel } from "@/domain/breed/breedRemove/model/BreedRemoveModel";

export class BreedRemoveMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<BreedRemoveModel> {
    try {
      const breedRemoveModel: BreedRemoveModel = {
        id: dto.data.id,
        ok: dto.ok,
      };

      return Result.ok(breedRemoveModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.BREED_REMOVE))
      );
    }
  }

  mapToDTO(domain: BreedRemoveModel): Result<BreedRemoveRequestDto> {
    try {
      const animalRequest = new BreedRemoveRequestDto(domain.id);

      return Result.ok(animalRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.BREED_REMOVE))
      );
    }
  }
}
