import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import VaccinationListService from "../service/VaccinationListService";
import { IVaccinationListRepository } from "@/domain/vaccination/vaccinationList/repository/IVaccinationListRepository";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { VaccinationListResponseDto } from "@/domain/vaccination/vaccinationList/dto/VaccinationListResponseDto";
import { VaccinationListRequestDto } from "@/domain/vaccination/vaccinationList/dto/VaccinationListRequestDto";

const vaccinationListService = new VaccinationListService();

export class VaccinationListRepository implements IVaccinationListRepository {
  async vaccinationList(
    request: VaccinationListRequestDto
  ): Promise<Result<VaccinationListResponseDto>> {
    try {
      return await vaccinationListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<VaccinationListResponseDto>(response.getValue());
          } else {
            return Result.fail<VaccinationListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<VaccinationListResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.VACCINATION_LIST))
      );
    }
  }
}
