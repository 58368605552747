import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { FeedBuyRequestDto } from "@/domain/feed/feedBuy/dto/FeedBuyRequestDto";
import { FeedBuyResponseDto } from "@/domain/feed/feedBuy/dto/FeedBuyResponseDto";
import { FeedBuyModel } from "@/domain/feed/feedBuy/model/FeedBuyModel";

export class FeedBuyMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: FeedBuyResponseDto): Result<FeedBuyModel[]> {
    try {
      const feedBuyModel: FeedBuyModel = {
        description: dto.data.attributes.description,
        productGroupShortCode: "ANIMAL_FEED",
        purchaseDate: dto.data.attributes.purchaseOrderDate,
        quantity: dto.data.attributes.purchaseOrderDetails.data.attributes.quantity,
        unitPrice: dto.data.attributes.purchaseOrderDetails.data.attributes.price,
        status: dto.data.attributes.purchaseOrderDetails.data.attributes.status,
      };

      return Result.ok(feedBuyModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.FEED_BUY))
      );
    }
  }

  mapToDTO(domain: FeedBuyModel): Result<FeedBuyRequestDto> {
    try {
      const feedBuyRequest = new FeedBuyRequestDto(
        domain.name,
        domain.description,
        domain.unitPrice,
        domain.quantity,
        domain.purchaseDate,
        "ANIMAL_FEED"
      );

      return Result.ok(feedBuyRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.FEED_BUY))
      );
    }
  }
}
