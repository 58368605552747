import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { IBreedListRepository } from "@/domain/breed/breedList/repository/IBreedListRepository";
import BreedListService from "../service/BreedListService";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BreedListResponseDto } from "@/domain/breed/breedList/dto/BreedListResponseDto";
import { BreedListRequestDto } from "@/domain/breed/breedList/dto/BreedListRequestDto";

const breedListService = new BreedListService();

export class BreedListRepository implements IBreedListRepository {
  async breedList(
    request: BreedListRequestDto
  ): Promise<Result<BreedListResponseDto>> {
    try {
      return await breedListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<BreedListResponseDto>(response.getValue());
          } else {
            return Result.fail<BreedListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<BreedListResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<BreedListResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.BREED_LIST))
      );
    }
  }
}
