import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalDiseaseAddService from "../service/AnimalDiseaseAddService";
import { IAnimalDiseaseAddRepository } from "@/domain/animal/animalDisease/animalDiseaseAdd/repository/IAnimalDiseaseAddRepository";
import { AnimalDiseaseAddRequestDto } from "@/domain/animal/animalDisease/animalDiseaseAdd/dto/AnimalDiseaseAddRequestDto";
import { AnimalDiseaseAddResponseDto } from "@/domain/animal/animalDisease/animalDiseaseAdd/dto/AnimalDiseaseAddResponseDto";

const addAnimalDiseaseService = new AnimalDiseaseAddService();

export class AnimalDiseaseAddRepository implements IAnimalDiseaseAddRepository {
  async addAnimalDisease(
    request: AnimalDiseaseAddRequestDto
  ): Promise<Result<AnimalDiseaseAddResponseDto>> {
    try {
      return await addAnimalDiseaseService
        .add(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<AnimalDiseaseAddResponseDto>(response.getValue());
          } else {
            return Result.fail<AnimalDiseaseAddResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<AnimalDiseaseAddResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<AnimalDiseaseAddResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_DISEASE_ADD))
      );
    }
  }
}
