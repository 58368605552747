import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import BarnyardService from "../service/BarnyardListService";
import { IBarnyardListRepository } from "@/domain/barnyard/barnyardList/repository/IBarnyardListRepository";
import { BarnyardListResponseDto } from "@/domain/barnyard/barnyardList/dto/BarnyardListResponseDto";
import { BarnyardListRequestDto } from "@/domain/barnyard/barnyardList/dto/BarnyardListRequestDto";

const barnyardService = new BarnyardService();

export class BarnyardListRepository implements IBarnyardListRepository {
  async barnyardList(request: BarnyardListRequestDto): Promise<Result<BarnyardListResponseDto>> {
    try {
      return await barnyardService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<BarnyardListResponseDto>(response.getValue());
          } else {
            return Result.fail<BarnyardListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<BarnyardListResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<BarnyardListResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.BARNYARD_LIST))
      );
    }
  }
}
