import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { IResetPasswordService } from "@/domain/authentication/resetPassword/service/IResetPasswordService";
import { ResetPasswordRequestDto } from "@/domain/authentication/resetPassword/dto/ResetPasswordRequestDto";
import { ResetPasswordResponseDto } from "@/domain/authentication/resetPassword/dto/ResetPasswordResponseDto";
import i18n from "@/presentation/language/i18n";
import { BadRequestCause } from "@/core/cause/BadRequestCause";

export default class ResetPasswordService implements IResetPasswordService {
  async reset(
    request: ResetPasswordRequestDto
  ): Promise<Result<ResetPasswordResponseDto>> {
    try {
      return await instance
        .post(API_ROUTE.RESET_PASSWORD, request)
        .then((response) => {
          return Result.ok<ResetPasswordResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<ResetPasswordResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_RESET_PASSWORD)))
          );
        });
    } catch (error) {
      return Result.fail<ResetPasswordResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.RESET_PASSWORD))
      );
    }
  }
}
