import { AnimalTagRemoveRepository } from "../repository/AnimalTagRemoveRepository";
import { AnimalTagRemoveMapper } from "../mapper/AnimalTagRemoveMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalTagRemoveUseCase } from "@/domain/animalTag/animalTagRemove/usecase/IAnimalTagRemoveUsecase";
import { AnimalTagRemoveModel } from "@/domain/animalTag/animalTagRemove/model/AnimalTagRemoveModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";


export class AnimalTagRemoveUsecase implements IAnimalTagRemoveUseCase {
  animalTagRemoveRepository: AnimalTagRemoveRepository;
  animalTagRemoveMapper = new AnimalTagRemoveMapper();

  constructor(_animalTagRemoveRepository: AnimalTagRemoveRepository) {
    this.animalTagRemoveRepository = _animalTagRemoveRepository;
  }

  async execute(
    model: AnimalTagRemoveModel
  ): Promise<Result<AnimalTagRemoveModel>> {
    try {
      const dto = this.animalTagRemoveMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalTagRemoveModel>(dto.getError());
      } else {
        const response = await this.animalTagRemoveRepository.removeAnimalTag(
          dto
        );

        if (response.isSuccess) {
          const domain = this.animalTagRemoveMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
          const analytics = getAnalytics();
          logEvent(analytics, 'remove_animal_tag', {
            animal_tag_id: model.id
          });
        }


          return Result.ok<AnimalTagRemoveModel>(domain.getValue());
        } else {
          return Result.fail<AnimalTagRemoveModel>(response.getError())
        }
      }
    } catch (error) {
      return Result.fail<AnimalTagRemoveModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_TAG_REMOVE))
      );
    }
  }
}
