import { TenantStatisticRepository } from "@/application/dashboard/tenantStatistic/repository/TenantStatisticRepository";
import { TenantStatisticUsecase } from "@/application/dashboard/tenantStatistic/usecase/TenantStatisticUsecase";
import { IController } from "@/core/base/IController";
import { Result } from "@/core/utility/ResultType";
import { TenantStatisticModel } from "@/domain/dashboard/tenantStatistic/model/TenantStatisticModel";

export function createDashboardController() {
  const tenantStatisticRepository = new TenantStatisticRepository();

  const tenantStatisticUsecase = new TenantStatisticUsecase(
    tenantStatisticRepository
  );

  return new DashboardController(tenantStatisticUsecase);
}

export class DashboardController implements IController {
  constructor(private tenantStatisticUsecase: TenantStatisticUsecase) {}

  async getTenantStatistic(): Promise<Result<TenantStatisticModel>> {
    const response = await this.tenantStatisticUsecase.execute();

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<TenantStatisticModel>(response.getError());
    }
  }
}
