import { BaseDomain } from "@/core/base/BaseDomain";
import { EmailAddress } from "@/core/value/object/EmailAddress";
import { Password } from "@/core/value/object/Password";
import { TenantUserModel } from "@/domain/tenantUser/model/TenantUserModel";

export class LoginModel extends BaseDomain {
  jwt?: string;
  id?: number;
  username?: string;
  email: EmailAddress;
  password: Password;
  activeTenantUser?: TenantUserModel;
  tenantUsers?: TenantUserModel[];

  constructor(
    email: string,
    password: string,
    jwt?: string,
    id?: number,
    username?: string,
    activeTenantUser?: TenantUserModel,
    tenantUsers?: TenantUserModel[],
  ) {
    super();
    this.password = new Password({ value: password });
    this.jwt = jwt;
    this.id = id;
    this.username = username;
    this.email = new EmailAddress({ value: email });
    this.activeTenantUser = activeTenantUser;
    this.tenantUsers = tenantUsers;
  }
}
