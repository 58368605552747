import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { IAnimalTagRemoveService } from "@/domain/animalTag/animalTagRemove/service/IAnimalTagRemoveService";
import { AnimalTagRemoveRequestDto } from "@/domain/animalTag/animalTagRemove/dto/AnimalTagRemoveRequestDto";
import { AnimalTagRemoveResponseDto } from "@/domain/animalTag/animalTagRemove/dto/AnimalTagRemoveResponseDto";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class AnimalTagRemoveService
  implements IAnimalTagRemoveService
{
  async remove(
    request: AnimalTagRemoveRequestDto
  ): Promise<Result<AnimalTagRemoveResponseDto>> {
    try {
      return await instance
        .delete(API_ROUTE.ANIMAL_TAG + request.id)
        .then((response) => {
          return Result.ok<AnimalTagRemoveResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<AnimalTagRemoveResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_REMOVE_ANIMAL_TAG)))
          );
        });
    } catch (error) {
      return Result.fail<AnimalTagRemoveResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_TAG_REMOVE))
      );
    }
  }
}
