import { BaseDTO } from "@/core/base/BaseDTO";

export class BreedRemoveRequestDto extends BaseDTO {
  id?: number;

  constructor(id?: number) {
    super();
    this.id = id;
  }
}
