import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { AnimalDiseaseAddResponseDto } from "@/domain/animal/animalDisease/animalDiseaseAdd/dto/AnimalDiseaseAddResponseDto";
import { AnimalDiseaseAddRequestDto } from "@/domain/animal/animalDisease/animalDiseaseAdd/dto/AnimalDiseaseAddRequestDto";
import { IAnimalDiseaseAddService } from "@/domain/animal/animalDisease/animalDiseaseAdd/service/IAnimalDiseaseAddService";
import i18n from "@/presentation/language/i18n";
import { BadRequestCause } from "@/core/cause/BadRequestCause";

export default class AnimalDiseaseAddService
  implements IAnimalDiseaseAddService
{
  async add(
    request: AnimalDiseaseAddRequestDto
  ): Promise<Result<AnimalDiseaseAddResponseDto>> {
    try {
      return await instance
        .post(API_ROUTE.ANIMAL_DISEASES, request)
        .then((response) => {
          return Result.ok<AnimalDiseaseAddResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<AnimalDiseaseAddResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_ADD_ANIMAL_VACCINE)))
          );
        });
    } catch (error) {
      return Result.fail<AnimalDiseaseAddResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_DISEASE_ADD))
      );
    }
  }
}
