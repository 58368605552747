import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { IForgotPasswordService } from "@/domain/authentication/forgotPassword/service/IForgotPasswordService";
import { ForgotPasswordRequestDto } from "@/domain/authentication/forgotPassword/dto/ForgotPasswordRequestDto";
import i18n from "@/presentation/language/i18n";
import { BadRequestCause } from "@/core/cause/BadRequestCause";

export default class ForgotPasswordService implements IForgotPasswordService {
  async post(request: ForgotPasswordRequestDto): Promise<Result<void>> {
    try {
      return await instance
        .post(API_ROUTE.FORGOT_PASSWORD, request)
        .then((response) => {
          return Result.ok<void>(response.data);
        })
        .catch(() => {
          return Result.fail<void>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR_FORGOT_PASSWORD)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<void>(
        new ServiceException(new UnknownCause(SERVICE.FORGOT_PASSWORD))
      );
    }
  }
}
