import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { IBreedListService } from "@/domain/breed/breedList/service/IBreedListService";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BreedListRequestDto } from "@/domain/breed/breedList/dto/BreedListRequestDto";
import { BreedListResponseDto } from "@/domain/breed/breedList/dto/BreedListResponseDto";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class BreedListService implements IBreedListService {
  async getList(
    request: BreedListRequestDto
  ): Promise<Result<BreedListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.BREED + request.toQueryString())
        .then((response) => {
          return Result.ok<BreedListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<BreedListResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_BREED_LIST)))
          );
        });
    } catch (error) {
      return Result.fail<BreedListResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.BREED_LIST))
      );
    }
  }
}
