import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { DeleteAnimalRequestDto } from "@/domain/animal/animalDelete/dto/DeleteAnimalRequestDto";
import { IDeleteAnimalService } from "@/domain/animal/animalDelete/service/IDeleteAnimalService";

export default class DeleteAnimalService implements IDeleteAnimalService {
  async delete(
    request: DeleteAnimalRequestDto
  ): Promise<Result<any>> {
    try {
      return await instance
        .delete<any>(API_ROUTE.ANIMALS + request.id)
        .then((response) => {
          return Result.ok<any>(response.data);
        })
        .catch(() => {
          return Result.fail<any>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_DELETE_ANIMAL)))
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_DELETE))
      );
    }
  }
}
