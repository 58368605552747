import { AnimalWeightListRepository } from "../repository/AnimalWeightListRepository";
import { AnimalWeightListModel } from "@/domain/animal/animalWeight/animalWeightList/model/AnimalWeightListModel";
import { AnimalWeightListMapper } from "../mapper/AnimalWeightListMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalWeightListUseCase } from "@/domain/animal/animalWeight/animalWeightList/usecase/IAnimalWeightListUsecase";

export class AnimalWeightListUsecase implements IAnimalWeightListUseCase {
  animalRepository: AnimalWeightListRepository;
  animalWeightListMapper = new AnimalWeightListMapper();

  constructor(_animalRepository: AnimalWeightListRepository) {
    this.animalRepository = _animalRepository;
  }

  async execute(
    model: AnimalWeightListModel
  ): Promise<Result<AnimalWeightListModel>> {
    try {
      const dto = this.animalWeightListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalWeightListModel>(dto.getError());
      } else {
        const response = await this.animalRepository.animalWeightList(
          dto,
          model.weight.animalId
        );

        if (response.isSuccess) {
          const domain = this.animalWeightListMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<AnimalWeightListModel>(domain.getValue());
        } else {
          return Result.fail<AnimalWeightListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AnimalWeightListModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_WEIGHT_LIST))
      );
    }
  }
}
