import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { AnimalTransferRequestDto } from "@/domain/animal/animalTransfer/dto/AnimalTransferRequestDto";
import { AnimalTransferModel } from "@/domain/animal/animalTransfer/model/AnimalTransferModel";

export class AnimalTransferMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(
    dto: any
  ): Result<AnimalTransferModel> {
    try {
      const animalTransferModel: AnimalTransferModel = {
        animal: 0,
        ok: dto.ok,
      };
      return Result.ok(animalTransferModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_TRANSFER))
      );
    }
  }

  mapToDTO(
    domain: AnimalTransferModel
  ): Result<AnimalTransferRequestDto> {
    try {
      const animalTransferRequest = new AnimalTransferRequestDto(
        domain.animal,
        domain.barnyard,
        domain.transferDate,
        domain.description
      );

      return Result.ok(animalTransferRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.ANIMAL_TRANSFER))
      );
    }
  }
}
