import { BaseValueObject } from "@/core/base/BaseValueObject";
import { IValueValidator } from "@/core/base/IValueValidator";
import EmailValidator from "../validator/EmailValidator";
import MinLengthValidator from "../validator/MinLengthValidator";
import StringNotBlankValidator from "../validator/StringNotBlankValidator";
import { Result } from "@/core/utility/ResultType";

export interface IEmailProps {
  value: string;
}

export class EmailAddress extends BaseValueObject<IEmailProps> {
  public type: string = "E-posta ";
  
  constructor(props: IEmailProps) {
    super(props);
  }

  get value(): string {
    return this.props.value;
  }

  get validators(): IValueValidator<string>[] {
    return [
      new StringNotBlankValidator(),
      new MinLengthValidator(10, this.type),
      new EmailValidator(),
    ];
  }

  public create(): Result<string> {
    for (const validator of this.validators) {
      const result: Result<string> = validator.validate(this.props.value);

      if (result.isFailure) {
        return Result.fail<string>(result.getError());
      }
    }

    return Result.ok(this.props.value);
  }
}
