import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { IAnimalTagUpdateService } from "@/domain/animalTag/animalTagUpdate/service/IAnimalTagUpdateService";
import { AnimalTagUpdateRequestDto } from "@/domain/animalTag/animalTagUpdate/dto/AnimalTagUpdateRequestDto";
import { AnimalTagUpdateResponseDto } from "@/domain/animalTag/animalTagUpdate/dto/AnimalTagUpdateResponseDto";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class AnimalTagUpdateService implements IAnimalTagUpdateService {
  async update(
    request: AnimalTagUpdateRequestDto, id?: number
  ): Promise<Result<AnimalTagUpdateResponseDto>> {
    try {
      return await instance
        .put(API_ROUTE.ANIMAL_TAG + id, request)
        .then((response) => {
          return Result.ok<AnimalTagUpdateResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<AnimalTagUpdateResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_UPDATE_ANIMAL_TAG)))
          );
        });
    } catch (error) {
      return Result.fail<AnimalTagUpdateResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_TAG_UPDATE))
      );
    }
  }
}
