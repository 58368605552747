import { AnimalTagAddRepository } from "../repository/AnimalTagAddRepository";
import { AnimalTagAddMapper } from "../mapper/AnimalTagAddMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/domain/Constant";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAnimalTagAddUseCase } from "@/domain/animalTag/animalTagAdd/usecase/IAnimalTagAddUsecase";
import { AnimalTagAddModel } from "@/domain/animalTag/animalTagAdd/model/AnimalTagAddModel";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from "../../../../../environment";

export class AnimalTagAddUsecase implements IAnimalTagAddUseCase {
  animalTagAddRepository: AnimalTagAddRepository;
  animalTagAddMapper = new AnimalTagAddMapper();

  constructor(_animalTagAddRepository: AnimalTagAddRepository) {
    this.animalTagAddRepository = _animalTagAddRepository;
  }

  async execute(model: AnimalTagAddModel): Promise<Result<AnimalTagAddModel>> {
    try {
      const dto = this.animalTagAddMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AnimalTagAddModel>(dto.getError());
      } else {
        const response = await this.animalTagAddRepository.addAnimalTag(dto);

        if (response.isSuccess) {
          const domain = this.animalTagAddMapper.mapToDomain(
            response.getValue()
          );

          if (environment.env == "prod") {
          const analytics = getAnalytics();
          logEvent(analytics, 'add_animal_tag', {
            animal_tag_id: domain.getValue().id
          });
        }

          return Result.ok<AnimalTagAddModel>(domain.getValue());
        } else {
          return Result.fail<AnimalTagAddModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AnimalTagAddModel>(
        new UseCaseException(new UnknownCause(USECASE.ANIMAL_TAG_ADD))
      );
    }
  }
}
