import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { IAnimalWeightUpdateService } from "@/domain/animal/animalWeight/animalWeightUpdate/service/IAnimalWeightUpdateService";
import { AnimalWeightUpdateRequestDto } from "@/domain/animal/animalWeight/animalWeightUpdate/dto/AnimalWeightUpdateRequestDto";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class AnimalWeightUpdateService
  implements IAnimalWeightUpdateService
{
  async update(
    request: AnimalWeightUpdateRequestDto
  ): Promise<Result<void>> {
    try {
      return await instance
        .post(API_ROUTE.ANIMAL_WEIGHT, request)
        .then((response) => {
          return Result.ok<void>(response.data);
        })
        .catch(() => {
          return Result.fail<void>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_UPDATE_ANIMAL_WEIGHT)))
          );
        });
    } catch (error) {
      return Result.fail<void>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_WEIGHT_UPDATE))
      );
    }
  }
}
