import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import DiseaseListService from "../service/DiseaseListService";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IDiseaseListRepository } from "@/domain/disease/diseaseList/repository/IDiseaseListRepository";
import { DiseaseListRequestDto } from "@/domain/disease/diseaseList/dto/DiseaseListRequestDto";
import { DiseaseListResponseDto } from "@/domain/disease/diseaseList/dto/DiseaseListResponseDto";

const diseaseListService = new DiseaseListService();

export class DiseaseListRepository implements IDiseaseListRepository {
  async diseaseList(
    request: DiseaseListRequestDto
  ): Promise<Result<DiseaseListResponseDto>> {
    try {
      return await diseaseListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<DiseaseListResponseDto>(response.getValue());
          } else {
            return Result.fail<DiseaseListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<DiseaseListResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<DiseaseListResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.DISEASE_LIST))
      );
    }
  }
}
