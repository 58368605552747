import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import i18n from "@/presentation/language/i18n";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import { IAnimalFeedAddService } from "@/domain/animal/animalFeed/animalFeedAdd/service/IAnimalFeedAddService";
import { AnimalFeedAddRequestDto } from "@/domain/animal/animalFeed/animalFeedAdd/dto/AnimalFeedAddRequestDto";

export default class AnimalFeedAddService
  implements IAnimalFeedAddService
{
  async add(
    request: AnimalFeedAddRequestDto
  ): Promise<Result<any>> {
    try {
      return await instance
        .post(API_ROUTE.ANIMAL_FEED, request)
        .then((response) => {
          return Result.ok<any>(response.data);
        })
        .catch(() => {
          return Result.fail<any>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_ADD_ANIMAL_FEED)))
          );
        });
    } catch (error) {
      return Result.fail<any>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_FEED_ADD))
      );
    }
  }
}
