export default {
  project: "Maldavar",
  year: "2024",
  version: "1.32",
  dashboard: "Tablero",
  settings: "Ajustes",
  authentication: "Autenticación",
  signIn: "Iniciar sesión",
  signUp: "Registrarse",
  passwordReset: "Restablecer contraseña",
  error404: "Error 404",
  error500: "Error 500",
  createAccount: "Crear cuenta",
  signin: "Iniciar sesión",
  adminPassword: "Bienvenido, por favor ingrese sus credenciales.",
  email: "Correo electrónico",
  password: "Contraseña",
  forgotPassword: "¿Olvidaste tu contraseña?",
  login: "Iniciar sesión",
  pleaseWait: "Por favor, espera...",
  animal: "Animal",
  barnyards: "Corrales",
  allAnimals: "Todos los animales",
  createAnimal: "Crear animal",
  weight: "Peso vivo",
  animalTags: "Etiquetas de animales",
  vaccine: "Vacuna",
  vaccineList: "Lista de vacunas",
  food: "Comida",
  treatment: "Tratamiento",
  overheads: "Gastos generales",
  current: "Actual",
  sales: "Ventas",
  order: "Pedido",
  shipment: "Envío",
  bill: "Factura",
  waybill: "Guía de remisión",
  buy: "Comprar",
  buyAnimal: "Comprar animal",
  buyFeed: "Comprar alimento",
  supplies: "Suministros",
  machine: "Máquina / Equipamiento",
  basicKnowledge: "Conocimientos básicos",
  vehicles: "Vehículos",
  breeds: "Razas",
  animalTypes: "Tipos de animales",
  vaccines: "Información sobre vacunas",
  diseases: "Enfermedades",
  foods: "Tipos de alimentos",
  treatments: "Tratamientos",
  tags: "Etiquetas",
  staff: "Personal",
  staffList: "Lista de personal",
  authorization: "Autorización",
  common: {
    layout: {
      about: "Acerca de",
      contact: "Contacto",
      contactUs: "Contáctanos",
    },
    button: {
      info: "Información",
      edit: "Editar",
      detail: "Detalle",
      save: "Guardar",
      cancel: "Cancelar",
      return: "Volver",
      add: "Agregar",
      update: "Actualizar",
      send: "Enviar",
      loader: "Por favor espera...",
      yes: "Sí",
      no: "No",
    },
    menu: {
      myProfile: "Mi perfil",
      changeTenant: "Cambiar granja",
      language: "Idioma",
      accountSettings: "Configuración de cuenta",
      signOut: "Cerrar sesión",
    },
    table: {
      process: "Proceso",
      notProcess: "No se puede procesar porque es predeterminado",
      pagination: {
        desc: "mostrando ",
        desc1: "de ",
        desc2: " resultados.",
      },
    },
  },
  swalMessages: {
    CONFIRM_BUTTON_TEXT: "Aceptar",
    API_ERROR_MESSAGE: "Error técnico.",
    ERROR_CODE: " Código de error: ",
    UNKNOWN_ERROR_MESSAGE: "Se produjo un error desconocido.",
    ARE_YOU_SURE: "¿Estás seguro?",
    SURE_DELETE_ANIMAL: "¿Estás seguro de que deseas eliminar este animal?",
    SURE_TREAT_ANIMAL: "¿Estás seguro de que deseas marcar esto como tratado?",

    SUCCESS: {
      SUCCESS: "¡Operación exitosa!",
      LOGIN_SUCCESS_MESSAGE: "Inicio de sesión exitoso.",
      SUCCESS_FORGOT_PASSWORD:
        "El correo electrónico de restablecimiento de contraseña se ha enviado correctamente.",
      SUCCESS_RESET_PASSWORD: "La contraseña se ha restablecido correctamente.",

      SUCCESS_CREATE_ANIMAL:
        "¡Felicidades, el animal se ha creado correctamente!",
      SUCCESS_UPDATE_ANIMAL:
        "¡Felicidades, el animal se ha actualizado correctamente!",
      SUCCESS_DELETE_ANIMAL:
        "¡Felicidades, el animal se ha eliminado correctamente!",
      SUCCESS_TRANSFER_ANIMAL:
        "¡Felicidades, el animal se ha transferido correctamente!",

      SUCCESS_UPDATE_ANIMAL_WEIGHT:
        "¡Felicidades, el peso del animal se ha actualizado correctamente!",
      SUCCESS_ADD_ANIMAL_VACCINE:
        "¡Felicidades, la vacunación del animal se ha registrado correctamente!",
      SUCCESS_ADD_ANIMAL_TAG:
        "¡Felicidades, la etiqueta del animal se ha creado correctamente!",
      SUCCESS_UPDATE_ANIMAL_TAG:
        "¡Felicidades, la etiqueta del animal se ha actualizado correctamente!",
      SUCCESS_REMOVE_ANIMAL_TAG:
        "¡Felicidades, la etiqueta del animal se ha eliminado correctamente!",
      SUCCESS_BUY_FOOD:
        "¡Felicidades, la compra de alimentos se ha registrado correctamente!",
      SUCCESS_ADD_BREED:
        "¡Felicidades, la raza de animal se ha creado correctamente!",
      SUCCESS_UPDATE_BREED:
        "¡Felicidades, la raza de animal se ha actualizado correctamente!",
      SUCCESS_REMOVE_BREED:
        "¡Felicidades, la raza de animal se ha eliminado correctamente!",
      SUCCESS_UPDATE_PRICE:
        "¡Felicidades, el precio de la raza de animal se ha actualizado correctamente!",
      SUCCESS_ADD_ANIMAL_FEED:
        "¡Felicidades, el registro de alimentación del animal se ha creado correctamente!",
      SUCCESS_ADD_ANIMAL_DISEASE:
        "¡Felicidades, el registro de enfermedad del animal se ha creado correctamente!",
      SUCCESS_UPDATE_ANIMAL_DISEASE:
        "¡Felicidades, la enfermedad del animal se ha actualizado correctamente!",
    },

    ERROR: {
      ERROR: "¡Operación fallida!",
      SESSION_TIME_OUT:
        "La sesión ha expirado. Por favor, inicie sesión nuevamente con sus credenciales.",
      LOGIN_ERROR_MESSAGE:
        "Las credenciales de inicio de sesión son incorrectas. Por favor, inténtelo de nuevo.",
      ERROR_FORGOT_PASSWORD:
        "Error técnico. No se pudo enviar el correo electrónico de restablecimiento de contraseña.",
      ERROR_RESET_PASSWORD:
        "Error técnico. No se pudo restablecer la contraseña. Por favor, inténtelo de nuevo.",
      ERROR_CHANGE_TENANT_USER: "Error técnico. No se pudo cambiar de granja.",
      ERROR_ABOUT_USER:
        "Error técnico. No se pudieron cargar los datos personales.",

      ERROR_CREATE_ANIMAL: "Error técnico. No se pudo crear el animal.",
      ERROR_UPDATE_ANIMAL: "Error técnico. No se pudo actualizar el animal.",
      ERROR_DELETE_ANIMAL: "Error técnico. No se pudo eliminar el animal.",
      ERROR_TRANSFER_ANIMAL: "Error técnico. No se pudo transferir el animal.",

      ERROR_UPDATE_ANIMAL_WEIGHT:
        "Error técnico. No se pudo actualizar el peso del animal.",
      ERROR_ADD_ANIMAL_VACCINE:
        "Error técnico. No se pudo registrar la vacunación del animal.",
      ERROR_ADD_ANIMAL_TAG:
        "Error técnico. No se pudo crear la etiqueta del animal.",
      ERROR_UPDATE_ANIMAL_TAG:
        "Error técnico. No se pudo actualizar la etiqueta del animal.",
      ERROR_REMOVE_ANIMAL_TAG:
        "Error técnico. No se pudo eliminar la etiqueta del animal.",
      ERROR_BUY_FOOD:
        "Error técnico. No se pudo registrar la compra de alimentos.",
      ERROR_ADD_BREED: "Error técnico. No se pudo crear la raza de animal.",
      ERROR_UPDATE_BREED:
        "Error técnico. No se pudo actualizar la raza de animal.",
      ERROR_REMOVE_BREED:
        "Error técnico. No se pudo eliminar la raza de animal.",
      ERROR_UPDATE_PRICE:
        "Error técnico. No se pudo actualizar el precio de la raza de animal.",
      ERROR_ADD_ANIMAL_FEED:
        "Error técnico. No se pudo registrar la alimentación del animal.",
      ERROR_ADD_ANIMAL_DISEASE:
        "Error técnico. No se pudo registrar la enfermedad del animal.",
      ERROR_UPDATE_ANIMAL_DISEASE:
        "Error técnico. No se pudo actualizar la enfermedad del animal.",

      ERROR_ANIMAL_LIST: "Error técnico. No se pudo listar los animales.",
      ERROR_ANIMAL_COUNT:
        "Error técnico. No se pudo obtener la cantidad de animales.",
      ERROR_MOST_VALUABLE_ANIMAL:
        "Error técnico. No se pudo obtener el animal más valioso.",
      ERROR_ANIMAL_WEIGHT_AVERAGE:
        "Error técnico. No se pudo obtener el promedio de aumento de peso del animal.",
      ERROR_TOTAL_COST: "Error técnico. No se pudo obtener el costo total.",
      ERROR_TOTAL_VALUE_ANIMALS:
        "Error técnico. No se pudo obtener el valor total de los animales.",
      ERROR_ANIMAL_TAG_LIST:
        "Error técnico. No se pudo listar las etiquetas de los animales.",
      ERROR_ANIMAL_TYPE_LIST:
        "Error técnico. No se pudo listar los tipos de animales.",
      ERROR_ANIMAL_WEIGHT_LIST:
        "Error técnico. No se pudo listar el peso de los animales.",
      ERROR_GET_ANIMAL_DETAIL:
        "Error técnico. No se pudo obtener los detalles del animal.",
      ERROR_BARNYARD_LIST: "Error técnico. No se pudo listar los corrales.",
      ERROR_BARNYARD_DETAIL:
        "Error técnico. No se pudieron obtener los detalles del corral.",
      ERROR_BREED_LIST:
        "Error técnico. No se pudo listar las razas de animales.",
      ERROR_FOOD_LIST: "Error técnico. No se pudo listar los alimentos.",
      ERROR_VACCINE_LIST: "Error técnico. No se pudo listar las vacunas.",
      ERROR_ANIMAL_VACCINE_LIST:
        "Error técnico. No se pudo listar las vacunas de los animales.",
      ERROR_DISEASE_LIST: "Error técnico. No se pudo listar las enfermedades.",
      ERROR_ANIMAL_DISEASE_LIST:
        "Error técnico. No se pudo listar las enfermedades de los animales.",
      ERROR_ANIMAL_FEED_LIST:
        "Error técnico. No se pudo listar la alimentación de los animales.",
    },
  },
  customErrorMessages: {
    useCase: {
      error: "Error de caso de uso.",
    },
    mapper: {
      error: "Error de mapeo.",
    },
    controller: {
      error: "Error de controlador.",
    },
    repository: {
      error: "Error de repositorio.",
    },
    aggregateRoot: {
      error: "Error de raíz de agregado.",
    },
    domainEvent: {
      error: "Error de evento de dominio.",
    },
    email: {
      required: "Por favor ingrese su dirección de correo electrónico.",
      invalid: "Por favor ingrese una dirección de correo electrónico válida.",
    },
    password: {
      required: "Por favor ingrese su contraseña.",
      min: "La contraseña debe tener al menos 6 caracteres.",
      match: "Las contraseñas deben coincidir.",
    },
    identity: {
      required: "Por favor ingrese su número de identidad.",
      length: "El número de identidad debe tener 11 dígitos.",
    },
    credential: {
      required:
        "Por favor ingrese su dirección de correo electrónico y contraseña.",
      credential:
        "Ha ingresado una dirección de correo electrónico o contraseña no válida.",
    },
    required: "Por favor no deje este campo vacío.",
  },
  validators_error: {
    required: "Por favor no deje este campo vacío.",
    typeError: "Por favor ingrese un tipo de dato adecuado.",
    email: "Por favor ingrese una dirección de correo electrónico válida.",
    minlength: " no puede tener menos caracteres.",
    maxlength: " no puede tener más caracteres.",
    zeroValue: "El valor no puede ser inferior a 0.",
  },
  menuPages: {
    name: "Páginas",
    dashboard: {
      pageName: "Tablero",
      statistics: {
        header: "Menú Rápido",
        barnyardList: "Lista de Corrales",
        animalList: "Lista de Animales",
        addAnimal: "Agregar Nuevo Animal",
        accounting: "Contabilidad",
        weight: "Peso",
        animalTag: "Etiquetas de Animales",
        breeds: "Razas de Animales",
      },
      tenantInfo: {
        header: "Información del Arrendatario",
        desc: "Información detallada sobre la granja...",
        tenant: "Granja",
        barnyardCount: "Cantidad de Corrales",
        animalCount: "Cantidad de Animales",
        occupancy: "Ocupación",
        totalAnimalValue: "Valor Total de los Animales",
        totalAmount: "Cantidad Total",
        unit: "Unidad",
        kilo: "Kilo",
      },
      animalWeight: {
        header: "Aumento de Peso de los Animales",
        desc: "Estadísticas de aumento de peso de los animales...",
      },
      mostValuableAnimal: {
        header: "Animal Más Valioso",
        desc: "Detalles del animal más valioso en la granja...",
        animal: {
          name: "Nombre del Animal",
          eartag: "Número de Identificación",
          gender: "Género",
          weight: "Peso",
          color: "Color",
          value: "Valor",
        },
      },
      accounting: {
        header: "Contabilidad",
        desc: "Información contable...",
      },
    },
    error404: {
      nothing: "Parece que no hay nada aquí",
      return: "Volver a la página de inicio",
    },
    error500: {
      error: "Error del Sistema",
      description: "¡Algo salió mal! ¡Por favor, inténtalo de nuevo más tarde!",
      return: "Volver a la página de inicio",
    },
    animalList: {
      pageName: "Animales",
      animalList: "Lista de Animales",
      animal: "Animal",
      earTag: "Número de Identificación",
      gender: "Género",
      color: "Color",
      barnyard: "Corral",
      lastWeight: "Último Peso",
      status: "Estado",
      pregnant: "Embarazada",
      search: "Buscar Animal",
      tag: "Etiqueta",
      zeroCount: "No se encontraron animales.",
      seeAll: "Ver Todos",
    },
    createAnimal: {
      pageName: "Agregar Animal",
      photo: "Foto",
      photoDesc: "Tipos de archivo permitidos: png, jpg, jpeg..",
      race: "Raza",
      selectRace: "Seleccione una raza...",
      gender: "Género",
      selectGender: "Seleccione un género...",
      earringNumber: "Número de Aro",
      nickname: "Apodo",
      lastWeight: "Último Peso",
      motherEarNumber: "Número de Aro de la Madre",
      fatherEarNumber: "Número de Aro del Padre",
      price: "Precio de Compra",
      companyInfo: "Información de la Empresa",
      companyFirstDate: "Fecha de Ingreso a la Empresa",
      companyEnterDesc: "Razón de Ingreso a la Empresa",
      healthInfo: "Información de Salud",
      barnyard: "Corral",
      selectBarnyard: "Seleccione un corral...",
      status: "Estado",
      selectStatus: "Seleccione un estado...",
      tags: "Etiquetas",
      tagsValid: "Se pueden seleccionar hasta 3 etiquetas.",
      selectTags: "Seleccionar...",
      color: "Color",
      birthDate: "Fecha de Nacimiento",
    },
    weight: {
      pageName: "Pesos",
      animals: "Animales",
      myWeight: "Mi Peso",
      animal: "Animal",
      barnyard: "Corral",
      lastWeight: "Último Peso",
      liveWeight: "Peso Vivo",
      status: "Estado",
      description: "Descripción",
      addWeight: "Agregar Peso",
    },
    addWeight: {
      pageName: "Agregar Peso",
      pageDescription:
        "Ingrese la información necesaria para agregar una medición de peso al animal.",
      liveWeight: "Peso Vivo",
      enterWeight: "Ingrese el peso...",
      date: "Fecha",
      enterDate: "Seleccione una fecha",
      description: "Descripción",
      enterDescription: "Ingrese una descripción...",
    },
    myWeight: {
      pageName: "Mi Peso",
      animals: "Animales",
      myWeight: "Mi Peso",
      animal: "Animal",
      barnyard: "Corral",
      date: "Fecha",
      lastWeight: "Último Peso",
      liveWeight: "Peso Vivo",
      weightResult: "Resultado del Peso",
      zeroCount: "No se encontró información de peso.",
    },
    animalWeight: {
      pageName: "Pesos de los Animales",
      graphic: "Gráfico",
      list: "Lista",
    },
    vaccineSelect: {
      pageName: "Vacuna",
      animalSelection: "Selección de Animal",
      vaccination: "Vacunación",
      animal: "Animal",
      barnyard: "Corral",
    },
    vaccination: {
      pageName: "Vacunación",
      vaccinationList: "Lista de Vacunas",
      vaccinationInfos: "Información de la Vacuna",
      animalSelection: "Selección de Animal",
      vaccination: "Vacunación",
      animal: "Animal",
      id: "ID",
      barnyard: "Corral",
      vaccine: "Vacuna",
      mililiter: "Mililitros",
      price: "Precio",
      date: "Fecha",
      description: "Descripción",
      active: "Activa",
      status: "Estado",
      createVaccination: "Crear Vacuna",
      addVaccination: "Vacunar",
      addAllVaccination: "Vacunar Todos",
      zeroCount: "No se encontró información de vacunación.",
      past: " días transcurridos",
      future: " días restantes",
    },
    vaccineList: {
      pageName: "Lista de Vacunas",
      id: "ID",
      barnyard: "Corral",
      vaccine: "Vacuna",
      animalName: "Nombre del Animal",
      earTag: "Número de Aro",
      date: "Fecha",
      status: "Estado",
      search: "Buscar Vacuna",
      zeroCount: "No se encontraron vacunas.",
    },
    vaccinate: {
      pageName: "Vacunar",
      pageDescription:
        "Ingrese los registros de vacunación de manera completa.",
      vaccine: "Vacuna",
      selectVaccine: "Seleccionar vacuna...",
      amount: "Cantidad (ml)",
      totalAmount: "Cantidad Total (ml)",
      enterAmount: "Ingrese la cantidad...",
      date: "Fecha",
      enterDate: "Seleccione una fecha",
      description: "Descripción",
      enterDescription: "Ingrese una descripción...",
    },
    addPrice: {
      pageName: "Actualizar Precio",
      pageDescription: "Ingrese la información del precio.",
      breed: "Raza",
      selectBreed: "Seleccione una raza...",
      price: "Precio",
      enterPrice: "Ingrese el precio...",
      currency: "Moneda",
      enterCurrency: "Ingrese la moneda...",
      description: "Descripción",
      enterDescription: "Ingrese una descripción...",
    },
    food: {
      pageName: "Alimento",
      feedAnimal: "Alimentar",
      selectFood: "Seleccionar alimento...",
      foodList: "Lista de Alimentos",
      pageDescription:
        "Ingrese el tipo de alimento que desea dar y otra información necesaria.",
      barnyard: "Corral",
      selectBarnyard: "Seleccione un corral...",
      numberOfAnimals: "Número de Animales Alimentados",
      foodName: "Nombre del Alimento",
      date: "Fecha",
      enterDate: "Seleccione una fecha...",
      amount: "Cantidad (kg)",
      totalAmount: "Cantidad Total (kg)",
      unitType: "Unidad",
      enterAmount: "Ingrese la cantidad...",
      description: "Descripción",
      enterDescription: "Ingrese una descripción...",
      button: {
        prepareFood: "Preparar Alimento",
        addFood: "Agregar Alimento",
        feedAnimal: "Alimentar",
        feedAllAnimal: "Alimentar a Todos",
      },
      zeroCount: "No se encontraron registros de alimento.",
    },
    animalFeeds: {
      pageName: "Lista de Alimentos",
      foodName: "Nombre del Alimento",
      productDescription: "Descripción del Producto",
      amount: "Cantidad (kg)",
      unitTypeName: "Unidad",
      description: "Descripción",
      completedDate: "Fecha",
      zeroCount: "No se encontraron registros de alimento.",
    },
    barnyards: {
      pageName: "Corrales",
      barnyard: "Corral",
      withoutBarnyard: "Sin Corral",
      outBarnyard: "Fuera del Corral",
      show: "Mostrar",
      zeroBarnyard: "No se encontraron corrales.",
    },
    barnyardPanel: {
      pageName: "Panel de Corral",
      tabMenu: {
        padokInfo: "Información del Corral",
        animals: "Animales",
        vaccination: "Vacunación",
        feed: "Alimento",
        weight: "Peso",
        treatment: "Tratamiento",
        overheads: "Gastos Generales",
      },
      barnyardName: "Nombre del Corral",
      capacity: "Capacidad",
      count: "Cantidad de Animales",
      barnyardLocation: "Ubicación del Corral",
      companyName: "Nombre de la Empresa",
    },
    animalInfo: {
      pageName: "Información del Animal",
      info: "Perfil",
      edit: "Editar",
      tabMenu: {
        weight: "Peso",
        vaccination: "Vacunación",
        feed: "Alimento",
        treatment: "Tratamiento",
        overheads: "Gastos",
        diseases: "Enfermedades",
      },
      photo: "Foto",
      photoDesc: "Tipos de archivo permitidos: png, jpg, jpeg..",
      race: "Raza",
      selectRace: "Selecciona una raza...",
      gender: "Género",
      selectGender: "Selecciona un género...",
      earringNumber: "Número de Aro",
      nickname: "Apodo",
      lastWeight: "Último Peso",
      motherEarNumber: "Número de Aro de la Madre",
      fatherEarNumber: "Número de Aro del Padre",
      price: "Precio",
      companyInfo: "Información de la Empresa",
      companyFirstDate: "Fecha de Ingreso a la Empresa",
      companyEnterDesc: "Razón de Ingreso a la Empresa",
      healthInfo: "Información de Salud",
      vaccinations: "Vacunas",
      diseases: "Enfermedades",
      barnyard: "Corral",
      selectBarnyard: "Selecciona un corral...",
      status: "Estado",
      selectStatus: "Selecciona un estado...",
      color: "Color",
      description: "Descripción",
    },
    breed: {
      pageName: "Razas de Animales",
      name: "Nombre de la Raza",
      description: "Descripción",
      animalType: "Tipo de Animal",
      isDefault: "Predeterminada",
      price: "Precio",
      button: {
        addBreed: "Agregar Raza",
      },
      zeroCount: "No se encontraron razas de animales.",
    },
    disease: {
      pageName: "Enfermedades",
      diseaseList: "Lista de Enfermedades",
      name: "Nombre de la Enfermedad",
      description: "Descripción",
      createDate: "Fecha de Creación",
      updateDate: "Fecha de Actualización",
      button: {
        addDisease: "Agregar Enfermedad",
      },
      zeroCount: "No se encontraron enfermedades.",
    },
    addDisease: {
      pageName: "Agregar Enfermedad",
      pageDescription:
        "Por favor, ingrese la información necesaria para agregar un registro de enfermedad al animal.",
      disease: "Enfermedad",
      selectDisease: "Selecciona una enfermedad...",
      startDate: "Fecha de Inicio",
      enterStartDate: "Seleccione una fecha...",
      endDate: "Fecha de Fin",
      enterEndDate: "Seleccione una fecha...",
      end: "Curado",
    },
    buyFeed: {
      pageName: "Comprar Alimento",
      feed: "Nombre del Alimento",
      description: "Descripción",
      grammage: "Gramaje",
      unitPrice: "Precio Unitario",
      quantity: "Cantidad (kg)",
      purchaseDate: "Fecha",
      button: {
        buy: "Comprar",
      },
    },
    animalTags: {
      pageName: "Etiquetas de Animales",
      id: "ID",
      shortCode: "Código",
      name: "Nombre",
      description: "Descripción",
      isDefault: "Predeterminada",
      button: {
        remove: "Eliminar",
        add: "Crear Etiqueta de Animal",
      },
      zeroCount: "No se encontraron etiquetas de animales.",
    },
    animalTypes: {
      pageName: "Tipos de Animales",
      id: "ID",
      shortCode: "Código",
      name: "Nombre",
      description: "Descripción",
      button: {
        remove: "Eliminar",
        add: "Crear Tipo de Animal",
      },
      zeroCount: "No se encontraron tipos de animales.",
    },
    addAnimalTag: {
      pageName: "Agregar Etiqueta de Animal",
      pageDescription:
        "Por favor, ingrese la siguiente información para agregar una etiqueta de animal.",
      animalTag: "Nombre de la Etiqueta",
      enterAnimalTag: "Ingrese la etiqueta del animal...",
      description: "Descripción",
      enterDescription: "Ingrese una descripción...",
    },
    addBreed: {
      pageName: "Agregar Raza de Animal",
      updateBreed: "Actualizar Raza de Animal",
      pageDescription:
        "Por favor, ingrese la siguiente información para agregar una raza de animal.",
      breedName: "Nombre de la Raza",
      enterBreedName: "Ingrese el nombre de la raza...",
      animalType: "Tipo de Animal",
      selectAnimalType: "Selecciona un tipo de animal...",
      description: "Descripción",
      enterDescription: "Ingrese una descripción...",
    },
    animalTransfer: {
      pageName: "Transferencia de Animal al Corral",
      pageDescription:
        "Ingrese el corral al que desea transferir al animal y la información necesaria de manera completa.",
      transfer: "Transferir",
      animal: "Animal",
      selectAnimal: "Selecciona un animal...",
      barnyard: "Corral",
      selectBarnyard: "Selecciona un corral...",
      date: "Fecha de Transferencia",
      selectDate: "Seleccione una fecha...",
      description: "Descripción",
      enterDescription: "Ingrese una descripción...",
    },
    forgotPassword: {
      pageName: "Olvidé mi Contraseña",
      pageDescription: "Por favor, ingrese su dirección de correo electrónico.",
      email: "Correo Electrónico",
    },
    resetPassword: {
      pageName: "Restablecer Contraseña",
      pageDescription: "Por favor, ingrese su nueva contraseña.",
      newPassword: "Nueva Contraseña",
      newPasswordAgain: "Confirmar Nueva Contraseña",
    },
  },
};
