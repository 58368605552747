import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ICreateAnimalService } from "@/domain/animal/animalAdd/service/ICreateAnimalService";
import { GetAnimalDetailResponseDto } from "@/domain/animal/animalDetail/dto/GetAnimalDetailResponseDto";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { CreateAnimalRequestDto } from "@/domain/animal/animalAdd/dto/CreateAnimalRequestDto";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class CreateAnimalService implements ICreateAnimalService {
  async post(request: CreateAnimalRequestDto): Promise<Result<GetAnimalDetailResponseDto>> {
    try {
      const formData = new FormData();
      formData.append("data", JSON.stringify(request.data));
      formData.append("profilePhoto", request.profilePhoto)

      return await instance
        .post<GetAnimalDetailResponseDto>(API_ROUTE.ANIMALS, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }})
        .then((response) => {
          return Result.ok<GetAnimalDetailResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<GetAnimalDetailResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_CREATE_ANIMAL)))
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_ADD))
      );
    }
  }
}
