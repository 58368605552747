import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { IAnimalDiseaseUpdateService } from "@/domain/animal/animalDisease/animalDiseaseUpdate/service/IAnimalDiseaseUpdateService";
import { AnimalDiseaseUpdateRequestDto } from "@/domain/animal/animalDisease/animalDiseaseUpdate/dto/AnimalDiseaseUpdateRequestDto";
import { AnimalDiseaseUpdateResponseDto } from "@/domain/animal/animalDisease/animalDiseaseUpdate/dto/AnimalDiseaseUpdateResponseDto";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";

export default class AnimalDiseaseUpdateService implements IAnimalDiseaseUpdateService {
  async update(
    request: AnimalDiseaseUpdateRequestDto, id?: number
  ): Promise<Result<AnimalDiseaseUpdateResponseDto>> {
    try {
      return await instance
        .put(API_ROUTE.ANIMAL_DISEASES + id, request)
        .then((response) => {
          return Result.ok<AnimalDiseaseUpdateResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<AnimalDiseaseUpdateResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_UPDATE_ANIMAL_DISEASE)))
          );
        });
    } catch (error) {
      return Result.fail<AnimalDiseaseUpdateResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.ANIMAL_DISEASE_UPDATE))
      );
    }
  }
}
