import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalWeightUpdateService from "../service/AnimalWeightUpdateService";
import { IAnimalWeightUpdateRepository } from "@/domain/animal/animalWeight/animalWeightUpdate/repository/IAnimalWeightUpdateRepository";
import { AnimalWeightUpdateRequestDto } from "@/domain/animal/animalWeight/animalWeightUpdate/dto/AnimalWeightUpdateRequestDto";

const animalWeightUpdateService = new AnimalWeightUpdateService();

export class AnimalWeightUpdateRepository
  implements IAnimalWeightUpdateRepository
{
  async updateAnimalWeight(
    request: AnimalWeightUpdateRequestDto
  ): Promise<Result<void>> {
    try {
      return await animalWeightUpdateService
        .update(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<void>(response.getValue());
          } else {
            return Result.fail<void>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<void>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<void>(
        new RepositoryException(
          new UnknownCause(REPOSITORY.ANIMAL_WEIGHT_UPDATE)
        )
      );
    }
  }
}
