import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { BarnyardListUseCase } from "@/application/barnyard/barnyardList/usecase/BarnyardListUseCase";
import { BarnyardListRepository } from "@/application/barnyard/barnyardList/repository/BarnyardListRepository";
import { BarnyardListModel } from "@/domain/barnyard/barnyardList/model/BarnyardListModel";
import { BarnyardDetailModel } from "@/domain/barnyard/barnyardDetail/model/BarnyardDetailModel";
import { BarnyardDetailRepository } from "@/application/barnyard/barnyardDetail/repository/BarnyardDetailRepository";
import { BarnyardDetailUseCase } from "@/application/barnyard/barnyardDetail/usecase/BarnyardDetailUseCase";

export function createBarnyardController() {
  const barnyardListRepository = new BarnyardListRepository();
  const barnyardDetailRepository = new BarnyardDetailRepository();

  const barnyardListUseCase = new BarnyardListUseCase(barnyardListRepository);
  const barnyardDetailUseCase = new BarnyardDetailUseCase(barnyardDetailRepository);
  return new BarnyardController(barnyardListUseCase, barnyardDetailUseCase);
}

export class BarnyardController implements IController {
  constructor(private barnyardListUseCase: BarnyardListUseCase,
    private barnyardDetailUseCase: BarnyardDetailUseCase) {}

  async barnyardList(barnyardListModel: BarnyardListModel): Promise<Result<BarnyardListModel>> {
    const response = await this.barnyardListUseCase.execute(barnyardListModel);
    
    if(response.isSuccess){
      return response;
    }
    else{
      return Result.fail<BarnyardListModel>(response.getError());
    }
  }

  async getBarnyardDetail(barnyardDetailModel: BarnyardDetailModel) : Promise<Result<BarnyardDetailModel>>{
    const response = await this.barnyardDetailUseCase.execute(barnyardDetailModel);

    if(response.isSuccess){
      return response;
    }
    else{
      return Result.fail<BarnyardDetailModel>(response.getError());
    }
  }
}
