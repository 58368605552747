import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE, SERVICE, SWAL_MESSAGES } from "@/domain/Constant";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IBarnyardDetailService } from "@/domain/barnyard/barnyardDetail/service/IBarnyardDetailService";
import { BarnyardDetailRequestDto } from "@/domain/barnyard/barnyardDetail/dto/BarnyardDetailRequestDto";
import { BarnyardDetailResponseDto } from "@/domain/barnyard/barnyardDetail/dto/BarnyardDetailResponseDto";

export default class BarnyardDetailService implements IBarnyardDetailService {
  async get(
    request: BarnyardDetailRequestDto
  ): Promise<Result<BarnyardDetailResponseDto>> {
    try {
      return await instance
        .get<BarnyardDetailResponseDto>(API_ROUTE.BARNYARDS + request.id)
        .then((response) => {
          return Result.ok<BarnyardDetailResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<BarnyardDetailResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR_BARNYARD_DETAIL)))
          );
        });
    } catch (error) {
      return Result.fail<BarnyardDetailResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.BARNYARD_DETAIL))
      );
    }
  }
}
