import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/domain/Constant";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AnimalTagAddService from "../service/AnimalTagAddService";
import { AnimalTagAddResponseDto } from "@/domain/animalTag/animalTagAdd/dto/AnimalTagAddResponseDto";
import { IAnimalTagAddRepository } from "@/domain/animalTag/animalTagAdd/repository/IAnimalTagAddRepository";
import { AnimalTagAddRequestDto } from "@/domain/animalTag/animalTagAdd/dto/AnimalTagAddRequestDto";

const addAnimalTagService = new AnimalTagAddService();

export class AnimalTagAddRepository implements IAnimalTagAddRepository {
  async addAnimalTag(
    request: AnimalTagAddRequestDto
  ): Promise<Result<AnimalTagAddResponseDto>> {
    try {
      return await addAnimalTagService
        .add(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<AnimalTagAddResponseDto>(response.getValue());
          } else {
            return Result.fail<AnimalTagAddResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<AnimalTagAddResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<AnimalTagAddResponseDto>(
        new RepositoryException(new UnknownCause(REPOSITORY.ANIMAL_TAG_ADD))
      );
    }
  }
}
