import { BaseValueObject } from "@/core/base/BaseValueObject";
import { Result } from "@/core/utility/ResultType";
import { IValueValidator } from "@/core/base/IValueValidator";
import StringNotBlankValidator from "../validator/StringNotBlankValidator";
import MinLengthValidator from "../validator/MinLengthValidator";

export interface IPasswordProps {
  value: string;
  hashed?: boolean;
}

export class Password extends BaseValueObject<IPasswordProps> {
  public type: string = "Password ";

  constructor(props: IPasswordProps) {
    super(props);
  }

  get value(): string {
    return this.props.value;
  }

  get validators(): IValueValidator<string>[] {
    return [
      new StringNotBlankValidator(),
      new MinLengthValidator(6, this.type),
    ];
  }

  public create(): Result<string> {
    for (const validator of this.validators) {
      const result = validator.validate(this.props.value);

      if (result.isFailure) {
        return result;
      }
    }

    return Result.ok(this.props.value);
  }
}
