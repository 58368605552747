import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/domain/Constant";
import { BarnyardListRequestDto } from "@/domain/barnyard/barnyardList/dto/BarnyardListRequestDto";
import { BarnyardListResponseDto } from "@/domain/barnyard/barnyardList/dto/BarnyardListResponseDto";
import { BarnyardListModel } from "@/domain/barnyard/barnyardList/model/BarnyardListModel";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";

const paginationMapper = new PaginationMapper();

export class BarnyardListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: BarnyardListResponseDto): Result<BarnyardListModel[]> {
    try {
      const barnyardList: BarnyardListModel[] = [];

      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const barnyardListModel: BarnyardListModel = {
            barnyard: {
              id: Number(item.id),
              shortCode: item.attributes.shortCode,
              name: item.attributes.name,
              description: item.attributes.description,
              animalCount: item.attributes.animalCount,
              capacity: item.attributes.capacity,
              sortValue: item.attributes.sortValue,
            },
            page: 0,
            pageSize: 0,
            pagination: dto.meta.pagination
          };

          barnyardList.push(barnyardListModel);
        }
      }
      return Result.ok(barnyardList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.BARNYARD_LIST))
      );
    }
  }

  mapToDTO(domain: BarnyardListModel): Result<BarnyardListModel> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );

      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();


      const barnyardListRequest = new BarnyardListRequestDto(
        pagiRequest,
      );

      return Result.ok(barnyardListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.BARNYARD_LIST))
      );
    }
  }
}
